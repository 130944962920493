import { CssBaseline, Paper, Card, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from "react";
import Topbar from '../topbar/topbar';
import { useDispatch, useSelector } from "react-redux";
import Sidebar from '../sidebar/sidebar';
import { Main } from '../main/main';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import VendorsChart from './vendorsChart';
import PartnersChart from './partnersChart';
import {
    getEventConferenceRecords,
    getSubscribersRecords,
    getRegistrationsRecords
} from "./dashboard.function";

export function Dashboard() {

    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const eventconferences = useSelector((state) => state?.dashboard?.eventconferences);
    const eventconferencesmounted = useSelector((state) => state?.dashboard?.eventconferencesmounted);
    const dispatch = useDispatch();

    const subscribers = useSelector((state) => state?.dashboard?.subscribers);
    const subscribersmounted = useSelector((state) => state?.dashboard?.subscribersmounted);

    const registrations = useSelector((state) => state?.dashboard?.registrations);
    const registrationsmounted = useSelector((state) => state?.dashboard?.registrationsmounted);

    useEffect(() => {
        if (!eventconferencesmounted) {
            dispatch(getEventConferenceRecords());
        }
        if (!subscribersmounted) {
            dispatch(getSubscribersRecords());
        }
        if (!registrationsmounted) {
            dispatch(getRegistrationsRecords());
        }
    });

    const vendor_data = (registrations.vendor_data) ? registrations.vendor_data : [];
    const partner_data = (registrations.partner_data) ? registrations.partner_data : [];
    const judge_data = (registrations.judge_data) ? registrations.judge_data : [];
    const team_data = (registrations.team_data) ? registrations.team_data : [];

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main open={sidebarState} className='dashbaordWrapper'>
                <Topbar />
                <Paper sx={{
                    background: '#e3f2fd', minHeight: 'calc(100% - 64px)', display: 'flex', flexWrap: 'wrap',
                    alignContent: 'flex-start', padding: '2rem'
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <Card className='dashboard_card purple'>
                                <div className='dashboard_card_icon'><EmojiEventsOutlinedIcon /></div>
                                <div className='number_txt'>{eventconferences.event_count}</div>
                                <div className='label_txt'>Total Events</div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card className='dashboard_card white'>
                                <div className='dashboard_card_icon'><OutlinedFlagRoundedIcon /></div>
                                <div className='number_txt'>{eventconferences.national_event_count}</div>
                                <div className='label_txt'>Nationals</div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card className='dashboard_card blue'>
                                <div className='dashboard_card_icon'><MarkEmailReadOutlinedIcon /></div>
                                <div className='number_txt'>{eventconferences.championship_event_count}</div>
                                <div className='label_txt'>Championships</div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card className='dashboard_card skyBlue'>
                                <div className='dashboard_card_icon'><ConfirmationNumberOutlinedIcon /></div>
                                <div className='number_txt'>{eventconferences.conferences_count}</div>
                                <div className='label_txt'>Conferences</div>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <VendorsChart teamData={Object.values(team_data)}
                                judgeData={Object.values(judge_data)}
                                partnerData={Object.values(partner_data)}
                                vendorData={Object.values(vendor_data)} name="Registrations" />
                        </Grid>
                        <Grid item xs={6}>
                            {!!subscribers && (
                                <PartnersChart data={Object.values(subscribers)} name="Subscribers" />
                            )}
                        </Grid>

                    </Grid>

                </Paper>
            </Main>
        </Box>
    )
}

export default Dashboard
