import { createSlice } from "@reduxjs/toolkit";

export const editSlideSlice = createSlice({
  name: "editSlide",
  initialState: {
    slide: {
      slideHeading: "",
      slideDescription: "",
      pictureOfSlide: "",
      slideImage: "",
      slideDisplayOrder: "",
    },
    errors: [],
    severity: "error",
    message: null,
    addSlideButtonDisabled: false,
    loading: false,
  },
  reducers: {
    getSlideDataById: (state, action) => {
      return {
        ...state,
        slide: state.slide,
        loading: true,
      };
    },
    getSlideDataByIdSuccess: (state, action) => {
      console.log(
        action.payload.slide.heading,
        action.payload.slide.description,
        action.payload.slide.image,
        action.payload.slide.display_order
      );
      return {
        ...state,
        slide: {
          id: action.payload.slide.id,
          slideHeading: action.payload.slide.heading,
          slideDescription: action.payload.slide.description,
          slideImage: action.payload.slide.image,
          slideDisplayOrder: action.payload.slide.display_order,
        },
        loading: false,
      };
    },
    getSlideDataByIdFailure: (state, action) => {
      return {
        ...state,
        errors: [],
        loading: false,
      };
    },
    editSlideHeading: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideHeading: action.payload.slideHeading,
        },
      };
    },
    editSlideDescription: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideDescription: action.payload.slideDescription,
        },
      };
    },
    editSlideImage: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideImage: action.payload.slideImage,
        },
      };
    },
    editSlideDisplayOrder: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideDisplayOrder: action.payload.slideDisplayOrder,
        },
      };
    },
    setEditSlideButtonDisabled: (state, action) => {
      return {
        ...state,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    editSlideLoading: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    editSlideSuccess: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
        },
        errors: [],
        message: action.payload.response,
        severity: "success",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    editSlideFailure: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        severity: "error",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },

    ///////////////
  },
});

export const {
  getSlideDataById,
  getSlideDataByIdFailure,
  getSlideDataByIdSuccess,

  editSlideHeading,
  editSlideDescription,
  editSlideImage,
  editSlideDisplayOrder,

  setEditSlideButtonDisabled,
  editSlideLoading,
  editSlideSuccess,
  editSlideFailure,
} = editSlideSlice.actions;

export default editSlideSlice.reducer;
