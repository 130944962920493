import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import {
  useMediaQuery,
  Box,
  CssBaseline,
  IconButton,
  Paper,
  tableCellClasses,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Backdrop,
  CircularProgress,
  Alert,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import { } from "@mui/system";
import { Main } from "../main/main";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Sidebar from "../sidebar/sidebar";
import Topbar from "../topbar/topbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import {
  setPage,
  setRecordDeleted,
  setRowsPerPage,
} from "./homepagebannerSlice";
import { getBannerRecords, deleteBanner } from "./banners.function";
import { Delete, Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import up from "../../assets/up.svg";
import down from "../../assets/down.svg";

export function Homepagebanner() {
  let navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      padding: "0.5rem 1rem",
      fontSize: 14,
    },
  }));
  const banners = useSelector((state) => state?.banners?.banners);
  const loading = useSelector((state) => state.banners.loading);
  const totalCount = useSelector((state) => state.banners.totalCount);
  const rowsPerPage = useSelector((state) => state.banners.rowsPerPage);
  const page = useSelector((state) => state.banners.page);
  const recordDeleted = useSelector((state) => state.banners.recordDeleted);
  const [sortoptions, setSortOptions] = useState({
    sortby: "",
    orderby: "",
  });

  const { sortby, orderby } = sortoptions;
  const [searchinput, setSearchInput] = useState("");
  const [doSearch, setDoSearch] = useState("");

  const dispatch = useDispatch();


  const sortRecords = (sortfield) => {
    let newsort = "";
    let neworder = "";
    if (sortoptions.sortby === sortfield) {
      newsort = sortfield;
      if (sortoptions.orderby === "asc") {
        neworder = "desc";
      }
      if (sortoptions.orderby === "desc") {
        neworder = "asc";
      }
    } else {
      newsort = sortfield;
      neworder = "asc";
    }
    setSortOptions((sortoptions) => ({
      ...sortoptions,
      ["sortby"]: newsort,
      ["orderby"]: neworder,
    }));
  };

  useEffect(() => {
    if (sortoptions.sortby !== "" && sortoptions.orderby !== "") {
      console.log("sortoptions", sortoptions);
      dispatch(
        getBannerRecords(
          page,
          rowsPerPage,
          sortoptions.sortby,
          sortoptions.orderby,
          searchinput
        )
      );
    }
  }, [sortoptions]);

  useEffect(() => {
    dispatch(
      getBannerRecords(
        0,
        rowsPerPage,
        sortoptions.sortby,
        sortoptions.orderby,
        searchinput
      )
    );
  }, [doSearch]);

  const handleClickClearSearch = () => {
    setSearchInput('');
    setDoSearch(new Date().getTime());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDoSearch(new Date().getTime());
  };


  useEffect(() => {
    dispatch(
      getBannerRecords(
        page,
        rowsPerPage,
        sortoptions.sortby,
        sortoptions.orderby,
        searchinput
      )
    );
  }, [
    dispatch,
    page,
    rowsPerPage,
    sortoptions.sortby,
    sortoptions.orderby
  ]);

  const handleChangePage = (banner, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (banner) => {
    dispatch(setRowsPerPage(banner.target.value));
    dispatch(setPage(0));
  };

  const [open, setOpen] = useState(false);
  const [dialogDeleteId, setDialogDeleteId] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const confirmDialogOpen = (id) => {
    setOpen(true);
    setDialogDeleteId(id);
  };

  const confirmDialogClose = (dialogAction) => {
    if (dialogAction === "Yes") {
      dispatch(deleteBanner(dialogDeleteId, page, rowsPerPage));
    }
    setOpen(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      setRecordDeleted({
        recordDeleted: false,
      })
    );
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        CLOSE
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          sx={{
            background: "#e3f2fd",
            padding: "1.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <div className="topBarButtonWrapper">
            <Button
              onClick={() => {
                navigate("/homepage-slideshow/add-slide", { replace: true });
              }}
              variant="contained"
            >
              Add Slide
            </Button>

            <form onSubmit={(e) => handleSubmit(e)}>
              <TextField
                id="searchinput"
                label="Search"
                variant="outlined"
                fullWidth={false}
                value={searchinput}
                /*onChange={(e) => doSearch(e.nativeEvent.target.value)}*/
                onChange={(e) => setSearchInput(e.nativeEvent.target.value)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!!searchinput && (
                        <IconButton
                          aria-label="Clear Search"
                          onClick={handleClickClearSearch}
                          edge="end"
                        >
                          <CloseIcon></CloseIcon>
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                aria-label="Search"
                onClick={(e) => setDoSearch(new Date().getTime())}
                edge="end"
              >
                <SearchIcon></SearchIcon>
              </IconButton>
            </form>
          </div>

          <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>
            Home Page Slides
          </Typography>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Slide Image</StyledTableCell>
                  <StyledTableCell onClick={() => sortRecords("heading")}>
                    Heading
                    <span>
                      <img
                        src={up}
                        alt="up"
                        width="12"
                        className={
                          sortoptions.sortby === "heading" &&
                            sortoptions.orderby === "asc"
                            ? " "
                            : "hide"
                        }
                      />{" "}
                      <img
                        src={down}
                        alt="down"
                        width="12"
                        className={
                          sortoptions.sortby === "heading" &&
                            sortoptions.orderby === "desc"
                            ? " "
                            : "hide"
                        }
                      />
                    </span>
                  </StyledTableCell>
                  <StyledTableCell onClick={() => sortRecords("description")}>Description
                    <span>
                      <img
                        src={up}
                        alt="up"
                        width="12"
                        className={
                          sortoptions.sortby === "description" &&
                            sortoptions.orderby === "asc"
                            ? " "
                            : "hide"
                        }
                      />{" "}
                      <img
                        src={down}
                        alt="down"
                        width="12"
                        className={
                          sortoptions.sortby === "description" &&
                            sortoptions.orderby === "desc"
                            ? " "
                            : "hide"
                        }
                      />
                    </span>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className="displayOrderCol"
                    onClick={() => sortRecords("display_order")}
                  >
                    Display Order
                    <span>
                      <img
                        src={up}
                        alt="up"
                        width="12"
                        className={
                          sortoptions.sortby === "display_order" &&
                            sortoptions.orderby === "asc"
                            ? " "
                            : "hide"
                        }
                      />{" "}
                      <img
                        src={down}
                        alt="down"
                        width="12"
                        className={
                          sortoptions.sortby === "display_order" &&
                            sortoptions.orderby === "desc"
                            ? " "
                            : "hide"
                        }
                      />
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banners.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <img
                        src={row.image}
                        loading="lazy"
                        alt=""
                        className="bannerSlideThumbnail"
                      />
                    </TableCell>
                    <TableCell>{row.heading}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align="center">{row.display_order}</TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          sx={{ marginRight: "0.25rem" }}
                          onClick={() => {
                            navigate(
                              "/homepage-slideshow/edit-slide/" + row.id,
                              {
                                replace: true,
                              }
                            );
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            confirmDialogOpen(row.id);
                          }}
                        >
                          <Delete sx={{ color: "#d32f2f" }} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => {
              confirmDialogClose("No");
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="dialogHeadWithIcon"
            >
              <DeleteIcon /> {"Delete Banner"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this banner?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="dailogBtnActWrapper">
                <Button
                  autoFocus
                  onClick={() => {
                    confirmDialogClose("No");
                  }}
                  variant="outlined"
                  color="primary"
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    confirmDialogClose("Yes");
                  }}
                  autoFocus
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={recordDeleted}
            autoHideDuration={6000}
            onClose={handleClose}
            action={action}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
          >
            <Alert severity="success" variant="filled">
              Slide has been deleted successfully.
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default Homepagebanner;
