import validator from "validator";
let errors = {
    isValid: false,
    error: null
};

export const validateEmail = (email, label) => {
    if (!email) {
        return errors = {
            ...errors,
            isValid: false,
            error: `${label} is required.`
        };
    } else if (email && validator.isEmail(email)) {
        return errors = {
            ...errors,
            isValid: true,
            error: '',
        };
    } else {
        return errors = {
            ...errors,
            isValid: false,
            error: `${label} is incorrect, enter a valid ${label}.`
        };
    }
}

export const validateString = (string, label) => {
    if (string !== "") {
        return errors = {
            ...errors,
            isValid: true,
            error: ''
        };
    } else {
        return errors = {
            ...errors,
            isValid: false,
            error: `Enter ${label}`
        };
    }
};

export const validatePhoneFax = (string, label) => {
    if (!string) {
        return errors = {
            ...errors,
            isValid: false,
            error: `Enter ${label} number.`
        };
    } else if (
        string &&
        validator.isNumeric(string.toString()) &&
        string.length >= 10 &&
        string.length <= 15
    ) {
        return errors = {
            ...errors,
            isValid: true,
            error: ''
        };
    } else if (string && validator.isNumeric(string.toString()) && string.length > 15) {
        return errors = {
            ...errors,
            isValid: false,
            error: "Enter maximum 15 digits."
        };
    } else if (string && validator.isNumeric(string.toString()) && string.length < 10) {
        return errors = {
            ...errors,
            isValid: false,
            error: `Enter 10 digit ${label} number.`
        };
    } else {
        return errors = {
            ...errors,
            isValid: false,
            error: "Enter Numeric Values."
        };
    }
};

export const validateUrl = (string, label) => {
    if (!string) {
        return errors = {
            ...errors,
            isValid: false,
            error: `Enter ${label} link.`
        };
    }
    if (string === '') {
        return errors = {
            ...errors,
            isValid: false,
            error: `Enter ${label} link.`
        };
    } else if (string && validator.isURL(string)) {
        return errors = {
            ...errors,
            isValid: true,
            error: ''
        };
    } else {
        return errors = {
            ...errors,
            isValid: false,
            error: "Enter valid URL."
        };
    }
};

export const validateFile = (string, label) => {
    if (string) {
        return errors = {
            ...errors,
            isValid: true,
            error: ''
        };
    } else {
        return errors = {
            ...errors,
            isValid: false,
            error: `Please upload ${label}.`
        };
    }
};
