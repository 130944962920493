import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import notFoundImg from '../../assets/not-found.svg'

export function NotFound() {
    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate("/");
    };

    const token = localStorage.getItem("token");
    let buttonText = 'Dashboard'
    if (token === null) {
        buttonText = 'Login';
    }

    return (
        <>
            <div className='notFoundWrapper'>
                <div className='notFountData'>
                    <div className='notFoundImg'>
                        <img src={notFoundImg} alt='' />
                    </div>
                    <Typography variant='h3'>404 Page not found</Typography>
                    <Typography variant='body1'>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</Typography>
                    <div className='notFoundButton'>
                        <Button variant='contained' color='primary' onClick={handleClickBack} disableElevation>Back to {buttonText}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;
