import {
  deleteAdvertisementSuccess,
  getAdvertisementRecordsFailure,
  getAdvertisementRecordsLoading,
  getAdvertisementRecordsSuccess,
} from "./advertisementSlice";
const { REACT_APP_API_URL } = process.env;

export function getAdvertisementRecords(page, pageSize) {
  const newPage = page + 1;
  return function getAdvertisementRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getAdvertisementRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}advertisements?page=${newPage}&sortBy=created_at&order=DESC&pageSize=${pageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getAdvertisementRecordsSuccess({
                advertisements: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(getAdvertisementRecordsFailure({ error: responseJson.info }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getAdvertisementRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteAdvertisement(id, page, rowsPerPage) {
  return function deleteAdvertisementThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}advertisement/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getAdvertisementRecords(page, rowsPerPage));
          dispatch(deleteAdvertisementSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getAdvertisementRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getAdvertisementRecordsFailure({ error: err }));
      });
  };
}
