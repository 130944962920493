import { createSlice } from '@reduxjs/toolkit'

export const addEventSlice = createSlice({
    name: 'addEvent',
    initialState: {
        event: {
            eventName: '',
            eventType: 1,
            eventDate: convertDateTimeFormat(new Date()).toString(),
            eventPlace: '',
            registerOnTime: convertDateTimeFormat(new Date()).toString(),
            finalLatePaymentBy: convertDateTimeFormat(new Date()).toString(),
            ruleQuestion: '',
            eventOpenTo: 0,
            qualifierForNationals: 1,
            performanceSurface: '',
            preliminaryLineup: '',
            shortDescription: '',
            draftSchedule: '',
            finalSchedule: '',
            awardGiveaway: '',
            onTime: 0,
            late: 0,
            crossCompetitor: 0,
            secondPerformance: 0,
            note: '',
            general: 0,
            senior: 0,
            child: 0,
            childFiveUnder: 0,
            parking: 'yes',
            fullRefundBy: convertDateTimeFormat(new Date()).toString(),
            halfRefundBy: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            mapUrl: '',
            eventAddress: '',
            pictureOfFacility: '',
            divisionLevelPdfName: '',
            schedulePdfName: '',
            resultsPdfName: '',
            covidGuidelinesPdfName: '',
            qualificationInvitePdfName: '',
            eventImage: null,
            accomodation: {
                accomodationId: 0,
                accommodationTitle: '',
                accommodationContent: '',
                accommodationDate: convertDateTimeFormat(new Date()).toString(),
            }
        },
        selectedTabValue: '1',
        errors: [],
        severity: 'error',
        message: null,
        addEventButtonDisabled: false,
        loading: false,
        decideLater: {
            eventDate: false,
            registerOnTime: false,
            finalLatePaymentBy: false,
            fullRefundBy: false,
            halfRefundBy: false,
            noRefund: false,
            accommodationDate: false
        },
        counter: {
            performanceSurfaceLength: 0,
            preliminaryLineupLength: 0,
            shortDescriptionLength: 0,
            draftScheduleLength: 0,
            finalScheduleLength: 0,
            notesLength: 0,
            awardsGiveawaysLength: 0,
        },
    },
    reducers: {
        setSelectedTabValue: (state, action) => {
            return {
                ...state,
                selectedTabValue: action.payload.selectedTabValue
            }
        },
        setAddEventButtonDisabled: (state, action) => {
            return {
                ...state,
                addEventButtonDisabled: action.payload.addEventButtonDisabled
            }
        },
        setEventName: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventName: action.payload.eventName
                }
            }
        },
        setEventType: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventType: action.payload.eventType
                }
            }
        },
        setEventOpenToType: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventOpenTo: action.payload.eventOpenTo
                }
            }
        },
        setEventDate: (state, action) => {
            console.log(action.payload.eventDate);
            return {
                ...state,
                event: {
                    ...state.event,
                    eventDate: action.payload.eventDate,
                }
            }
        },
        setEventPlace: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventPlace: action.payload.eventPlace
                }
            }
        },
        setRegisterOnTime: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    registerOnTime: action.payload.registerOnTime
                }
            }
        },
        setFinalLatePaymentBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    finalLatePaymentBy: action.payload.finalLatePaymentBy
                }
            }
        },
        setRuleQuestion: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    ruleQuestion: action.payload.ruleQuestion
                }
            }
        },
        setQualifierForNationals: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    qualifierForNationals: action.payload.qualifierForNationals
                }
            }
        },
        setPerformanceSurface: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    performanceSurface: action.payload.performanceSurface
                }
            }
        },
        setPreliminaryLineup: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    preliminaryLineup: action.payload.preliminaryLineup
                }
            }
        },
        setShortDescription: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    shortDescription: action.payload.shortDescription
                }
            }
        },
        setDraftSchedule: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    draftSchedule: action.payload.draftSchedule
                }
            }
        },
        setFinalSchedule: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    finalSchedule: action.payload.finalSchedule
                }
            }
        },
        setAwardGiveaway: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    awardGiveaway: action.payload.awardGiveaway
                }
            }
        },
        setOnTime: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    onTime: action.payload.onTime
                }
            }
        },
        setLate: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    late: action.payload.late
                }
            }
        },
        setCrossCompetitor: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    crossCompetitor: action.payload.crossCompetitor
                }
            }
        },
        setSecondPerformance: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    secondPerformance: action.payload.secondPerformance
                }
            }
        },
        setNote: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    note: action.payload.note
                }
            }
        },
        setGeneral: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    general: action.payload.general
                }
            }
        },
        setSenior: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    senior: action.payload.senior
                }
            }
        },
        setChild: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    child: action.payload.child
                }
            }
        },
        setChildFiveUnder: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    childFiveUnder: action.payload.childFiveUnder
                }
            }
        },
        setParking: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    parking: action.payload.parking
                }
            }
        },
        setFullRefundBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    fullRefundBy: action.payload.fullRefundBy
                }
            }
        },
        setHalfRefundBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    halfRefundBy: action.payload.halfRefundBy
                }
            }
        },
        setNoRefund: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    noRefund: action.payload.noRefund
                }
            }
        },
        setMapUrlValue: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    mapUrl: action.payload.mapUrl
                }
            }
        },
        setEventAddress: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventAddress: action.payload.eventAddress
                }
            }
        },
        setEventImage: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventImage: action.payload.eventImage
                }
            }
        },
        setAccommodationTitle: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationTitle: action.payload.title
                    }
                }
            }
        },
        setAccommodationContent: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationContent: action.payload.content
                    }
                }
            }
        },
        setAccommodationDate: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationDate: action.payload.date
                    }
                }
            }
        },
        setDivisionLevelPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    divisionLevelPdfName: action.payload.divisionLevelPdfName
                }
            }
        },
        setSchedulePdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    schedulePdfName: action.payload.schedulePdfName
                }
            }
        },
        setResultsPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    resultsPdfName: action.payload.resultsPdfName
                }
            }
        },
        setCovidGuidelinesPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    covidGuidelinesPdfName: action.payload.covidGuidelinesPdfName
                }
            }
        },
        setQualificationInvitePdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    qualificationInvitePdfName: action.payload.qualificationInvitePdfName
                }
            }
        },
        eventDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    eventDate: action.payload.decideLater
                }
            }
        },
        latePaymentByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    finalLatePaymentBy: action.payload.decideLater
                }
            }
        },
        registerOnTimeByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    registerOnTime: action.payload.decideLater
                }
            }
        },
        fullRefundByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    fullRefundBy: action.payload.decideLater
                }
            }
        },
        halfRefundByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    halfRefundBy: action.payload.decideLater
                }
            }
        },
        noRefundDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    noRefund: action.payload.decideLater
                }
            }
        },
        accommodationDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    accommodationDate: action.payload.decideLater
                }
            }
        },
        addEventLoading: (state, action) => {
            return {
                ...state,
                loading: true,
            }
        },
        addEventSuccess: (state, action) => {
            return {
                ...state,
                event: {
                    eventName: '',
                    eventType: 1,
                    eventDate: convertDateTimeFormat(new Date()).toString(),
                    eventPlace: '',
                    registerOnTime: convertDateTimeFormat(new Date()).toString(),
                    finalLatePaymentBy: convertDateTimeFormat(new Date()).toString(),
                    ruleQuestion: '',
                    eventOpenTo: 0,
                    qualifierForNationals: 1,
                    performanceSurface: '',
                    preliminaryLineup: '',
                    shortDescription: '',
                    draftSchedule: '',
                    finalSchedule: '',
                    awardGiveaway: '',
                    onTime: 0,
                    late: 0,
                    crossCompetitor: 0,
                    secondPerformance: 0,
                    note: '',
                    general: 0,
                    senior: 0,
                    child: 0,
                    childFiveUnder: 0,
                    parking: 'yes',
                    fullRefundBy: convertDateTimeFormat(new Date()).toString(),
                    halfRefundBy: convertDateTimeFormat(new Date()).toString(),
                    noRefund: convertDateTimeFormat(new Date()).toString(),
                    mapUrl: '',
                    eventAddress: '',
                    pictureOfFacility: '',
                    divisionLevelPdfName: '',
                    schedulePdfName: '',
                    resultsPdfName: '',
                    covidGuidelinesPdfName: '',
                    qualificationInvitePdfName: '',
                    eventImage: null,
                    accommodation: {
                        accommodationTitle: '',
                        accommodationContent: '',
                        accommodationDate: '',
                    }
                },
                errors: [],
                message: action.payload.response,
                severity: 'success',
                loading: false,
                addEventButtonDisabled: action.payload.addEventButtonDisabled,
                counter: {
                    performanceSurfaceLength: 0,
                    preliminaryLineupLength: 0,
                    shortDescriptionLength: 0,
                    draftScheduleLength: 0,
                    finalScheduleLength: 0,
                    notesLength: 0,
                    awardsGiveawaysLength: 0,
                }
            }
        },
        addEventFailure: (state, action) => {
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.error,
                severity: 'error',
                loading: false,
                addEventButtonDisabled: action.payload.addEventButtonDisabled
            }
        },
        setCounter: (state, action) => {
            switch (action.payload.key) {
                case 'performanceSurface':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            performanceSurfaceLength: action.payload.value,
                        }
                    };
                case 'preliminaryLineup':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            preliminaryLineupLength: action.payload.value,
                        }
                    };
                case 'shortDescription':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            shortDescriptionLength: action.payload.value,
                        }
                    };
                case 'draftSchedule':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            draftScheduleLength: action.payload.value,
                        }
                    };
                case 'finalSchedule':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            finalScheduleLength: action.payload.value,
                        }
                    };
                case 'notes':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            notesLength: action.payload.value,
                        }
                    };
                case 'awardsGiveaways':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            awardsGiveawaysLength: action.payload.value,
                        }
                    };
                default:
                    return {
                        ...state
                    }
            }
        },
    },
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

// Action creators are generated for each case reducer function
export const {
    setSelectedTabValue,
    setEventName,
    setEventType,
    setEventOpenToType,
    setEventDate,
    setEventPlace,
    setRegisterOnTime,
    setFinalLatePaymentBy,
    setRuleQuestion,
    setQualifierForNationals,
    setPerformanceSurface,
    setPreliminaryLineup,
    setShortDescription,
    setDraftSchedule,
    setFinalSchedule,
    setAwardGiveaway,
    setOnTime,
    setLate,
    setCrossCompetitor,
    setSecondPerformance,
    setNote,
    setGeneral,
    setSenior,
    setChild,
    setChildFiveUnder,
    setParking,
    setFullRefundBy,
    setHalfRefundBy,
    setNoRefund,
    setMapUrlValue,
    setEventAddress,
    setEventImage,
    setDivisionLevelPdf,
    setSchedulePdf,
    setResultsPdf,
    setCovidGuidelinesPdf,
    setQualificationInvitePdf,
    addEventSuccess,
    addEventFailure,
    setAddEventButtonDisabled,
    addEventLoading,
    eventDateDecideLater,
    latePaymentByDecideLater,
    registerOnTimeByDecideLater,
    fullRefundByDecideLater,
    noRefundDecideLater,
    halfRefundByDecideLater,
    setCounter,
    setAccommodationTitle,
    setAccommodationContent,
    setAccommodationDate,
    accommodationDateDecideLater
} = addEventSlice.actions

export default addEventSlice.reducer