import {
  deleteContactusRequestSuccess,
  getContactusRequestRecordsFailure,
  getContactusRequestRecordsLoading,
  getContactusRequestRecordsSuccess,
  exportContactusRequestSuccess,
  exportContactusRequestFailure,
} from "./contactusSlice";
const { REACT_APP_API_URL } = process.env;

export function getContactusRequestRecords(
  page,
  pageSize,
  sortBy = "created_at",
  order = "DESC",
  search = ""
) {
  const newPage = page + 1;
  return function getContactusRequestRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getContactusRequestRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}contact-submissions?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getContactusRequestRecordsSuccess({
                contactusrequests: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getContactusRequestRecordsFailure({ error: responseJson.info })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getContactusRequestRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteContactusRequest(id, page, rowsPerPage) {
  return function deleteContactusRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}delete-contactsubmission/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getContactusRequestRecords(page, rowsPerPage));
          dispatch(deleteContactusRequestSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(
            getContactusRequestRecordsFailure({ error: responseJson.info })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getContactusRequestRecordsFailure({ error: err }));
      });
  };
}

export function exportContactusRequest() {
  return function exportContactusRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}export-contactus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            exportContactusRequestSuccess({ response: responseJson.data })
          );
        } else {
          console.log(responseJson);
          dispatch(
            exportContactusRequestFailure({ error: responseJson.error })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(exportContactusRequestFailure({ error: err }));
      });
  };
}
