import { createSlice } from '@reduxjs/toolkit'

export const eventsRecordSlice = createSlice({
    name: 'events',
    initialState: {
        events: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getEventRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getEventRecordsSuccess: (state, action) => {
            return {
                ...state,
                events: action.payload.events,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getEventRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteEventSuccess: (state, action) => {
            return {
                ...state,
                events: state.events.filter(event => event.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportEventSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportEventFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getEventRecordsLoading, setRecordDeleted, deleteEventSuccess, getEventRecordsSuccess,
    getEventRecordsFailure, setPage, setRowsPerPage, exportEventSuccess, exportEventFailure } = eventsRecordSlice.actions

export default eventsRecordSlice.reducer