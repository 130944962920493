import { Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import PropTypes from 'prop-types';

export function VendorsChart(props) {

    const [showElement, setShowElement] = React.useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 2000);
    },
        [])

    const [chartOptions, setChartOptions] = useState({
        options: {
            colors: [
                'rgba(78, 140, 167, 0.85)',
                'rgba(144, 202, 249, 0.85)',
                'rgba(30, 136, 229, 0.85)',
                'rgba(103, 58, 183, 0.85)',
            ],
            chart: {
                id: "basic-bar",
                stacked: true,
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `"Mulish", sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 16,
                    vertical: 16
                }
            },
        },
        series: [
            {
                name: 'Vendors',
                data: []
            },
            {
                name: 'Partners',
                data: []
            },
            {
                name: 'Judges',
                data: []
            },
            {
                name: 'Join Our Team',
                data: []
            }
        ]
    });

    useEffect(() => {
        setChartOptions({
            options: {
                colors: [
                    'rgba(78, 140, 167, 0.85)',
                    'rgba(144, 202, 249, 0.85)',
                    'rgba(30, 136, 229, 0.85)',
                    'rgba(103, 58, 183, 0.85)',
                ],
                chart: {
                    id: "basic-bar",
                    stacked: true,
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                fill: {
                    type: 'solid'
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: true,
                    fontSize: '14px',
                    fontFamily: `"Mulish", sans-serif`,
                    position: 'bottom',
                    offsetX: 20,
                    labels: {
                        useSeriesColors: false
                    },
                    markers: {
                        width: 16,
                        height: 16,
                        radius: 5
                    },
                    itemMargin: {
                        horizontal: 16,
                        vertical: 16
                    }
                },
            },
            series: [
                {
                    name: 'Vendors',
                    data: props?.vendorData
                },
                {
                    name: 'Partners',
                    data: props?.partnerData
                },
                {
                    name: 'Judges',
                    data: props?.judgeData
                },
                {
                    name: 'Join Our Team',
                    data: props?.teamData
                }
            ]
        });
    }, [props]);

    return (
        <Card className="dashboard_chart">
            <Typography variant="h6">{props.name}</Typography>
            <div className="chartWrapper">
                {showElement ? <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type="bar"
                    width="100%"
                /> : <></>}
            </div>
        </Card>
    );
}

VendorsChart.propTypes = {
    teamData: PropTypes.array,
    judgeData: PropTypes.array,
    partnerData: PropTypes.array,
    vendorData: PropTypes.array,
    name: PropTypes.string,
}
export default VendorsChart
