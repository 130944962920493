import {
  addSlideFailure,
  addSlideLoading,
  addSlideSuccess,
  setAddSlideButtonDisabled,
} from "./addslideSlice";
const { REACT_APP_API_URL } = process.env;

// Add Slide
export function addSlide(data) {
  let formData = new FormData();
  formData.append("heading", data.heading);
  formData.append("description", data.description);
  if (data.image) {
    formData.append("image", data.image);
  }
  if (data.display_order) {
    formData.append("display_order", parseInt(data.display_order));
  }

  return function addSlideThunk(dispatch) {
    dispatch(addSlideLoading());
    dispatch(setAddSlideButtonDisabled({ addSlideButtonDisabled: true }));
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}slider/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(
            addSlideSuccess({
              response: responseJson.message,
              addSlideButtonDisabled: false,
            })
          );
        } else {
          console.log(responseJson);
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            addSlideFailure({
              error: responseJson.message,
              errors: newErrors,
              addSlideButtonDisabled: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(addSlideFailure({ error: err }));
      });
  };
}
