import {
  editAdvertisementFailure,
  editAdvertisementSuccess,
  getAdvertisementDataById,
  getAdvertisementDataByIdFailure,
  getAdvertisementDataByIdSuccess,
  editAdvertisementLoading,
} from "./editadvertisementSlice";

import { getAdvertisementRecordsFailure } from "../advertisementSlice";

const { REACT_APP_API_URL } = process.env;

export function editAdvertisement(data, adId) {
  let formData = new FormData();
  if (data.image) {
    formData.append("image", data.image);
  }

  formData.append("link", data.link);

  formData.append("image_hidden", "file.jpg");

  formData.append("_method", "PUT");
  return function editAdvertisementThunk(dispatch) {
    dispatch(editAdvertisementLoading());
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}advertisement/update/${adId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            editAdvertisementSuccess({ response: responseJson.message })
          );
        } else {
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            editAdvertisementFailure({
              error: responseJson.message,
              errors: newErrors,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(editAdvertisementFailure({ error: err }));
      });
  };
}
export function getAdvertisement(id) {
  return function getEventByIdThunk(dispatch) {
    dispatch(getAdvertisementDataById());
    const token = window.localStorage.getItem("token");
    if (token) {
      fetch(`${REACT_APP_API_URL}advertisement/edit/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            dispatch(
              getAdvertisementDataByIdSuccess({
                editAdvertisement: responseJson,
              })
            );
          } else {
            dispatch(getAdvertisementDataByIdFailure({ error: "API Error" }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getAdvertisementRecordsFailure({ error: err }));
        });
    }
  };
}
