import {
  deleteTeamRequestSuccess,
  getTeamRequestRecordsFailure,
  getTeamRequestRecordsLoading,
  getTeamRequestRecordsSuccess,
  exportTeamRequestSuccess,
  exportTeamRequestFailure
} from "./joinourteamSlice";
const { REACT_APP_API_URL } = process.env;

export function getTeamRequestRecords(page, pageSize, sortBy='created_at', order='DESC', search='') {
  const newPage = page + 1;
  return function getTeamRequestRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getTeamRequestRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}team-requests?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getTeamRequestRecordsSuccess({
                teamrequests: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(getTeamRequestRecordsFailure({ error: responseJson.info }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getTeamRequestRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteTeamRequest(id, page, rowsPerPage) {
  return function deleteTeamRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}team-request/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getTeamRequestRecords(page, rowsPerPage));
          dispatch(deleteTeamRequestSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getTeamRequestRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getTeamRequestRecordsFailure({ error: err }));
      });
  };
}

export function exportTeamRequest() {
  return function exportTeamRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}export-join-team-request`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(exportTeamRequestSuccess({ response: responseJson.data }));
        } else {
          console.log(responseJson);
          dispatch(exportTeamRequestFailure({ error: responseJson.error }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(exportTeamRequestFailure({ error: err }));
      });
  };
}
