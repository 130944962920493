import {
  deleteJudgeRequestSuccess,
  getJudgeRequestRecordsFailure,
  getJudgeRequestRecordsLoading,
  getJudgeRequestRecordsSuccess,
  exportJudgeRequestSuccess,
  exportJudgeRequestFailure,
} from "./judgeSlice";
const { REACT_APP_API_URL } = process.env;

export function getJudgeRequestRecords(
  page,
  pageSize,
  sortBy = "created_at",
  order = "DESC",
  search = ""
) {
  const newPage = page + 1;
  return function getJudgeRequestRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getJudgeRequestRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}judge-requests?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getJudgeRequestRecordsSuccess({
                judgerequests: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getJudgeRequestRecordsFailure({ error: responseJson.info })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getJudgeRequestRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteJudgeRequest(id, page, rowsPerPage) {
  return function deleteJudgeRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}delete-judge-request/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getJudgeRequestRecords(page, rowsPerPage));
          dispatch(deleteJudgeRequestSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getJudgeRequestRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getJudgeRequestRecordsFailure({ error: err }));
      });
  };
}

export function exportJudgeRequest() {
  return function exportJudgeRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}export-judges`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(exportJudgeRequestSuccess({ response: responseJson.data }));
        } else {
          console.log(responseJson);
          dispatch(exportJudgeRequestFailure({ error: responseJson.error }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(exportJudgeRequestFailure({ error: err }));
      });
  };
}
