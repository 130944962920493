import { createSlice } from "@reduxjs/toolkit";

export const websiteSettingSlice = createSlice({
  name: "websitesetting",
  initialState: {
    message: "",
    data: {
      id: 1,
      email: "",
      phone_number: "",
      address: "",
      heading: "",
      fax: "",
      copyright_content: "",
      header_logo: "",
      footer_logo: "",
      facebook_link: "",
      twitter_link: "",
      instagram_link: "",
      created_at: "",
      updated_at: "",
      contact_us_image: "",
    },
    errors: {
      address: { isValid: true, error: "" },
      heading: { isValid: true, error: "" },
      phone: { isValid: true, error: "" },
      email: { isValid: true, error: "" },
      fax: { isValid: true, error: "" },
      copyright: { isValid: true, error: "" },
      facebook: { isValid: true, error: "" },
      twitter: { isValid: true, error: "" },
      instagram: { isValid: true, error: "" },
    },
    severity: "error",
    mounted: false,
    loading: false,
    isFormValid: false,
    buttonDisabled: false,
  },
  reducers: {
    saveWebsiteSetting: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    saveWebsiteSettingError: (state, action) => {
      return {
        ...state,
        message: action.payload,
        data: state.data,
        severity: "error",
        loading: false,
      };
    },
    saveWebsiteSettingSuccess: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        data: {
          id: action.payload.data.id,
          email: action.payload.data.email,
          phone_number: action.payload.data.phone_number,
          address: action.payload.data.address,
          heading: action.payload.data.heading,
          fax: action.payload.data.fax,
          copyright_content: action.payload.data.copyright_content,
          header_logo: action.payload.data.header_logo,
          footer_logo: action.payload.data.footer_logo,
          facebook_link: action.payload.data.facebook_link,
          twitter_link: action.payload.data.twitter_link,
          instagram_link: action.payload.data.instagram_link,
          contact_us_image: action.payload.data.contact_us_image,
        },
        severity: "success",
        loading: false,
      };
    },
    getWebsiteSettings: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    setWebsiteSettings: (state, action) => {
      return {
        ...state,
        data: {
          id: action.payload.id,
          email: action.payload.email,
          phone_number: action.payload.phone_number,
          address: action.payload.address,
          heading: action.payload.heading,
          fax: action.payload.fax,
          copyright_content: action.payload.copyright_content,
          header_logo: action.payload.header_logo,
          footer_logo: action.payload.footer_logo,
          facebook_link: action.payload.facebook_link,
          twitter_link: action.payload.twitter_link,
          instagram_link: action.payload.instagram_link,
          contact_us_image: action.payload.contact_us_image,
        },
        mounted: true,
        loading: false,
      };
    },
    setAddress: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          address: action.payload.address,
        },
      };
    },
    setHeading: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          heading: action.payload.heading,
        },
      };
    },
    setFax: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          fax: action.payload.fax,
        },
      };
    },
    setPhone: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          phone_number: action.payload.phone_number,
        },
      };
    },
    setEmail: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.email,
        },
      };
    },
    setCopyrightContent: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          copyright_content: action.payload.copyright_content,
        },
      };
    },
    setFacebookLink: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          facebook_link: action.payload.facebook_link,
        },
      };
    },
    setTwitterLink: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          twitter_link: action.payload.twitter_link,
        },
      };
    },
    setInstagramLink: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          instagram_link: action.payload.instagram_link,
        },
      };
    },
    setButtonDisabled: (state, action) => {
      return {
        ...state,
        buttonDisabled: action.payload.buttonDisabled,
      };
    },
    setIsFormValid: (state, action) => {
      return {
        ...state,
        isFormValid: action.payload,
      };
    },
    setErrors: (state, action) => {
      switch (action.payload.key) {
        case "email":
          return {
            ...state,
            errors: {
              ...state.errors,
              email: action.payload.value,
            },
          };

        case "heading":
          return {
            ...state,
            errors: {
              ...state.errors,
              heading: action.payload.value,
            },
          };

        case "phone":
          return {
            ...state,
            errors: {
              ...state.errors,
              phone: action.payload.value,
            },
          };

        case "copyright":
          return {
            ...state,
            errors: {
              ...state.errors,
              copyright: action.payload.value,
            },
          };

        case "facebook":
          return {
            ...state,
            errors: {
              ...state.errors,
              facebook: action.payload.value,
            },
          };

        case "twitter":
          return {
            ...state,
            errors: {
              ...state.errors,
              twitter: action.payload.value,
            },
          };

        case "instagram":
          return {
            ...state,
            errors: {
              ...state.errors,
              instagram: action.payload.value,
            },
          };
        case "address":
          return {
            ...state,
            errors: {
              ...state.errors,
              address: action.payload.value,
            },
          };
        case "fax":
          return {
            ...state,
            errors: {
              ...state.errors,
              fax: action.payload.value,
            },
          };
        default:
          return {
            ...state,
          };
      }
    },
  },
});

export const {
  saveWebsiteSetting,
  saveWebsiteSettingSuccess,
  saveWebsiteSettingError,
  getWebsiteSettings,
  setWebsiteSettings,
  setAddress,
  setFax,
  setHeading,
  setPhone,
  setCopyrightContent,
  setFacebookLink,
  setEmail,
  setInstagramLink,
  setTwitterLink,
  setErrors,
  setIsFormValid,
  setButtonDisabled,
} = websiteSettingSlice.actions;

export default websiteSettingSlice.reducer;
