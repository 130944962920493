import {
    Alert,
    Button, Card, Checkbox, CssBaseline, FormControl, FormControlLabel, Grid, Input,  
    InputAdornment, InputLabel, OutlinedInput,
    Paper, Stack, TextField, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Main } from '../../main/main';
import Sidebar from '../../sidebar/sidebar';
import Topbar from '../../topbar/topbar';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Editor from '../../../shared/editor/editor';
import locationImg from '../../../assets/location-image.gif';
import {
    conferenceDateDecideLater, deadlineDateDecideLater, fullRefundDateDecideLater, halfRefundDateDecideLater, noRefundAfterDecideLater, setConferenceAddress, setConferenceDate, setConferenceImage, setConferenceName,
    setConferencePlace,
    setCovidGuidelinesPdf,
    setDeadlineDate,
    setFeeNote,
    setFeeOneToNine,
    setFeeTenToNineteen,
    setFeeTwentyPlus,
    setFullRefundDate,
    setHalfRefundDate,
    setMapCode,
    setNoRefundAfterDate,
    setSchedulePdf,
    setAccommodationTitle,
    setAccommodationContent,
    setAccommodationDate,
    accommodationDateDecideLater,
} from './addconferenceSlice';

import { addConference } from '../conferences.function';


export function AddConferences() {
    const conferenceImage = useSelector(state => state.addConference.conference?.conferenceImage);
    const [conferencePicture, savePicture] = useState(null);
    const [schedulePdf, saveSchedulePdf] = useState(null);
    const [covidPdf, saveCovidPdf] = useState(null);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const conference = useSelector(state => state.addConference?.conference);
    const conferenceDateDecideLaterValue = useSelector(state => state.addConference?.decideLater?.conferenceDate);
    const deadlineDateDecideLaterValue = useSelector(state => state.addConference?.decideLater?.deadlineDate);
    const refundFullDecideLaterValue = useSelector(state => state.addConference?.decideLater?.refundFull);
    const refundHalfDecideLaterValue = useSelector(state => state.addConference?.decideLater?.refundHalf);
    const noRefundDecideLaterValue = useSelector(state => state.addConference?.decideLater?.noRefund);
    const accommodationDateDecideLaterValue = useSelector((state) => state.addConference.decideLater.accommodation_date);
    const accommodation_title = useSelector(state => state.addConference.conference.accomodation?.accommodation_title);
    const accommodation_content = useSelector(state => state.addConference.conference.accomodation?.accommodation_content);
    const accommodation_date = useSelector(state => state.addConference.conference.accomodation?.accommodation_date);

    const schedulePdfName = useSelector(state => state.addConference.conference.schedulePdfName);
    const covidGuidelinesPdfName = useSelector(state => state.addConference.conference.covidGuidelinesPdfName);
    const message = useSelector(state => state.addConference.message);
    const severity = useSelector(state => state.addConference.severity);
    const errors = useSelector(state => state.addConference.errors);
    const addConferenceButtonDisabled = useSelector(state => state.addConference.addConferenceButtonDisabled);

    const handleClickBack = () => {
        navigate("/conferences");
    };

    const conferenceNameHandleChange = (event) => {
        dispatch(setConferenceName({ conferenceName: event.target.value }));
    }

    const conferencePlaceHandleChange = (event) => {
        dispatch(setConferencePlace({ conferencePlace: event.target.value }));
    }

    const conferenceDateHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setConferenceDate({ conferenceDate: dateValue }));
    };

    const conferenceDateDecideLaterHandleChange = (event) => {
        dispatch(conferenceDateDecideLater({ decideLater: event.target.checked }));
    }

    const deadlineDateHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setDeadlineDate({ deadlineDate: dateValue }));
    };

    const deadlineDateDecideLaterHandleChange = (event) => {
        dispatch(deadlineDateDecideLater({ decideLater: event.target.checked }));
    }

    const refundFullByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setFullRefundDate({ refundFull: dateValue }));
    };

    const refundFullByDecideLaterHandleChange = (event) => {
        dispatch(fullRefundDateDecideLater({ decideLater: event.target.checked }));
    }

    const refundHalfByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setHalfRefundDate({ refundHalf: dateValue }));
    };

    const refundHalfByDecideLaterHandleChange = (event) => {
        dispatch(halfRefundDateDecideLater({ decideLater: event.target.checked }));
    }

    const noRefundAfterHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setNoRefundAfterDate({ noRefund: dateValue }));
    };

    const noRefundAfterDecideLaterHandleChange = (event) => {
        dispatch(noRefundAfterDecideLater({ decideLater: event.target.checked }));
    }

    const feeOneToNineHandleChange = (event) => {
        dispatch(setFeeOneToNine({ feeOneToNine: event.target.value }));
    }

    const feeTenToNineteenHandleChange = (event) => {
        dispatch(setFeeTenToNineteen({ feeTenToNineteen: event.target.value }));
    }

    const feeTwentyPlusHandleChange = (event) => {
        dispatch(setFeeTwentyPlus({ feeTwentyPlus: event.target.value }));
    }

    const addressHandleChange = (event) => {
        dispatch(setConferenceAddress({ conferenceAddress: event.target.value }));
    }

    const mapCodeHandleChange = (event) => {
        dispatch(setMapCode({ mapCode: event.target.value }));
    }

    const feeNoteHandleChange = (event) => {
        dispatch(setFeeNote({ feeNote: event.target.value }));
    }

    const accommmodationDateDecideLaterHandleChange = (event) => {
        dispatch(accommodationDateDecideLater({ decideLater: event.target.checked }));
    }

    const accommodationTitleChange = (event) => {
        dispatch(setAccommodationTitle({ title: event.target.value }));
    }

    const accommodationContentChange = (data) => {
        dispatch(setAccommodationContent({ content: data }));
    }

    const accommodationDateChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setAccommodationDate({ date: dateValue }));
    }


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const conferenceImageHandleChange = (event) => {
        savePicture(event.target.files[0]);
        getBase64(event.target.files[0])
            .then(base64 => {
                const file = base64;
                dispatch(setConferenceImage({
                    conferenceImage: file
                }));
            });
    }

    function convertDateTimeFormat(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const schedulePdfHandleChange = (event) => {
        saveSchedulePdf(event.target.files[0]);
        dispatch(setSchedulePdf({
            schedulePdfName: event.target.files[0].name
        }));
    }

    const covidGuidelinesPdfHandleChange = (event) => {
        saveCovidPdf(event.target.files[0]);
        dispatch(setCovidGuidelinesPdf({
            covidGuidelinesPdfName: event.target.files[0].name
        }));
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        const addConferenceData = {
            'name': conference?.conferenceName,
            'place': conference?.conferencePlace,
            'type': conference?.conferenceType,
            'accommodation_id': conference?.accommodationId,
            'conference_date': conference?.conferenceDate,
            'deadline_date': conference?.deadlineDate,
            'refund_full': conference?.refundFull,
            'refund_half': conference?.refundHalf,
            'refund_not': conference?.noRefund,
            'fee_one_to_ten': conference?.feeOneToNine?conference?.feeOneToNine:0,
            'fee_ten_to_nineteen': conference?.feeTenToNineteen?conference?.feeTenToNineteen:0,
            'fee_twenty_plus': conference?.feeTwentyPlus?conference?.feeTwentyPlus:0,
            'address': conference?.conferenceAddress,
            'map_code': conference?.mapCode,
            'fee_note': conference?.feeNote,
            'image': conferencePicture,
            'schedule_pdf': schedulePdf,
            'covid_pdf': covidPdf,
            'accommodation': {
                'accommodation_title': accommodation_title,
                'accommodation_content': accommodation_content,
                'accommodation_date': accommodation_date,
            },
            'decideLater': {
                'conferenceDate': conferenceDateDecideLaterValue,
                'deadlineDate': deadlineDateDecideLaterValue,
                'fullRefundDate': refundFullDecideLaterValue,
                'halfRefundDate': refundHalfDecideLaterValue,
                'noRefundAfterDate': noRefundDecideLaterValue,
                'accommodation_date': accommodationDateDecideLaterValue,
            }
        };
        dispatch(addConference(addConferenceData));
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Sidebar />
                <Main open={sidebarState} className='dashbaordWrapper'>
                    <Topbar />
                    <Paper elevation={0} sx={{
                        background: '#e3f2fd', padding: '1.5rem',
                        minHeight: 'calc(100% - 64px)'
                    }}>
                        <Card elevation={1} sx={{ padding: '2rem' }}>
                            <div
                                style={{
                                    alignItems: "center",
                                    alignContent: "center",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    marginBottom: "1.5rem",
                                }}
                            >
                                <Typography variant="h3">Add Conference</Typography>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    startIcon={<ArrowBackIcon />}
                                    onClick={handleClickBack}
                                >
                                    Back
                                </Button>
                            </div>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={4}>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="name"
                                            value={conference?.conferenceName}
                                            onChange={conferenceNameHandleChange}
                                            label="Name" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="place"
                                            value={conference?.conferencePlace}
                                            onChange={conferencePlaceHandleChange}
                                            label="Place" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Conference Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.conferenceDate}
                                                    onChange={conferenceDateHandleChange}
                                                    disabled={conferenceDateDecideLaterValue}
                                                    disablePast
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={conferenceDateDecideLaterHandleChange}
                                            checked={conferenceDateDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Deadline Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.deadlineDate}
                                                    disabled={deadlineDateDecideLaterValue}
                                                    onChange={deadlineDateHandleChange}
                                                    disablePast
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={deadlineDateDecideLaterHandleChange}
                                            checked={deadlineDateDecideLaterValue}
                                        />} label="Decide later" />

                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="100% Refund by"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.refundFull}
                                                    disabled={refundFullDecideLaterValue}
                                                    onChange={refundFullByHandleChange}
                                                    disablePast
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={refundFullByDecideLaterHandleChange}
                                            checked={refundFullDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="50% Refund by"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.refundHalf}
                                                    disabled={refundHalfDecideLaterValue}
                                                    onChange={refundHalfByHandleChange}
                                                    disablePast
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={refundHalfByDecideLaterHandleChange}
                                            checked={refundHalfDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="No Refund after"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.noRefund}
                                                    disabled={noRefundDecideLaterValue}
                                                    onChange={noRefundAfterHandleChange}
                                                    disablePast
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={noRefundAfterDecideLaterHandleChange}
                                            checked={noRefundDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_one_to_nine">Fee One to Nine</InputLabel>
                                            <OutlinedInput
                                                id="fee_one_to_nine"
                                                value={conference?.feeOneToNine}
                                                onChange={feeOneToNineHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee One to Nine"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_ten_to_nineteen">Fee Ten to Nineteen</InputLabel>
                                            <OutlinedInput
                                                id="fee_ten_to_nineteen"
                                                value={conference?.feeTenToNineteen}
                                                onChange={feeTenToNineteenHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee One to Nine"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_twenty_plus">Fee Twenty Plus</InputLabel>
                                            <OutlinedInput
                                                id="fee_twenty_plus"
                                                value={conference?.feeTwentyPlus}
                                                onChange={feeTwentyPlusHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee Twenty Plus"
                                            />
                                        </FormControl>
                                    </Grid>       
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Grid container spacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField fullWidth multiline rows={2} id="address"
                                                value={conference?.conferenceAddress}
                                                onChange={addressHandleChange}
                                                label="Address" variant="outlined" />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField fullWidth multiline rows={7} id="map_code"
                                                    value={conference?.mapCode}
                                                    onChange={mapCodeHandleChange}
                                                    label="Map Embed Code" variant="outlined" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <div className="locationImage">
                                            <Typography variant="h6">How to get embed code?</Typography>
                                            <img src={locationImg} alt="" />
                                        </div>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth multiline rows={2} id="fee_note"
                                            value={conference?.feeNote}
                                            onChange={feeNoteHandleChange}
                                            label="Fee Note" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="accommodation_title"
                                            value={accommodation_title}
                                            onChange={accommodationTitleChange}
                                            label="Accommodation Title" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    disablePast={true}
                                                    label="Accommodation Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={accommodation_date}
                                                    disabled={accommodationDateDecideLaterValue}
                                                    onChange={accommodationDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={accommmodationDateDecideLaterHandleChange}
                                            checked={accommodationDateDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Editor
                                            data={accommodation_content}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                accommodationContentChange(data);
                                            }} />
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <div className='inputFileWrapper'>
                                            <label htmlFor="schedule_pdf" className="file-input pictureInput">
                                                <Button color='secondary' variant="outlined" component="span"
                                                    className='fileInputButton'>
                                                    Select Schedule pdf</Button>
                                                <Input id="schedule_pdf" onChange={schedulePdfHandleChange}
                                                    accept='pdf/*'
                                                    inputProps={{ 'accept': 'application/pdf' }} type="file" />
                                            </label>
                                            <div className='fileName addEvent'><span><PictureAsPdfIcon /></span>
                                                {schedulePdfName ? schedulePdfName : 'File name will show here'}</div>
                                            {/* <label className="recomemdedText">Recommended Size : 2mb</label> */}
                                        </div>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <div className='inputFileWrapper'>
                                            <label htmlFor="covid_pdf" className="file-input pictureInput">
                                                <Button color='secondary' variant="outlined" component="span"
                                                    className='fileInputButton'>
                                                    Select Covid pdf</Button>
                                                <Input id="covid_pdf" onChange={covidGuidelinesPdfHandleChange}
                                                    accept='pdf/*'
                                                    inputProps={{ 'accept': 'application/pdf' }}
                                                    type="file" />
                                            </label>
                                            <div className='fileName addEvent'><span><PictureAsPdfIcon /></span>
                                                {covidGuidelinesPdfName ?
                                                    covidGuidelinesPdfName : 'File name will show here'}</div>
                                            {/* <label className="recomemdedText">Recommended Size : 2mb</label> */}
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <label htmlFor="image" className="file-input pictureInput">
                                            <Button color='secondary' variant="outlined" component="span"
                                                className='fileInputButton'>
                                                Select Image</Button>
                                            <Input id="image" onChange={conferenceImageHandleChange} accept='image/*'
                                                inputProps={{ 'accept': 'image/*' }}
                                                type="file" />
                                            <label className="recomemdedText">Recommended Size : 472px X 282px</label>
                                        </label>
                                        {!!conferenceImage && (
                                            <div className='fileName'><img src={conferenceImage} alt=''
                                                className='eventImage' /></div>
                                        )}
                                    </Grid>
                                    
                                    <Grid item lg={12} md={12} sm={6} xs={12}>
                                        <Button onClick={handleSubmit} disabled={addConferenceButtonDisabled} style={{ marginBottom: '1rem' }}
                                            variant='contained' color="primary">Submit</Button>
                                        {!!message && (
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Alert severity={severity}>{message}</Alert>
                                            </Grid>
                                        )}
                                        {!!errors && errors.map((error) => (
                                            <Grid item key={error} lg={12} md={12} sm={12} xs={12}>
                                                <Alert severity={severity}>{error}</Alert>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Paper>
                </Main>
            </Box>
        </>
    )
}

export default AddConferences;
