import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CssBaseline,
  Grid,
  Input,
  Paper,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Topbar from "../topbar/topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  saveWebsiteSettingError,
  setAddress,
  setFax,
  setHeading,
  setCopyrightContent,
  setEmail,
  setFacebookLink,
  setInstagramLink,
  setPhone,
  setTwitterLink,
  setErrors,
  setIsFormValid,
} from "./websitesettingSlice";
import { Main } from "../main/main";
import {
  validateEmail,
  validatePhoneFax,
  validateString,
  validateUrl,
} from "../../shared/validations";
import {
  getWebsiteSettingsData,
  sendWebsiteSettingRequest,
} from "./websitesetting.function";

export function WebsiteSetting() {
  const { REACT_APP_API_URL } = process.env;
  const dispatch = useDispatch();
  const websiteSettingsdata = useSelector((state) => state.websiteSetting.data);
  const loading = useSelector((state) => state.websiteSetting.loading);
  const websiteSettingsmessage = useSelector(
    (state) => state.websiteSetting.message
  );
  const severity = useSelector((state) => state.websiteSetting.severity);
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);
  const errors = useSelector((state) => state.websiteSetting.errors);
  const buttonDisabled = useSelector(
    (state) => state.websiteSetting.buttonDisabled
  );
  const [headerLogoName, setHeaderLogoName] = useState("");
  const [footerLogoName, setFooterLogoName] = useState("");
  const [contactUsImageName, setContactUsImageName] = useState("");
  const isFormValid = useSelector((state) => state.websiteSetting.isFormValid);
  const mounted = useSelector((state) => state?.websiteSetting?.mounted);

  useEffect(() => {
    if (!mounted) {
      dispatch(getWebsiteSettingsData());
    }
    let formValid = false;
    setTimeout(() => {
      for (let i in errors) {
        if (errors[i].isValid === false) {
          formValid = false;
          break;
        } else {
          formValid = true;
        }
      }
      dispatch(setIsFormValid(formValid));
    }, 1000);
  }, [REACT_APP_API_URL, dispatch, mounted, errors]);

  const verifyEmail = (e) => {
    const email = e.target.value;
    dispatch(setEmail({ email: email }));
    dispatch(setErrors({ key: "email", value: validateEmail(email, "email") }));
  };

  const verifyAddress = (e) => {
    const address = e.target.value;
    dispatch(setAddress({ address: address }));
    dispatch(
      setErrors({ key: "address", value: validateString(address, "address") })
    );
  };

  const verifyHeading = (e) => {
    const heading = e.target.value;
    dispatch(setHeading({ heading: heading }));
    dispatch(
      setErrors({ key: "heading", value: validateString(heading, "heading") })
    );
  };

  const verifyCopyright = (e) => {
    const copyright = e.target.value;
    dispatch(setCopyrightContent({ copyright_content: copyright }));
    dispatch(
      setErrors({
        key: "copyright",
        value: validateString(copyright, "copyright"),
      })
    );
  };

  const verifyPhonenumber = (e) => {
    const phoneNumber = e.target.value;
    dispatch(setPhone({ phone_number: phoneNumber }));
    dispatch(
      setErrors({ key: "phone", value: validatePhoneFax(phoneNumber, "phone") })
    );
  };

  const verifyFax = (e) => {
    const fax = e.target.value;
    dispatch(setFax({ fax: fax }));
    dispatch(setErrors({ key: "fax", value: validatePhoneFax(fax, "fax") }));
  };

  const verifyTwitter = (e) => {
    const twitterlink = e.target.value;
    dispatch(setTwitterLink({ twitter_link: twitterlink }));
    dispatch(
      setErrors({ key: "twitter", value: validateUrl(twitterlink, "twitter") })
    );
  };

  const verifyFacebook = (e) => {
    const facebookLink = e.target.value;
    dispatch(setFacebookLink({ facebook_link: facebookLink }));
    dispatch(
      setErrors({
        key: "facebook",
        value: validateUrl(facebookLink, "facebook"),
      })
    );
  };

  const verifyInstagram = (e) => {
    const instagramLink = e.target.value;
    dispatch(setInstagramLink({ instagram_link: instagramLink }));
    dispatch(
      setErrors({
        key: "instagram",
        value: validateUrl(instagramLink, "instagram"),
      })
    );
  };

  const verifyHeaderLogo = (e) => {
    const headerLogoName = e.target.files[0].name;
    setHeaderLogoName(headerLogoName);
  };

  const verifyFooterLogo = (e) => {
    const footerLogoName = e.target.files[0].name;
    setFooterLogoName(footerLogoName);
  };

  const verifyContactUsImage = (e) => {
    console.log(e);
    const contactUsImageName = e.target.files[0].name;
    setContactUsImageName(contactUsImageName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const headerLogo = e.nativeEvent.target[19].files[0];
    const footerLogo = e.nativeEvent.target[20].files[0];
    const contactUsImage = e.nativeEvent.target[21].files[0];

    dispatch(
      setErrors({
        key: "address",
        value: validateString(websiteSettingsdata.address, "address"),
      })
    );
    dispatch(
      setErrors({
        key: "heading",
        value: validateString(websiteSettingsdata.heading, "heading"),
      })
    );
    dispatch(
      setErrors({
        key: "phone",
        value: validatePhoneFax(websiteSettingsdata.phone_number, "phone"),
      })
    );
    dispatch(
      setErrors({
        key: "email",
        value: validateString(websiteSettingsdata.email, "email"),
      })
    );
    dispatch(
      setErrors({
        key: "facebook",
        value: validateUrl(websiteSettingsdata.facebook_link, "facebook"),
      })
    );
    dispatch(
      setErrors({
        key: "twitter",
        value: validateUrl(websiteSettingsdata.twitter_link, "twitter"),
      })
    );
    dispatch(
      setErrors({
        key: "instagram",
        value: validateUrl(websiteSettingsdata.instagram_link, "instagram"),
      })
    );
    dispatch(
      setErrors({
        key: "fax",
        value: validatePhoneFax(websiteSettingsdata.fax, "fax"),
      })
    );
    dispatch(
      setErrors({
        key: "copyright",
        value: validateString(
          websiteSettingsdata.copyright_content,
          "copyright"
        ),
      })
    );

    setTimeout(() => {
      if (isFormValid === true) {
        dispatch(
          sendWebsiteSettingRequest(
            websiteSettingsdata.email,
            websiteSettingsdata.address,
            websiteSettingsdata.heading,
            websiteSettingsdata.phone_number,
            websiteSettingsdata.copyright_content,
            websiteSettingsdata.facebook_link,
            websiteSettingsdata.twitter_link,
            websiteSettingsdata.instagram_link,
            headerLogo,
            footerLogo,
            contactUsImage,
            websiteSettingsdata.fax
          )
        );
      } else {
        dispatch(saveWebsiteSettingError("Please fix validation errors."));
      }
    }, 1500);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "100vh",
          }}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2} item md={12}>
              <Card elevation={1} sx={{ padding: "2rem" }}>
                <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>
                  Website Settings
                </Typography>
                <form onSubmit={(e) => handleSubmit(e)} id="websiteSettingForm">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} item>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyHeading(e)}
                          id="heading"
                          label="Heading"
                          variant="outlined"
                          fullWidth
                          error={!errors.heading?.isValid}
                          helperText={errors.heading?.error}
                          value={websiteSettingsdata?.heading}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyAddress(e)}
                          id="address"
                          label="Address"
                          variant="outlined"
                          fullWidth
                          error={!errors.address?.isValid}
                          helperText={errors.address?.error}
                          value={websiteSettingsdata?.address}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyPhonenumber(e)}
                          id="phoneNumber"
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          error={!errors.phone?.isValid}
                          helperText={errors.phone?.error}
                          value={websiteSettingsdata?.phone_number}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyEmail(e)}
                          id="emailAddress"
                          label="Email Address"
                          variant="outlined"
                          fullWidth
                          error={!errors.email?.isValid}
                          helperText={errors.email?.error}
                          value={websiteSettingsdata?.email}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyFacebook(e)}
                          id="facebookLink"
                          label="Facebook Link"
                          variant="outlined"
                          fullWidth
                          error={!errors.facebook?.isValid}
                          helperText={errors.facebook?.error}
                          value={websiteSettingsdata?.facebook_link}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyTwitter(e)}
                          id="twitterLink"
                          label="Twitter Link"
                          variant="outlined"
                          fullWidth
                          error={!errors.twitter?.isValid}
                          helperText={errors.twitter?.error}
                          value={websiteSettingsdata?.twitter_link}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyInstagram(e)}
                          id="instagramLink"
                          label="Instagram Link"
                          variant="outlined"
                          fullWidth
                          error={!errors.instagram?.isValid}
                          helperText={errors.instagram?.error}
                          value={websiteSettingsdata?.instagram_link}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          onChange={(e) => verifyFax(e)}
                          id="fax"
                          label="Fax"
                          variant="outlined"
                          fullWidth
                          error={!errors.fax?.isValid}
                          helperText={errors.fax?.error}
                          value={websiteSettingsdata?.fax}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          onChange={(e) => verifyCopyright(e)}
                          id="copyrightText"
                          label="Copyright text"
                          multiline
                          rows={2}
                          variant="outlined"
                          fullWidth
                          error={!errors.copyright?.isValid}
                          helperText={errors.copyright?.error}
                          value={websiteSettingsdata?.copyright_content}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label htmlFor="headerLogo" className="file-input">
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Select Header Logo
                          </Button>
                          <Input
                            onChange={(e) => verifyHeaderLogo(e)}
                            id="headerLogo"
                            inputProps={{ accept: "image/*" }}
                            type="file"
                          />
                          <label className="recomemdedText">
                            Recommended Size : 176px X 110px
                          </label>
                          <div className="fileName">{headerLogoName}</div>
                          <p className="errorMessage">
                            {errors.headerLogo?.error}
                          </p>
                        </label>
                        {!!websiteSettingsdata?.header_logo && (
                          <img
                            src={websiteSettingsdata?.header_logo}
                            width="170px"
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label htmlFor="footerLogo" className="file-input">
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Select Footer Logo
                          </Button>
                          <Input
                            onChange={(e) => verifyFooterLogo(e)}
                            id="footerLogo"
                            inputProps={{ accept: "image/*" }}
                            type="file"
                          />
                          <label className="recomemdedText">
                            Recommended Size : 220px X 137px
                          </label>
                          <div className="fileName">{footerLogoName}</div>
                          <p className="errorMessage">
                            {errors.footerLogo?.error}
                          </p>
                        </label>
                        {!!websiteSettingsdata?.footer_logo && (
                          <img
                            src={websiteSettingsdata?.footer_logo}
                            width="170px"
                            alt=""
                          />
                        )}
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label htmlFor="contactusImage" className="file-input">
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Contact Us Image
                          </Button>
                          <Input
                            onChange={(e) => verifyContactUsImage(e)}
                            id="contactusImage"
                            inputProps={{ accept: "image/*" }}
                            type="file"
                          />
                          <label className="recomemdedText">
                            Recommended Size : 445px X 660px
                          </label>
                          <div className="fileName">{contactUsImageName}</div>
                          <p className="errorMessage">
                            {errors.ContactUsImage?.error}
                          </p>
                        </label>
                        {!!websiteSettingsdata?.contact_us_image && (
                          <img
                            src={websiteSettingsdata?.contact_us_image}
                            width="170px"
                            alt=""
                          />
                        )}
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          type="submit"
                          sx={{ margin: "1rem 0" }}
                          disabled={buttonDisabled}
                          variant="contained"
                          disableElevation
                          size="large"
                        >
                          Save Website Settings
                        </Button>
                      </Grid>
                      {websiteSettingsmessage !== "" && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>
                            {websiteSettingsmessage}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}
export default WebsiteSetting;
