import {
  addEventFailure,
  addEventLoading,
  addEventSuccess,
  setAddEventButtonDisabled,
} from "./addevents/addeventSlice";
import {
  editEventFailure,
  editEventLoading,
  editEventSuccess,
  getEventDataById,
  getEventDataByIdFailure,
  getEventDataByIdSuccess,
} from "./editevents/editeventSlice";
import {
  deleteEventSuccess,
  exportEventFailure,
  exportEventSuccess,
  getEventRecordsFailure,
  getEventRecordsLoading,
  getEventRecordsSuccess,
} from "./listevents/listeventsSlice";
const { REACT_APP_API_URL } = process.env;

export function getEventRecords(page, pageSize, sortBy = "event_date", order = "DESC", search = "") {
  const newPage = page + 1;
  return function getEventRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getEventRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}events?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getEventRecordsSuccess({
                events: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(getEventRecordsFailure({ error: responseJson.info }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getEventRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteEvent(id, page, rowsPerPage) {
  return function deleteEventThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}event/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getEventRecords(page, rowsPerPage));
          dispatch(deleteEventSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getEventRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getEventRecordsFailure({ error: err }));
      });
  };
}

export function exportEvent() {
  return function exportEventThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}export-events`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(exportEventSuccess({ response: responseJson.data }));
        } else {
          console.log(responseJson);
          dispatch(exportEventFailure({ error: responseJson.error }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(exportEventFailure({ error: err }));
      });
  };
}

export function addEvent(data, performanceTextAreaCount) {
  let formData = new FormData();
  formData.append("name", data.name);
  formData.append("type", data.type);
  formData.append("open_to", data.open_to);

  if (!data.decideLater.eventDate) {
    formData.append("event_date", data.event_date);
  }

  if (!data.decideLater.registerOnTime) {
    formData.append("register_by", data.register_by);
  }

  if (!data.decideLater.finalLatePaymentBy) {
    formData.append("final_payment_by", data.final_payment_by);
  }

  if (!data.decideLater.fullRefundBy) {
    formData.append("refund_full", data.refund_full);
  }

  if (!data.decideLater.halfRefundBy) {
    formData.append("refund_half", data.refund_half);
  }

  if (!data.decideLater.noRefund) {
    formData.append("refund_not", data.refund_not);
  }

  formData.append("place", data.place);
  formData.append("rules_question", data.rules_question);
  formData.append("qualifier", data.qualifier);
  formData.append("performance_surface", data.performance_surface);
  formData.append("preliminary_lineup", data.preliminary_lineup);
  formData.append("short_description", data.short_description);
  formData.append("draft_schedule", data.draft_schedule);
  formData.append("final_schedule", data.final_schedule);
  formData.append("award_giveaway", data.award_giveaway);
  formData.append("youth_ontime", parseFloat(data.youth_ontime));
  formData.append("youth_late", parseFloat(data.youth_late));
  formData.append("youth_crosscompetitor", parseFloat(data.youth_crosscompetitor));
  formData.append("youth_2ndperformance", parseFloat(data.youth_2ndperformance));
  formData.append("youth_note", data.youth_note);
  formData.append("spector_general", parseFloat(data.spector_general));
  formData.append("spector_senior", parseFloat(data.spector_senior));
  formData.append("spector_child", parseFloat(data.spector_child));
  formData.append("spector_childunder5", parseFloat(data.spector_childunder5));
  formData.append("spector_parking", data.spector_parking);
  formData.append("map_url", data.map_url);
  formData.append("address", data.address);
  formData.append("accommodation_title", data.accommodation.accommodation_title);
  formData.append("accommodation_content", data.accommodation.accommodation_content);
  formData.append("accommodation_date", data.accommodation.accommodation_date);
  if (data.image) {
    formData.append("image", data.image);
  }
  if (data.dlrs_pdf) {
    formData.append("dlrs_pdf", data.dlrs_pdf);
  }
  if (data.schedule_pdf) {
    formData.append("schedule_pdf", data.schedule_pdf);
  }
  if (data.result_pdf) {
    formData.append("result_pdf", data.result_pdf);
  }
  if (data.covid_pdf) {
    formData.append("covid_pdf", data.covid_pdf);
  }
  if (data.invite_pdf) {
    formData.append("invite_pdf", data.invite_pdf);
  }

  return function addEventThunk(dispatch) {
    dispatch(addEventLoading());
    dispatch(setAddEventButtonDisabled({ addEventButtonDisabled: true }));
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}event/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          performanceTextAreaCount = '';
          dispatch(
            addEventSuccess({
              response: responseJson.message,
              addEventButtonDisabled: false
            })
          );
        } else {
          console.log(responseJson);
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            addEventFailure({
              error: responseJson.message,
              errors: newErrors,
              addEventButtonDisabled: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(addEventFailure({ error: err }));
      });
  };
}

export function editEvent(data, eventId) {
  let formData = new FormData();
  formData.append("name", data.name);
  formData.append("type", data.type);
  formData.append("open_to", data.open_to);

  if (!data.decideLater.eventDate) {
    formData.append("event_date", data.event_date);
  }

  if (!data.decideLater.registerOnTime) {
    formData.append("register_by", data.register_by);
  }

  if (!data.decideLater.finalLatePaymentBy) {
    formData.append("final_payment_by", data.final_payment_by);
  }

  if (!data.decideLater.fullRefundBy) {
    formData.append("refund_full", data.refund_full);
  }

  if (!data.decideLater.halfRefundBy) {
    formData.append("refund_half", data.refund_half);
  }

  if (!data.decideLater.noRefund) {
    formData.append("refund_not", data.refund_not);
  }
  if (!data.decideLater.accommodationDate) {
    formData.append("accommodation_date", data.accommodation.accommodation_date);
  }
console.log(data);
  formData.append("place", data.place);
  formData.append("rules_question", data.rules_question);
  formData.append("qualifier", data.qualifier);
  formData.append("performance_surface", data.performance_surface);
  formData.append("preliminary_lineup", data.preliminary_lineup);
  formData.append("short_description", data.short_description);
  formData.append("draft_schedule", data.draft_schedule);
  formData.append("final_schedule", data.final_schedule);
  formData.append("award_giveaway", data.award_giveaway);
  formData.append("youth_ontime", parseFloat(data.youth_ontime));
  formData.append("youth_late", parseFloat(data.youth_late));
  formData.append("youth_crosscompetitor", parseFloat(data.youth_crosscompetitor));
  formData.append("youth_2ndperformance", parseFloat(data.youth_2ndperformance));
  formData.append("youth_note", data.youth_note);
  formData.append("spector_general", parseFloat(data.spector_general));
  formData.append("spector_senior", parseFloat(data.spector_senior));
  formData.append("spector_child", parseFloat(data.spector_child));
  formData.append("spector_childunder5", parseFloat(data.spector_childunder5));
  formData.append("spector_parking", data.spector_parking);
  formData.append("accommodation_title", data.accommodation.accommodation_title);
  formData.append("accommodation_content", data.accommodation.accommodation_content);
  //formData.append("accommodation_date", data.accommodation.accommodation_date);
  formData.append("map_url", data.map_url);
  formData.append("address", data.address);

  formData.append("dlrs_hidden", "file.pdf");
  formData.append("schedule_hidden", "file.pdf");
  formData.append("result_hidden", "file.pdf");
  formData.append("covid_hidden", "file.pdf");
  formData.append("invite_hidden", "file.pdf");

  if (data.image) {
    formData.append("image", data.image);
  }
  if (data.dlrs_pdf) {
    formData.append("dlrs_pdf", data.dlrs_pdf);
  }
  if (data.schedule_pdf) {
    formData.append("schedule_pdf", data.schedule_pdf);
  }
  if (data.result_pdf) {
    formData.append("result_pdf", data.result_pdf);
  }
  if (data.covid_pdf) {
    formData.append("covid_pdf", data.covid_pdf);
  }
  if (data.invite_pdf) {
    formData.append("invite_pdf", data.invite_pdf);
  }

  formData.append("_method", "PUT");
  console.log('sda sdas d', formData);
  return function editEventThunk(dispatch) {
    dispatch(editEventLoading());
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}event/update/${eventId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(editEventSuccess({ response: responseJson.message }));
        } else {
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            editEventFailure({ error: responseJson.message, errors: newErrors })
          );
        }
      })
      .catch((err) => {
        dispatch(addEventFailure({ error: err }));
      });
  };
}

export function getEvent(id) {
  return function getEventByIdThunk(dispatch) {
    dispatch(getEventDataById());
    const token = window.localStorage.getItem("token");
    if (token) {
      fetch(`${REACT_APP_API_URL}event/edit/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            dispatch(getEventDataByIdSuccess({ event: responseJson }));
          } else {
            dispatch(getEventDataByIdFailure({ error: "API Error" }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getEventRecordsFailure({ error: err }));
        });
    }
  };
}
