import {
    Alert,
    Button, Card, Checkbox, CssBaseline, FormControl, FormControlLabel, Grid, Input, InputAdornment,
    InputLabel, OutlinedInput, Paper, Stack, TextField, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Main } from '../../main/main';
import Sidebar from '../../sidebar/sidebar';
import Topbar from '../../topbar/topbar';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { editConference, getConference } from '../conferences.function';
import Editor from '../../../shared/editor/editor';
import locationImg from '../../../assets/location-image.gif';
import {
    clearFeeNote,
    editConferenceAddress,
    editConferenceDate,
    editConferenceDateDecideLater,
    editConferenceImage,
    editConferenceName,
    editConferencePlace,
    editCovidGuidelinesPdf,
    editDeadlineDate,
    editDeadlineDateDecideLater,
    editFeeNote,
    editFeeOneToNine,
    editFeeTenToNineteen,
    editFeeTwentyPlus,
    editFullRefundDate,
    editFullRefundDateDecideLater,
    editHalfRefundDate,
    editHalfRefundDateDecideLater,
    editMapCode,
    editNoRefundAfterDate,
    editNoRefundAfterDecideLater,
    editSchedulePdf,
    editAccommodationTitle,
    editAccommodationContent,
    editAccommodationDate,
    editAccommodationDateDecideLater,
} from './editconferenceSlice';

export function EditConference() {

    let navigate = useNavigate();
    let dispatch = useDispatch();
    const params = useParams();
    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const [conferenceId, saveConferenceId] = useState(0);
    const [conferencePicture, savePicture] = useState(null);
    const [schedulePdf, saveSchedulePdf] = useState(null);
    const [covidPdf, saveCovidPdf] = useState(null);
    const conference = useSelector((state) => state?.editConference?.conference);
    const decideLater = useSelector((state) => state?.editConference?.decideLater);
    const message = useSelector((state) => state?.editConference?.message);
    const severity = useSelector((state) => state?.editConference?.severity);
    const errors = useSelector((state) => state?.editConference?.errors);
    const editConferenceButtonDisabled = useSelector((state) => state?.editConference?.editConferenceButtonDisabled);
    const accommodationDateDecideLaterValue = useSelector((state) => state.editConference.decideLater.accommodation_date);
    const accommodation_title = useSelector(state => state.editConference.conference.accomodation?.accommodation_title);
    const accommodation_content = useSelector(state => state.editConference.conference.accomodation?.accommodation_content);
    const accommodation_date = useSelector(state => state.editConference.conference.accomodation?.accommodation_date);

    // PDF Urls
    const schedulePdfUrl = useSelector(state => state.editConference.conference.schedulePdfUrl);
    const covidPdfUrl = useSelector(state => state.editConference.conference.covidPdfUrl);

    useEffect(() => {
        if (params) {
            const conferenceId = params.id;
            saveConferenceId(conferenceId);
            dispatch(getConference(conferenceId));
        }
    }, [dispatch, params]);

    const handleClickBack = () => {
        navigate("/conferences");
        dispatch(clearFeeNote(conference.feeNote))
    };

    const conferenceNameHandleChange = (event) => {
        dispatch(editConferenceName({ conferenceName: event.target.value }));
    }

    const conferencePlaceHandleChange = (event) => {
        dispatch(editConferencePlace({ conferencePlace: event.target.value }));
    }

    const conferenceDateHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editConferenceDate({ conferenceDate: dateValue }));
    };

    const conferenceDateDecideLaterHandleChange = (event) => {
        dispatch(editConferenceDateDecideLater({ decideLater: event.target.checked }));
    }

    const deadlineDateHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editDeadlineDate({ deadlineDate: dateValue }));
    };

    const deadlineDateDecideLaterHandleChange = (event) => {
        dispatch(editDeadlineDateDecideLater({ decideLater: event.target.checked }));
    }

    const refundFullByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editFullRefundDate({ refundFull: dateValue }));
    };

    const refundFullByDecideLaterHandleChange = (event) => {
        dispatch(editFullRefundDateDecideLater({ decideLater: event.target.checked }));
    }

    const refundHalfByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editHalfRefundDate({ refundHalf: dateValue }));
    };

    const refundHalfByDecideLaterHandleChange = (event) => {
        dispatch(editHalfRefundDateDecideLater({ decideLater: event.target.checked }));
    }

    const noRefundAfterHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editNoRefundAfterDate({ noRefund: dateValue }));
    };

    const noRefundAfterDecideLaterHandleChange = (event) => {
        dispatch(editNoRefundAfterDecideLater({ decideLater: event.target.checked }));
    }

    const feeOneToNineHandleChange = (event) => {
        dispatch(editFeeOneToNine({ feeOneToNine: event.target.value }));
    }

    const feeTenToNineteenHandleChange = (event) => {
        dispatch(editFeeTenToNineteen({ feeTenToNineteen: event.target.value }));
    }

    const feeTwentyPlusHandleChange = (event) => {
        dispatch(editFeeTwentyPlus({ feeTwentyPlus: event.target.value }));
    }

    const addressHandleChange = (event) => {
        dispatch(editConferenceAddress({ conferenceAddress: event.target.value }));
    }

    const mapCodeHandleChange = (event) => {
        dispatch(editMapCode({ mapCode: event.target.value }));
    }

    const feeNoteHandleChange = (event) => {
        dispatch(editFeeNote({ feeNote: event.target.value }));
    }

    const accommmodationDateDecideLaterHandleChange = (event) => {
        dispatch(editAccommodationDateDecideLater({ decideLater: event.target.checked }));
    }

    const accommodationTitleChange = (event) => {
        dispatch(editAccommodationTitle({ title: event.target.value }));
    }

    const accommodationContentChange = (data) => {
        dispatch(editAccommodationContent({ content: data }));
    }

    const accommodationDateChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(editAccommodationDate({ date: dateValue }));
    }


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const conferenceImageHandleChange = (event) => {
        savePicture(event.target.files[0]);
        getBase64(event.target.files[0])
            .then(base64 => {
                const file = base64;
                dispatch(editConferenceImage({
                    conferenceImage: file
                }));
            });
    }

    function convertDateTimeFormat(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const schedulePdfHandleChange = (event) => {
        saveSchedulePdf(event.target.files[0]);
        dispatch(editSchedulePdf({
            schedulePdfName: event.target.files[0].name
        }));
    }

    const covidGuidelinesPdfHandleChange = (event) => {
        saveCovidPdf(event.target.files[0]);
        dispatch(editCovidGuidelinesPdf({
            covidGuidelinesPdfName: event.target.files[0].name
        }));
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        const editConferenceData = {
            'name': conference?.conferenceName,
            'place': conference?.conferencePlace,
            'type': conference?.conferenceType,
            'accommodation_id': conference?.accommodationId,
            'conference_date': conference?.conferenceDate,
            'deadline_date': conference?.deadlineDate,
            'refund_full': conference?.refundFull,
            'refund_half': conference?.refundHalf,
            'refund_not': conference?.noRefund,
            'fee_one_to_ten': conference?.feeOneToNine?conference?.feeOneToNine:0,
            'fee_ten_to_nineteen': conference?.feeTenToNineteen?conference?.feeTenToNineteen:0,
            'fee_twenty_plus': conference?.feeTwentyPlus?conference?.feeTwentyPlus:0,
            'address': conference?.conferenceAddress,
            'map_code': conference?.mapCode,
            'fee_note': conference?.feeNote,
            'image': conferencePicture,
            'schedule_pdf': schedulePdf,
            'covid_pdf': covidPdf,
            'accommodation': {
              'accommodation_title': accommodation_title,
              'accommodation_content': accommodation_content,
              'accommodation_date': accommodation_date,
            },
            'decideLater': {
                'conferenceDate': decideLater?.conferenceDate,
                'deadlineDate': decideLater?.deadlineDate,
                'fullRefundDate': decideLater?.refundFull,
                'halfRefundDate': decideLater?.refundHalf,
                'noRefundAfterDate': decideLater?.noRefund,
                'accommodation_date': decideLater?.accommodation_date
            }
        };
        dispatch(editConference(editConferenceData, conferenceId));
    }


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Sidebar />
                <Main open={sidebarState} className='dashbaordWrapper'>
                    <Topbar />
                    <Paper elevation={0} sx={{ background: '#e3f2fd', padding: '1.5rem', minHeight: 'calc(100% - 64px)' }}>
                        <Card elevation={1} sx={{ padding: '2rem' }} className={`conference_${conferenceId}`}>
                            <div
                                style={{
                                    alignItems: "center",
                                    alignContent: "center",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    marginBottom: "1.5rem",
                                }}
                            >
                                <Typography variant="h3">Edit Conference</Typography>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    startIcon={<ArrowBackIcon />}
                                    onClick={handleClickBack}
                                >
                                    Back
                                </Button>
                            </div>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={4}>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="name" label="Name"
                                            onChange={conferenceNameHandleChange}
                                            value={conference?.conferenceName} variant="outlined" />
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="place" value={conference?.conferencePlace}
                                            onChange={conferencePlaceHandleChange}
                                            label="Place" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Conference Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.conferenceDate}
                                                    onChange={conferenceDateHandleChange}
                                                    disablePast
                                                    disabled={decideLater?.conferenceDate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={conferenceDateDecideLaterHandleChange}
                                            checked={decideLater?.conferenceDate}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Deadline Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.deadlineDate}
                                                    onChange={deadlineDateHandleChange}
                                                    disablePast
                                                    disabled={decideLater?.deadlineDate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={deadlineDateDecideLaterHandleChange}
                                            checked={decideLater?.deadlineDate}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="100% Refund by"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.refundFull}
                                                    onChange={refundFullByHandleChange}
                                                    disablePast
                                                    disabled={decideLater?.refundFull}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={refundFullByDecideLaterHandleChange}
                                            checked={decideLater?.refundFull}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="50% Refund by"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.refundHalf}
                                                    onChange={refundHalfByHandleChange}
                                                    disablePast
                                                    disabled={decideLater?.refundHalf}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={refundHalfByDecideLaterHandleChange}
                                            checked={decideLater?.refundHalf}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="No Refund after"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={conference?.noRefund}
                                                    onChange={noRefundAfterHandleChange}
                                                    disablePast
                                                    disabled={decideLater?.noRefund}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={noRefundAfterDecideLaterHandleChange}
                                            checked={decideLater?.noRefund}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_one_to_nine">Fee One to Nine</InputLabel>
                                            <OutlinedInput
                                                id="fee_one_to_nine"
                                                value={conference?.feeOneToNine}
                                                onChange={feeOneToNineHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee One to Nine"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_ten_to_nineteen">Fee Ten to Nineteen</InputLabel>
                                            <OutlinedInput
                                                id="fee_ten_to_nineteen"
                                                value={conference?.feeTenToNineteen}
                                                onChange={feeTenToNineteenHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee One to Nine"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fee_twenty_plus">Fee Twenty Plus</InputLabel>
                                            <OutlinedInput
                                                id="fee_twenty_plus"
                                                value={conference?.feeTwentyPlus}
                                                onChange={feeTwentyPlusHandleChange}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Fee Twenty Plus"
                                            />
                                        </FormControl>
                                    </Grid>



                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Grid container spacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField fullWidth multiline rows={2} id="address"
                                                value={conference?.conferenceAddress}
                                                onChange={addressHandleChange}
                                                label="Address" variant="outlined" />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField fullWidth multiline rows={7} id="map_code"
                                                    value={conference?.mapCode}
                                                    onChange={mapCodeHandleChange}
                                                    label="Map Embed Code" variant="outlined" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <div className="locationImage">
                                            <Typography variant="h6">How to get embed code?</Typography>
                                            <img src={locationImg} alt="" />
                                        </div>
                                    </Grid>


                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth multiline rows={2} id="fee_note"
                                            value={conference?.feeNote}
                                            onChange={feeNoteHandleChange}
                                            label="Fee Note" variant="outlined" />
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <TextField fullWidth id="accommodation_title"
                                            value={accommodation_title}
                                            onChange={accommodationTitleChange}
                                            label="Accommodation Title" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    disablePast={true}
                                                    label="Accommodation Date*"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={accommodation_date}
                                                    disabled={accommodationDateDecideLaterValue}
                                                    onChange={accommodationDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                        <FormControlLabel control={<Checkbox
                                            onChange={accommmodationDateDecideLaterHandleChange}
                                            checked={accommodationDateDecideLaterValue}
                                        />} label="Decide later" />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Editor
                                            data={accommodation_content}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                accommodationContentChange(data);
                                            }} />
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <div className='inputFileWrapper'>
                                            <label htmlFor="schedule_pdf" className="file-input pictureInput">
                                                <Button color='secondary' variant="outlined" component="span"
                                                    className='fileInputButton'>
                                                    Select Schedule pdf</Button>
                                                <Input id="schedule_pdf" accept='pdf/*' inputProps={{ accept: "application/pdf" }}
                                                    onChange={schedulePdfHandleChange}
                                                    type="file" />
                                            </label>
                                            <div className='fileName addEvent'><span><PictureAsPdfIcon /></span>
                                                {conference?.schedulePdfName !== '' ? conference?.schedulePdfName
                                                    : 'File name will show here'}</div>
                                            <a className="editEventPdfDownloadLink" href={schedulePdfUrl} rel="noreferrer" target="_blank">Download</a>
                                            {/* <label className="recomemdedText">Recommended Size : 2mb</label> */}
                                        </div>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12}>
                                        <div className='inputFileWrapper'>
                                            <label htmlFor="covid_pdf" className="file-input pictureInput">
                                                <Button color='secondary' variant="outlined" component="span"
                                                    className='fileInputButton'>
                                                    Select Covid pdf</Button>
                                                <Input id="covid_pdf" accept='pdf/*' inputProps={{ accept: "application/pdf" }}
                                                    onChange={covidGuidelinesPdfHandleChange}
                                                    type="file" />
                                            </label>
                                            <div className='fileName addEvent'><span><PictureAsPdfIcon /></span>
                                                {conference?.covidGuidelinesPdfName !== '' ? conference?.covidGuidelinesPdfName
                                                    : 'File name will show here'}
                                            </div>
                                            <a className="editEventPdfDownloadLink" href={covidPdfUrl} rel="noreferrer" target="_blank">Download</a>
                                            {/* <label className="recomemdedText">Recommended Size : 2mb</label> */}
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <label htmlFor="image" className="file-input pictureInput">
                                            <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>
                                                Select Image</Button>
                                            <Input id="image"
                                                onChange={conferenceImageHandleChange}
                                                accept='image/*' inputProps={{ accept: "image/*" }} type="file" />
                                            {conference?.conferenceImage && (
                                                <div className='fileName'>
                                                    <img src={conference?.conferenceImage} alt="" />
                                                </div>
                                            )}
                                            <label className="recomemdedText">Recommended Size : 472px X 282px</label>
                                        </label>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Button onClick={handleSubmit} style={{ marginBottom: '1rem' }}
                                            variant='contained' disabled={editConferenceButtonDisabled} color="primary">Submit</Button>
                                        {!!message && (
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Alert severity={severity}>{message}</Alert>
                                            </Grid>
                                        )}
                                        {!!errors && errors.map((error) => (
                                            <Grid item key={error} lg={12} md={12} sm={12} xs={12}>
                                                <Alert severity={severity}>{error}</Alert>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Paper>
                </Main>
            </Box>
        </>
    )
}

export default EditConference;
