import {
    Alert, Box, Button, Card, Container,
    CssBaseline, FormControl, FormHelperText, Grid, IconButton,
    InputAdornment, InputLabel, OutlinedInput, Paper
} from '@mui/material'
import React, { useState } from 'react'
import Sidebar from '../sidebar/sidebar'
import Topbar from '../topbar/topbar'
import { useSelector, useDispatch } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { saveChangePasswordError, saveChangePasswordSuccess } from './changePasswordSlice';
import { useNavigate } from 'react-router-dom';
import { removeLoginToken } from '../login/loginSlice';
import { Main } from '../main/main';

export function Changepassword() {

    const { REACT_APP_API_URL } = process.env;
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const changePasswordMessage = useSelector(state => state.changePassword.message)
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [oldPasswordHelperText, setOldPasswordHelperText] = useState('')
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [newPasswordHelperText, setnewPasswordHelperText] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('')
    const [buttonDisabled, SetButtonDisabled] = useState(true)
    const severity = useSelector(state => state.changePassword.severity)


    const validateOldPassword = (e) => {
        const oldPassword = e.target.value
        if (oldPassword !== '') {
            setOldPasswordError(false)
            setOldPasswordHelperText('')
            SetButtonDisabled(false)
        } else {
            setOldPasswordError(true)
            setOldPasswordHelperText('Enter Old Password.')
            SetButtonDisabled(true)
        }
    }
    const validateNewPassword = (e) => {
        const newPassword = e.target.value
        if (newPassword !== '') {
            setNewPasswordError(false)
            setnewPasswordHelperText('')
            SetButtonDisabled(false)
        } else {
            setNewPasswordError(true)
            setnewPasswordHelperText('Enter New Password.')
            SetButtonDisabled(true)
        }
    }
    const validateConfirmPassword = (e) => {
        const confirmPassword = e.target.value
        if (confirmPassword !== '') {
            setConfirmPasswordError(false)
            setConfirmPasswordHelperText('')
            SetButtonDisabled(false)
        } else {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('Enter Confirm Password.')
            SetButtonDisabled(true)
        }
    }

    const handleSubmit = (e) => {
        SetButtonDisabled(true)
        e.preventDefault();
        const oldPassword = e.nativeEvent.target[0].value;
        const newPassword = e.nativeEvent.target[3].value;
        const confirmPassword = e.nativeEvent.target[6].value;

        let checkError = false;
        if (oldPassword !== '') {
            setOldPasswordError(false)
            setOldPasswordHelperText('')
            checkError = true;
        } else {
            setOldPasswordError(true)
            setOldPasswordHelperText('Enter Old Password.')
        }
        if (newPassword !== '') {
            setNewPasswordError(false)
            setnewPasswordHelperText('')
            checkError = true;
        } else {
            setNewPasswordError(true)
            setnewPasswordHelperText('Enter New Password.')
        }
        if (confirmPassword !== '') {
            setConfirmPasswordError(false)
            setConfirmPasswordHelperText('')
            checkError = true;
        } else {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('Enter Confirm Password.')
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('New password and confirm password do not match.')
        }
        if (checkError) {
            sendChangePasswordRequest(newPassword, confirmPassword, oldPassword);
        }

    }

    const [values, setValues] = React.useState({
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false
    });

    const sidebarState = useSelector(state => state.topbar.drawerToggle);

    const handleClickShowOldPassword = () => {
        setValues({
            ...values,
            showOldPassword: !values.showOldPassword
        });
    };

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const logOut = () => {
        fetch(`${REACT_APP_API_URL}logout?token=${window.localStorage.getItem('token')}`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    console.log(responseJson.message);
                    window.localStorage.removeItem('token');
                    dispatch(removeLoginToken());
                    navigate("/")
                } else {
                    navigate("/")
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    const sendChangePasswordRequest = (newPassword, confirmPassword, oldPassword) => {
        fetch(`${REACT_APP_API_URL}password/change`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                "token": window.localStorage.getItem('token'),
                "password": newPassword,
                "password_confirmation": confirmPassword,
                "current_password": oldPassword
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(saveChangePasswordSuccess(responseJson.message))
                    setTimeout(function () {
                        logOut();
                    }, 3000);
                } else {
                    const getErrors = Object.values(responseJson.errors);
                    getErrors.map((item, key) => {
                        dispatch(saveChangePasswordError(Object.values(item)[0]))
                        return true;
                    });

                }
                SetButtonDisabled(false)

            })
            .catch(err => {
                console.log(err);
            });
    }
    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Sidebar />
                <Main open={sidebarState} className='dashbaordWrapper'>
                    <Topbar />
                    <Paper elevation={0} sx={{ background: '#e3f2fd', height: '100vh' }}>
                        <Container maxWidth="lg" sx={{ height: '100%' }}>
                            <Grid container sx={{ height: '100%' }} justifyContent="center" alignItems="center">
                                <Grid item lg={3} md={3} sm={1} xs={12}>
                                </Grid>
                                <Grid item lg={6} md={6} sm={10} xs={12}>
                                    <Card sx={{ padding: '1.5rem 4rem 4rem' }}>
                                        <form onSubmit={(e) => handleSubmit(e)}>
                                            <h2>Change Password</h2>
                                            <FormControl fullWidth margin="normal" variant="outlined">
                                                <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                                                <OutlinedInput
                                                    id="oldPassword"
                                                    type={values.showOldPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={(e) => validateOldPassword(e)}
                                                    error={oldPasswordError}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowOldPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                                {!!oldPasswordError && (
                                                    <FormHelperText error id="password-error">
                                                        {oldPasswordHelperText}
                                                    </FormHelperText>)}
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" variant="outlined">
                                                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                                <OutlinedInput
                                                    id="newPassword"
                                                    type={values.showNewPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={(e) => validateNewPassword(e)}
                                                    error={newPasswordError}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowNewPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                                {!!newPasswordError && (
                                                    <FormHelperText error id="password-error">
                                                        {newPasswordHelperText}
                                                    </FormHelperText>)}
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" variant="outlined">
                                                <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                                                <OutlinedInput
                                                    id="confirmPassword"
                                                    type={values.showConfirmPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={(e) => validateConfirmPassword(e)}
                                                    error={confirmPasswordError}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                                {!!confirmPasswordError && (
                                                    <FormHelperText error id="password-error">
                                                        {confirmPasswordHelperText}
                                                    </FormHelperText>)}
                                            </FormControl>
                                            <Button disabled={buttonDisabled} type="submit" sx={{ margin: '1rem 0' }} variant="contained" fullWidth size="large">Change Password</Button>
                                        </form>
                                        {!!changePasswordMessage && (
                                            <Alert severity={severity}>{changePasswordMessage}</Alert>
                                        )}
                                    </Card>
                                </Grid>
                                <Grid item lg={3} md={3} sm={1} xs={12}>
                                </Grid>
                            </Grid>
                        </Container>
                    </Paper>
                </Main>
            </Box>
        </div>
    )
}

export default Changepassword