import { createSlice } from '@reduxjs/toolkit'

export const addConferenceSlice = createSlice({
    name: 'addConference',
    initialState: {
        conference: {
            conferenceName: '',
            accommodationId: null,
            conferenceType: 1,
            conferenceDate: convertDateTimeFormat(new Date()).toString(),
            deadlineDate: convertDateTimeFormat(new Date()).toString(),
            conferencePlace: '',
            conferenceAddress: '',
            conferenceImage: null,
            mapCode: '',
            schedulePdfName: '',
            covidGuidelinesPdfName: '',
            feeOneToNine: 0,
            feeTenToNineteen: 0,
            feeTwentyPlus: 0,
            feeNote: '',
            refundFull: convertDateTimeFormat(new Date()).toString(),
            refundHalf: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            accomodation: {
                accomodationId: 0,
                accommodation_title: '',
                accommodation_content: '',
                accommodation_date: convertDateTimeFormat(new Date()).toString(),
            }
        },
        errors: [],
        severity: 'error',
        message: null,
        addConferenceButtonDisabled: false,
        loading: false,
        decideLater: {
            conferenceDate: false,
            deadlineDate: false,
            refundFull: false,
            refundHalf: false,
            noRefund: false,
            accommodation_date: false
        },
    },
    reducers: {
        setAddConferenceButtonDisabled: (state, action) => {
            return {
                ...state,
                addConferenceButtonDisabled: action.payload.addConferenceButtonDisabled
            }
        },
        setConferenceName: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceName: action.payload.conferenceName
                }
            }
        },
        setConferencePlace: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferencePlace: action.payload.conferencePlace
                }
            }
        },
        setConferenceDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceDate: action.payload.conferenceDate,
                }
            }
        },
        conferenceDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    conferenceDate: action.payload.decideLater
                }
            }
        },
        setDeadlineDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    deadlineDate: action.payload.deadlineDate,
                }
            }
        },
        deadlineDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    deadlineDate: action.payload.decideLater
                }
            }
        },
        setFullRefundDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    refundFull: action.payload.refundFull,
                }
            }
        },
        fullRefundDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    refundFull: action.payload.decideLater
                }
            }
        },
        setHalfRefundDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    refundHalf: action.payload.refundHalf,
                }
            }
        },
        halfRefundDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    refundHalf: action.payload.decideLater
                }
            }
        },
        setNoRefundAfterDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    noRefund: action.payload.noRefund,
                }
            }
        },
        noRefundAfterDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    noRefund: action.payload.decideLater
                }
            }
        },
        accommodationDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    accommodation_date: action.payload.decideLater
                }
            }
        },
        setFeeOneToNine: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeOneToNine: action.payload.feeOneToNine,
                }
            }
        },
        setFeeTenToNineteen: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeTenToNineteen: action.payload.feeTenToNineteen,
                }
            }

        },
        setFeeTwentyPlus: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeTwentyPlus: action.payload.feeTwentyPlus,
                }
            }
        },
        setMapCode: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    mapCode: action.payload.mapCode
                }
            }
        },
        setConferenceAddress: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceAddress: action.payload.conferenceAddress
                }
            }
        },
        setConferenceImage: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceImage: action.payload.conferenceImage
                }
            }
        },
        setFeeNote: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeNote: action.payload.feeNote
                }
            }
        },
        setSchedulePdf: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    schedulePdfName: action.payload.schedulePdfName
                }
            }
        },
        setCovidGuidelinesPdf: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    covidGuidelinesPdfName: action.payload.covidGuidelinesPdfName
                }
            }
        },
        setAccommodationTitle: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_title: action.payload.title
                    }
                }
            }
        },
        setAccommodationContent: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_content: action.payload.content
                    }
                }
            }
        },
        setAccommodationDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_date: action.payload.date
                    }
                }
            }
        },
        addConferenceLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                addConferenceButtonDisabled: true,
            }
        },
        addConferenceSuccess: (state, action) => {
            return {
                ...state,
                conference: {
                    conferenceName: '',
                    accommodationId: 0,
                    conferenceType: 1,
                    conferenceDate: convertDateTimeFormat(new Date()).toString(),
                    deadlineDate: convertDateTimeFormat(new Date()).toString(),
                    conferencePlace: '',
                    conferenceAddress: '',
                    conferenceImage: null,
                    mapCode: '',
                    schedulePdfName: '',
                    covidGuidelinesPdfName: '',
                    feeOneToNine: 0,
                    feeTenToNineteen: 0,
                    feeTwentyPlus: 0,
                    feeNote: '',
                    refundFull: convertDateTimeFormat(new Date()).toString(),
                    refundHalf: convertDateTimeFormat(new Date()).toString(),
                    noRefund: convertDateTimeFormat(new Date()).toString(),
                    accomodation: {
                        accommodation_title: '',
                        accommodation_content: '',
                        accommodation_date: '',
                    }
                },
                errors: [],
                message: action.payload.response,
                severity: 'success',
                loading: false,
                addConferenceButtonDisabled: action.payload.addConferenceButtonDisabled,
                decideLater: {
                    conferenceDate: false,
                    deadlineDate: false,
                    refundFull: false,
                    refundHalf: false,
                    noRefund: false,
                    accommodation_date: false
                },
            }
        },
        addConferenceFailure: (state, action) => {
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.error,
                severity: 'error',
                loading: false,
                addConferenceButtonDisabled: action.payload.addConferenceButtonDisabled
            }
        },
    },
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

// Action creators are generated for each case reducer function
export const {
    setConferenceName,
    setConferenceDate,
    setConferencePlace,
    setDeadlineDate,
    deadlineDateDecideLater,
    setFullRefundDate,
    setHalfRefundDate,
    setNoRefundAfterDate,
    fullRefundDateDecideLater,
    halfRefundDateDecideLater,
    noRefundAfterDecideLater,
    setFeeOneToNine,
    setFeeTenToNineteen,
    setFeeTwentyPlus,
    setFeeNote,
    setMapCode,
    setConferenceAddress,
    setConferenceImage,
    setSchedulePdf,
    setCovidGuidelinesPdf,
    addConferenceSuccess,
    addConferenceFailure,
    setAddConferenceButtonDisabled,
    addConferenceLoading,
    conferenceDateDecideLater,
    accommodationDateDecideLater,
    setAccommodationTitle,
    setAccommodationContent,
    setAccommodationDate
} = addConferenceSlice.actions

export default addConferenceSlice.reducer