import {
  editSlideFailure,
  editSlideSuccess,
  getSlideDataById,
  getSlideDataByIdFailure,
  getSlideDataByIdSuccess,
  editSlideLoading,
} from "./editslideSlice";

import { getBannerRecordsFailure } from "../homepagebanner/homepagebannerSlice";

const { REACT_APP_API_URL } = process.env;

export function editSlide(data, slideId) {
  let formData = new FormData();
  formData.append("heading", data.heading);
  formData.append("description", data.description);
  if (data.image) {
    formData.append("image", data.image);
  }

  if (data.display_order) {
    formData.append("display_order", parseInt(data.display_order));
  }

  formData.append("image_hidden", "file.pdf");

  formData.append("_method", "PUT");
  return function editSlideThunk(dispatch) {
    dispatch(editSlideLoading());
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}slider/update/${slideId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(editSlideSuccess({ response: responseJson.message }));
        } else {
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            editSlideFailure({ error: responseJson.message, errors: newErrors })
          );
        }
      })
      .catch((err) => {
        dispatch(editSlideFailure({ error: err }));
      });
  };
}
export function getSlide(id) {
  return function getEventByIdThunk(dispatch) {
    dispatch(getSlideDataById());
    const token = window.localStorage.getItem("token");
    if (token) {
      fetch(`${REACT_APP_API_URL}slider/edit/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            dispatch(getSlideDataByIdSuccess({ slide: responseJson.data }));
          } else {
            dispatch(getSlideDataByIdFailure({ error: "API Error" }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getBannerRecordsFailure({ error: err }));
        });
    }
  };
}
