import { createSlice } from "@reduxjs/toolkit";

export const CompetitionGuidelineSlice = createSlice({
  name: "editcompetitionguideline",
  initialState: {
    message: "",
    data: {
      id: 1,
      title: "",
      contentValue: "",
      registerPdfName: "",
      registerPdfUrl: "",
      policiePdfName: "",
      policiePdfUrl: "",
      youthcatdivisionPdfName: "",
      youthcheerrulescorePdfName: "",
      youthdancerulescorePdfName: "",
      nacatdivisionPdfName: "",
      nacheerrulescorePdfName: "",
      nadancerulescorePdfName: "",
      yacdaMembership: "",
      yacdaVideoLibrary: "",
      created_at: "",
      updated_at: "",
    },
    severity: "error",
    mounted: false,
    loading: false,
    errors: {
      title: { isValid: true, error: "" },
      contentValue: { isValid: true, error: "" },
      ycadamembership: { isValid: true, error: "" },
      ycadavideolibrary: { isValid: true, error: "" },
    },
  },
  reducers: {
    saveCompetitionGuidelineError: (state, action) => {
      return {
        ...state,
        message: action.payload.error,
        loading: false,
        severity: "error",
      };
    },
    saveCompetitionGuidelineSuccess: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        data: {
          title: action.payload.data.title,
          contentValue: !action.payload.data.content ? '' : action.payload.data.content,
          registerPdfName: action.payload.data.register_pdf,
          registerPdfUrl: action.payload.data.url_register_pdf,

          policiePdfName: action.payload.data.policie_pdf,
          policiePdfUrl: action.payload.data.url_policie_pdf,

          youthcatdivisionPdfName: action.payload.data.youth_cat_division_pdf,
          youthcatdivisionPdfUrl:
            action.payload.data.url_youth_cat_division_pdf,

          youthcheerrulescorePdfName:
            action.payload.data.youth_cheer_rule_score_pdf,
          youthcheerrulescorePdfUrl:
            action.payload.data.url_youth_cheer_rule_score_pdf,

          youthdancerulescorePdfName:
            action.payload.data.youth_dance_rule_score_pdf,
          youthdancerulescorePdfUrl:
            action.payload.data.url_youth_dance_rule_score_pdf,

          nacatdivisionPdfName: action.payload.data.na_cat_division_pdf,
          nacatdivisionPdfUrl: action.payload.data.url_na_cat_division_pdf,

          nacheerrulescorePdfName: action.payload.data.na_cheer_rule_score_pdf,
          nacheerrulescorePdfUrl:
            action.payload.data.url_na_cheer_rule_score_pdf,

          nadancerulescorePdfName: action.payload.data.na_dance_rule_score_pdf,
          nadancerulescorePdfUrl:
            action.payload.data.url_na_dance_rule_score_pdf,

          yacdaMembership: action.payload.data.yacda_membership,

          yacdaVideoLibrary: action.payload.data.yacda_video_library,
        },
        severity: "success",
        loading: false,
      };
    },
    setCompetitionGuidelines: (state, action) => {
      return {
        ...state,
        data: {
          title: action.payload.title,
          contentValue: !action.payload.content ? '' : action.payload.content,
          registerPdfName: action.payload.register_pdf,
          registerPdfUrl: action.payload.url_register_pdf,

          policiePdfName: action.payload.policie_pdf,
          policiePdfUrl: action.payload.url_register_pdf,

          youthcatdivisionPdfName: action.payload.youth_cat_division_pdf,
          youthcatdivisionPdfUrl: action.payload.url_youth_cat_division_pdf,

          youthcheerrulescorePdfName: action.payload.youth_cheer_rule_score_pdf,
          youthcheerrulescorePdfUrl:
            action.payload.url_youth_cheer_rule_score_pdf,

          youthdancerulescorePdfName: action.payload.youth_dance_rule_score_pdf,
          youthdancerulescorePdfUrl:
            action.payload.url_youth_dance_rule_score_pdf,

          nacatdivisionPdfName: action.payload.na_cat_division_pdf,
          nacatdivisionPdfUrl: action.payload.url_na_cat_division_pdf,

          nacheerrulescorePdfName: action.payload.na_cheer_rule_score_pdf,
          nacheerrulescorePdfUrl: action.payload.url_na_cheer_rule_score_pdf,

          nadancerulescorePdfName: action.payload.na_dance_rule_score_pdf,
          nadancerulescorePdfUrl: action.payload.url_na_dance_rule_score_pdf,

          yacdaMembership: action.payload.yacda_membership,
          yacdaVideoLibrary: action.payload.yacda_video_library,
        },
        mounted: true,
        loading: false,
      };
    },
    setTitle: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          title: action.payload.title,
        },
      };
    },
    setContent: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          contentValue: action.payload.contentValue,
        },
      };
    },
    setRegisterPdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          registerPdfName: action.payload.registerPdfName,
        },
      };
    },
    setPoliciePdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          policiePdfName: action.payload.policiePdfName,
        },
      };
    },
    setYouthCatDivisionPdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          youthcatdivisionPdfName: action.payload.youthcatdivisionPdfName,
        },
      };
    },
    setYouthCheerRuleScorePdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          youthcheerrulescorePdfName: action.payload.youthcheerrulescorePdfName,
        },
      };
    },
    setYouthDanceRuleScorePdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          youthdancerulescorePdfName: action.payload.youthdancerulescorePdfName,
        },
      };
    },
    setNaCatDivisionPdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          nacatdivisionPdfName: action.payload.nacatdivisionPdfName,
        },
      };
    },
    setNaCheerRuleScorePdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          nacheerrulescorePdfName: action.payload.nacheerrulescorePdfName,
        },
      };
    },
    setNaDanceRuleScorePdf: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          nadancerulescorePdfName: action.payload.nadancerulescorePdfName,
        },
      };
    },
    setYacdaMembership: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          yacdaMembership: action.payload.yacdaMembership,
        },
      };
    },
    setYacdaVideoLibrary: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          yacdaVideoLibrary: action.payload.yacdaVideoLibrary,
        },
      };
    },
    saveCompetitionGuideline: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getCompetitionGuideline: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    setErrors: (state, action) => {
      switch (action.payload.key) {
        case 'title':
          return {
            ...state,
            errors: {
              ...state.errors,
              title: action.payload.value
            },
          };

        case 'ycadamembership':
          return {
            ...state,
            errors: {
              ...state.errors,
              ycadamembership: action.payload.value
            },
          };

        case 'ycadavideolibrary':
          return {
            ...state,
            errors: {
              ...state.errors,
              ycadavideolibrary: action.payload.value
            },
          };
        default:
          return {
            ...state
          }
      };
    }
  },
});

export const {
  saveCompetitionGuidelineSuccess,
  saveCompetitionGuidelineError,
  setCompetitionGuidelines,
  saveCompetitionGuideline,
  getCompetitionGuideline,
  setTitle,
  setContent,
  setRegisterPdf,
  setPoliciePdf,
  setYouthCatDivisionPdf,
  setYouthCheerRuleScorePdf,
  setYouthDanceRuleScorePdf,
  setNaCatDivisionPdf,
  setNaCheerRuleScorePdf,
  setNaDanceRuleScorePdf,
  setYacdaMembership,
  setYacdaVideoLibrary,
  setErrors,
} = CompetitionGuidelineSlice.actions;

export default CompetitionGuidelineSlice.reducer;
