import { createSlice } from '@reduxjs/toolkit'

export const judgeRecordSlice = createSlice({
    name: 'judge',
    initialState: {
        judgerequests: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getJudgeRequestRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getJudgeRequestRecordsSuccess: (state, action) => {
            return {
                ...state,
                judgerequests: action.payload.judgerequests,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getJudgeRequestRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteJudgeRequestSuccess: (state, action) => {
            return {
                ...state,
                judgerequests: state.judgerequests.filter(teamrequest => teamrequest.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportJudgeRequestSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportJudgeRequestFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getJudgeRequestRecordsLoading, setRecordDeleted, deleteJudgeRequestSuccess, getJudgeRequestRecordsSuccess,
    getJudgeRequestRecordsFailure, setPage, setRowsPerPage, exportJudgeRequestSuccess, exportJudgeRequestFailure } = judgeRecordSlice.actions

export default judgeRecordSlice.reducer