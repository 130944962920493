import { createSlice } from '@reduxjs/toolkit'

export const changePasswordSlice = createSlice({
    name: 'changePassword',
    initialState: {
        message: '',
        severity: 'error'
    },
    reducers: {
        saveChangePasswordError: (state, action) => {
            return {
                ...state,
                message: action.payload,
                severity: 'error'
            }
        },
        saveChangePasswordSuccess: (state, action) => {
            return {
                ...state,
                message: action.payload,
                severity: 'success'
            }
        }
    }
})


export const { saveChangePasswordError, saveChangePasswordSuccess } = changePasswordSlice.actions

export default changePasswordSlice.reducer