import { createSlice } from '@reduxjs/toolkit'

export const contentpagebannerSlice = createSlice({
    name: 'bannerscontentpage',
    initialState: {
        banners: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getBannerRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getBannerRecordsSuccess: (state, action) => {
            return {
                ...state,
                banners: action.payload.banners,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getBannerRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteBannerSuccess: (state, action) => {
            return {
                ...state,
                banners: state.banners.filter(banner => banner.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportBannerSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportBannerFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getBannerRecordsLoading, setRecordDeleted, deleteBannerSuccess, getBannerRecordsSuccess,
    getBannerRecordsFailure, setPage, setRowsPerPage, exportBannerSuccess, exportBannerFailure } = contentpagebannerSlice.actions

export default contentpagebannerSlice.reducer