import { useTheme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import {
    useMediaQuery, Box, CssBaseline, IconButton, Paper, tableCellClasses, Button, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Snackbar, Backdrop, CircularProgress, Alert, Typography
} from '@mui/material';
import { } from '@mui/system';
import { Main } from '../main/main';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import Sidebar from '../sidebar/sidebar';
import Topbar from '../topbar/topbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom'
import { setPage, setRecordDeleted, setRowsPerPage } from './advertisementSlice';
import { getAdvertisementRecords, deleteAdvertisement } from './advertisement.function'
import { Delete, Edit } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close';

export function Advertisement() {

    let navigate = useNavigate();
    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 'bold',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '0.5rem 1rem',
            fontSize: 14,
        },
    }));
    const advertisements = useSelector(state => state?.advertisements?.advertisements);
    const loading = useSelector(state => state.advertisements.loading);
    const totalCount = useSelector(state => state.advertisements.totalCount);
    const rowsPerPage = useSelector(state => state.advertisements.rowsPerPage);
    const page = useSelector(state => state.advertisements.page);
    const recordDeleted = useSelector(state => state.advertisements.recordDeleted);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAdvertisementRecords(page, rowsPerPage));
    }, [dispatch, page, rowsPerPage]);


    const handleChangePage = (advertisement, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (advertisement) => {
        dispatch(setRowsPerPage(advertisement.target.value));
        dispatch(setPage(0));

    };

    const [open, setOpen] = useState(false);
    const [dialogDeleteId, setDialogDeleteId] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const confirmDialogOpen = (id) => {
        setOpen(true);
        setDialogDeleteId(id);
    };

    const confirmDialogClose = (dialogAction) => {
        if (dialogAction === 'Yes') {
            dispatch(deleteAdvertisement(dialogDeleteId, page, rowsPerPage));
        }
        setOpen(false);
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setRecordDeleted({
            recordDeleted: false
        }));
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                CLOSE
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main open={sidebarState} className='dashbaordWrapper'>
                <Topbar />
                <Paper sx={{ background: '#e3f2fd', padding: '2.5rem 1.5rem 1.5rem 2.5rem', minHeight: 'calc(100% - 64px)' }}>

                    <Button style={{ marginBottom: '1rem' }}
                        onClick={() => {
                            navigate('/advertisement/add', { replace: true });
                        }}
                        variant="contained">Add Advertisement</Button>

                    <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>
                        Advertisements
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Ad Image</StyledTableCell>
                                    <StyledTableCell align="left">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {advertisements.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <img src={row.image} alt='' className='bannerSlideThumbnail' />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Stack direction="row" spacing={1}>
                                                <IconButton color="primary" aria-label="edit"
                                                    sx={{ marginRight: '0.25rem' }}
                                                    onClick={() => {
                                                        navigate('/advertisement/edit/' + row.id, { replace: true });
                                                    }}
                                                >
                                                    <Edit color="primary" />
                                                </IconButton>
                                                <IconButton aria-label="delete"
                                                    onClick={() => {
                                                        confirmDialogOpen(row.id);
                                                    }}
                                                >
                                                    <Delete sx={{ color: '#d32f2f' }} />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!loading && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}

                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={() => { confirmDialogClose('No') }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" className='dialogHeadWithIcon'>
                            <DeleteIcon /> {"Delete Advertisement"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this advertisement?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div className='dailogBtnActWrapper'>
                                <Button autoFocus onClick={() => { confirmDialogClose('No') }} variant="outlined" color='primary'>No</Button>
                                <Button onClick={() => { confirmDialogClose('Yes') }} autoFocus variant="contained" color='primary'>Yes</Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={recordDeleted}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        action={action}
                        anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                        }}
                    >
                        <Alert severity="success" variant="filled">Advertisement deleted successfully</Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            </Main>
        </Box>
    );
}

export default Advertisement;
