import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import Topbar from "../../topbar/topbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../sidebar/sidebar";
import { Main } from "../../main/main";
import { useNavigate, useParams } from "react-router-dom";
import {
  editDepartmentName,
  editDepartmentEmail,
  setEditDepartmentButtonDisabled,
} from "./editdepartmentSlice";

import {
  editDepartment,
  getDepartment,
} from "./editdepartment.functions";
import validator from "validator";

export function EditDepartment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const departmentName = useSelector(
    (state) => state.editDepartment.editDepartment.departmentName
  );

  const emailID = useSelector(
    (state) => state.editDepartment.editDepartment.emailID
  );

  const editDepartmentButtonDisabled = useSelector(
    (state) => state.editDepartment.editDepartmentButtonDisabled
  );

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");

  ///////////Error and view handles ///////////////
  const loading = useSelector((state) => state.editDepartment.loading);
  const message = useSelector((state) => state.editDepartment.message);
  const severity = useSelector((state) => state.editDepartment.severity);
  const errors = useSelector((state) => state.editDepartment.errors);

  const validateName = (name) => {
    if (name) {
      setNameError(false);
      setNameHelperText("");
      dispatch(setEditDepartmentButtonDisabled({ editDepartmentButtonDisabled: false }));
    } else {
      setNameError(true);
      setNameHelperText("Enter valid Department Name.");
      dispatch(setEditDepartmentButtonDisabled({ editDepartmentButtonDisabled: true }));
    }
    dispatch(editDepartmentName({ departmentName: name }));
  };

  const validateEmail = (email) => {

    if (!email) {
      setEmailError(true);
      setEmailHelperText("Email is required.");
      dispatch(setEditDepartmentButtonDisabled({ editDepartmentButtonDisabled: true }));
    } else if (email && validator.isEmail(email)) {
      setEmailError(false);
      setEmailHelperText("");
      dispatch(setEditDepartmentButtonDisabled({ editDepartmentButtonDisabled: false }));
    } else {
      setEmailError(true);
      setEmailHelperText("Enter valid email id.");
      dispatch(setEditDepartmentButtonDisabled({ editDepartmentButtonDisabled: true }));
    }
    dispatch(editDepartmentEmail({ emailID: email }));
  };

  const [DepartmentId, saveDepartmentId] = useState(0);
  const params = useParams();

  useEffect(() => {
    if (params) {
      const DepartmentId = params.id;
      saveDepartmentId(DepartmentId);
      dispatch(getDepartment(DepartmentId));
    }
  }, [dispatch, params]);

  const handleClickBack = () => {
    navigate("/departments");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ediDepartmentData = {
      name: departmentName,
      email: emailID,
    };
    dispatch(editDepartment(ediDepartmentData, DepartmentId));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">Edit Department</Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2} item>
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingTop: "30px", marginLeft: "0" }}
                      >
                        <Grid item lg={7} md={7} sm={7} xs={12}>
                          <TextField
                            id="name"
                            value={departmentName}
                            onChange={(e) => validateName(e.target.value)}
                            label="Edit Department Name"
                            error={nameError}
                            helperText={nameHelperText}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>

                        <Grid item lg={7} md={7} sm={7} xs={12}>
                          <TextField
                            id="email"
                            value={emailID}
                            onChange={(e) => validateEmail(e.target.value)}
                            label="Edit Email ID"
                            error={emailError}
                            helperText={emailHelperText}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            disabled={editDepartmentButtonDisabled}
                            sx={{ margin: "1rem 0" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={handleClickBack}
                            sx={{ margin: "1rem" }}
                            variant="outlined"
                            disableElevation
                            size="large"
                          >
                            Cancel
                          </Button>

                          {!!message && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Alert severity={severity}>{message}</Alert>
                            </Grid>
                          )}
                          {!!errors &&
                            errors.map((error) => (
                              <Grid
                                item
                                key={error}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Alert severity={severity}>{error}</Alert>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box >
  );
}

export default EditDepartment;
