import {
    addConferenceFailure, addConferenceLoading,
    addConferenceSuccess,
} from "./addconferences/addconferenceSlice";
import {
    editConferenceFailure, editConferenceLoading,
    editConferenceSuccess, getConferenceDataById, getConferenceDataByIdFailure, getConferenceDataByIdSuccess
} from "./editconference/editconferenceSlice";
import {
    deleteConferenceSuccess,
    exportConferenceFailure,
    exportconferenceSuccess,
    getConferenceRecordsFailure,
    getConferenceRecordsLoading,
    getConferenceRecordsSuccess,
} from "./listconferences/listConferencesSlice";
const { REACT_APP_API_URL } = process.env;

export function getConferenceRecords(page, pageSize, sortBy = "Conference_date", order = "DESC", search = "") {
    const newPage = page + 1;
    return function getConferenceRecordsThunk(dispatch) {
        const token = window.localStorage.getItem("token");
        dispatch(getConferenceRecordsLoading());
        if (token) {
            fetch(
                `${REACT_APP_API_URL}conferences?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "content-type": "application/json",
                        accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === true && responseJson.data) {
                        dispatch(
                            getConferenceRecordsSuccess({
                                conferences: responseJson.data,
                                totalCount: responseJson.total_count,
                            })
                        );
                    } else {
                        console.log(responseJson);
                        dispatch(getConferenceRecordsFailure({ error: responseJson.info }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(getConferenceRecordsFailure({ error: err }));
                });
        }
    };
}

export function deleteConference(id, page, rowsPerPage) {
    return function deleteConferenceThunk(dispatch) {
        const token = window.localStorage.getItem("token");
        fetch(`${REACT_APP_API_URL}conference/delete/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
                accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === true) {
                    console.log(responseJson);
                    dispatch(getConferenceRecords(page, rowsPerPage));
                    dispatch(deleteConferenceSuccess({ id: id }));
                } else {
                    console.log(responseJson);
                    dispatch(getConferenceRecordsFailure({ error: responseJson.info }));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(getConferenceRecordsFailure({ error: err }));
            });
    };
}

export function exportConference() {
    return function exportConferenceThunk(dispatch) {
        const token = window.localStorage.getItem("token");
        fetch(`${REACT_APP_API_URL}export-conferences`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
                accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === true) {
                    dispatch(exportconferenceSuccess({ response: responseJson.data }));
                } else {
                    console.log(responseJson);
                    dispatch(exportConferenceFailure({ error: responseJson.error }));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(exportConferenceFailure({ error: err }));
            });
    };
}

export function addConference(data) {

    console.log(data);

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("type", data.type);

    if (!data.decideLater.conferenceDate) {
        formData.append("conference_date", data.conference_date);
    }

    if (!data.decideLater.deadlineDate) {
        formData.append("deadline_date", data.deadline_date);
    }

    if (!data.decideLater.fullRefundDate) {
        formData.append("refund_full", data.refund_full);
    }

    if (!data.decideLater.halfRefundDate) {
        formData.append("refund_half", data.refund_half);
    }

    if (!data.decideLater.noRefundAfterDate) {
        formData.append("refund_not", data.refund_not);
    }

    formData.append("place", data.place);
    formData.append("address", data.address);

    if (data.image) {
        formData.append("image", data.image);
    }

    formData.append("map_code", data.map_code);

    formData.append("fee_one_to_ten", data.fee_one_to_ten);
    formData.append("fee_ten_to_nineteen", data.fee_ten_to_nineteen);
    formData.append("fee_twenty_plus", data.fee_twenty_plus);
    formData.append("fee_note", data.fee_note);

    if (data.schedule_pdf) {
        formData.append("schedule_pdf", data.schedule_pdf);
    }

    if (data.covid_pdf) {
        formData.append("covid_pdf", data.covid_pdf);
    }

    formData.append("accommodation_title", data.accommodation.accommodation_title);
    formData.append("accommodation_content", data.accommodation.accommodation_content);
    //formData.append("accommodation_date", data.accommodation.accommodation_date);

    if (!data.decideLater.accommodation_date) {
        formData.append("accommodation_date", data.accommodation.accommodation_date);
    }
    console.log('data.decideLater', data.decideLater);

    console.log('formData', formData);

    return function addConferenceThunk(dispatch) {
        dispatch(addConferenceLoading());
        const token = window.localStorage.getItem("token");
        fetch(`${REACT_APP_API_URL}conference/create`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Disposition": "attachment",
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === true) {
                    console.log(responseJson);
                    dispatch(
                        addConferenceSuccess({
                            response: responseJson.message,
                            addConferenceButtonDisabled: false
                        })
                    );
                } else {
                    console.log(responseJson);
                    const errors = responseJson.errors;
                    const newErrors = [];
                    for (const [key, value] of Object.entries(errors)) {
                        console.log(`${key}`, `${value}`);
                        newErrors.push(`${value}`);
                    }
                    dispatch(
                        addConferenceFailure({
                            error: responseJson.message,
                            errors: newErrors,
                            addConferenceButtonDisabled: false,
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(addConferenceFailure({ error: err }));
            });
    };
}

export function editConference(data, ConferenceId) {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("type", data.type);
    formData.append("accommodation)id", data.accommodation_id);

    if (!data.decideLater.conferenceDate) {
        formData.append("conference_date", data.conference_date);
    }

    if (!data.decideLater.deadlineDate) {
        formData.append("deadline_date", data.deadline_date);
    }

    formData.append("place", data.place);
    formData.append("address", data.address);

    if (data.image) {
        formData.append("image", data.image);
    }

    formData.append("map_code", data.map_code);

    if (data.schedule_pdf) {
        formData.append("schedule_pdf", data.schedule_pdf);
    }
    if (data.covid_pdf) {
        formData.append("covid_pdf", data.covid_pdf);
    }

    formData.append("fee_one_to_ten", parseFloat(data.fee_one_to_ten));
    formData.append("fee_ten_to_nineteen", parseFloat(data.fee_ten_to_nineteen));
    formData.append("fee_twenty_plus", parseFloat(data.fee_twenty_plus));
    formData.append("fee_note", data.fee_note);

    if (!data.decideLater.fullRefundDate) {
        formData.append("refund_full", data.refund_full);
    }

    if (!data.decideLater.halfRefundDate) {
        formData.append("refund_half", data.refund_half);
    }

    if (!data.decideLater.noRefundAfterDate) {
        formData.append("refund_not", data.refund_not);
    }

    formData.append("schedule_hidden", "file.pdf");
    formData.append("covid_hidden", "file.pdf");

    formData.append("accommodation_title", data.accommodation.accommodation_title);
    formData.append("accommodation_content", data.accommodation.accommodation_content);
    //formData.append("accommodation_date", data.accommodation.accommodation_date);

    console.log('data.decideLater', data.decideLater);
    if (!data.decideLater.accommodation_date) {
        formData.append("accommodation_date", data.accommodation.accommodation_date);
    }
    console.log('formData', formData);

    formData.append("_method", "PUT");
    return function editConferenceThunk(dispatch) {
        dispatch(editConferenceLoading());
        const token = window.localStorage.getItem("token");
        fetch(`${REACT_APP_API_URL}conference/update/${ConferenceId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Disposition": "attachment",
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === true) {
                    dispatch(editConferenceSuccess({
                        response: responseJson.message,
                        editConferenceButtonDisabled: false
                    }));
                } else {
                    const errors = responseJson.errors;
                    const newErrors = [];
                    for (const [key, value] of Object.entries(errors)) {
                        console.log(`${key}`, `${value}`);
                        newErrors.push(`${value}`);
                    }
                    dispatch(
                        editConferenceFailure({
                            error: responseJson.message,
                            editConferenceButtonDisabled: false,
                            errors: newErrors
                        })
                    );
                }
            })
            .catch((err) => {
                dispatch(editConferenceFailure({ error: err }));
            });
    };
}

export function getConference(id) {
    return function getConferenceByIdThunk(dispatch) {
        dispatch(getConferenceDataById());
        const token = window.localStorage.getItem("token");
        if (token) {
            fetch(`${REACT_APP_API_URL}conference/edit/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "content-type": "application/json",
                    accept: "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson) {
                        dispatch(getConferenceDataByIdSuccess({ conference: responseJson }));
                    } else {
                        dispatch(getConferenceDataByIdFailure({ error: "API Error" }));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(getConferenceRecordsFailure({ error: err }));
                });
        }
    };
}
