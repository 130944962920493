import { createSlice } from "@reduxjs/toolkit";

export const PrivacyPolicySlice = createSlice({
  name: "editprivacypolicy",
  initialState: {
    message: "",
    data: {
      id: 4,
      title: "",
      contentValue: "",
    },
    severity: "error",
    mounted: false,
    loading: false,
    errors: [],
  },
  reducers: {
    savePrivacyPolicyError: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        loading: false,
        severity: "error",
      };
    },
    savePrivacyPolicySuccess: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        data: {
          title: action.payload.data.title,
          contentValue: !action.payload.data.content ? '' : action.payload.data.content,
        },
        severity: "success",
        loading: false,
        errors: [],
      };
    },
    setPrivacyPolicy: (state, action) => {
      return {
        ...state,
        data: {
          title: action.payload.title,
          contentValue: !action.payload.content ? '' : action.payload.content,
        },
        mounted: true,
        loading: false,
      };
    },
    setTitle: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          title: action.payload.title,
        },
      };
    },
    setContent: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          contentValue: action.payload.contentValue,
        },
      };
    },
    savePrivacyPolicy: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getPrivacyPolicy: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
});

export const {
  savePrivacyPolicySuccess,
  savePrivacyPolicyError,
  setPrivacyPolicy,
  savePrivacyPolicy,
  getPrivacyPolicy,
  setTitle,
  setContent,
} = PrivacyPolicySlice.actions;

export default PrivacyPolicySlice.reducer;
