import { createSlice } from '@reduxjs/toolkit'

export const departmentSlice = createSlice({
    name: 'departments',
    initialState: {
        departments: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getDepartmentRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getDepartmentRecordsSuccess: (state, action) => {
            return {
                ...state,
                departments: action.payload.departments,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getDepartmentRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteDepartmentSuccess: (state, action) => {
            return {
                ...state,
                departments: state.departments.filter(department => department.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportDepartmentSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportDepartmentFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getDepartmentRecordsLoading, setRecordDeleted, deleteDepartmentSuccess, getDepartmentRecordsSuccess,
    getDepartmentRecordsFailure, setPage, setRowsPerPage, exportDepartmentSuccess, exportDepartmentFailure } = departmentSlice.actions

export default departmentSlice.reducer