import { Alert, Button, Card, Container, Grid, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import validator from 'validator'

export function Forgotpassword() {
    const { REACT_APP_API_URL } = process.env;
    const [emailError, setEmailError] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')
    const [buttonDisabled, SetButtonDisabled] = useState(false)
    const [severity, SetSeverity] = useState('')
    const [message, updateMessage] = useState('')
    const validateEmail = (e) => {
        const email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
            SetButtonDisabled(false)
        } else {
            setEmailError(true)
            setEmailHelperText('Enter a valid email.')
            SetButtonDisabled(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.nativeEvent.target[0].value;
        if (validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
        } else {
            setEmailError(true)
            setEmailHelperText('Enter a valid email.')
        }
        if (email) {
            sendForgotPasswordRequest(email);
        }
    }

    const sendForgotPasswordRequest = (email) => {
        SetButtonDisabled(true)
        fetch(`${REACT_APP_API_URL}password/forgot-password`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                "email": email,
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.response_code === 200 && responseJson.status === true) {
                    updateMessage(responseJson.message);
                    SetSeverity('success');
                    SetButtonDisabled(false)
                } else {
                    SetSeverity('error')
                    updateMessage(responseJson.message);
                    SetButtonDisabled(false)
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            <Paper elevation={0} sx={{ background: '#e3f2fd', height: '100vh' }}>
                <Container maxWidth="lg" sx={{ height: '100%' }}>
                    <Grid container sx={{ height: '100%' }} justifyContent="center" alignItems="center">
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                        <Grid item lg={6} md={6} sm={10} xs={12}>
                            <Card variant="outlined" sx={{ padding: '1.5rem 4rem 4rem' }} className='formPaper'>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div style={{ textAlign: 'center' }}><img src={logo} alt="logo" style={{ margin: '0 auto 1rem', display: 'inline-block', maxWidth: '200px' }} /></div>
                                    <h2>Forgot Password</h2>
                                    <p>Enter your email associated with your account and we'll send an email with instructions to reset your password.</p>
                                    <TextField onChange={(e) => validateEmail(e)} fullWidth id="email" label="Email" variant="outlined" margin="normal"
                                        error={emailError} helperText={emailHelperText} />
                                    <Button type="submit" sx={{ margin: '1rem 0' }} disabled={buttonDisabled} variant="contained" disableElevation fullWidth size="large">Send Instructions</Button>
                                </form>
                                <div style={{ textAlign: 'center', marginBottom: '1rem' }}><NavLink to="/" style={{ color: 'grey', textDecoration: 'none' }}>Back to login</NavLink></div>
                                {message !== '' && (
                                    <Alert severity={severity}>{message}</Alert>
                                )}
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </div>
    )
}

export default Forgotpassword
