import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { setPagesCollapsed } from "../topbar/topbarSlice";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

function Sidebar() {
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);
  const pagesColapsed = useSelector((state) => state.topbar.pagesColapsed);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setPagesCollapsed(!pagesColapsed));
  };

  return (
    <div className="customDrawer" open={sidebarState}>
      <div className="logo">
        <img src={logo} width="156" alt="logo" />
      </div>
      <nav aria-label="main mailbox folders" className="adminNavigation">
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/dashboard"
              >
                <DashboardOutlinedIcon /> Dashboard
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/website-setting"
              >
                <SettingsOutlinedIcon /> Website Settings
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/events"
              >
                <EmojiEventsOutlinedIcon /> Events
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/conferences"
              >
                <VideocamOutlinedIcon /> Conferences
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItemButton onClick={handleClick}>
            <ListItemIcon sx={{ minWidth: "24px" }}>
              <InboxIcon
                sx={{ color: "#666", minWidth: "24px", marginRight: "10px" }}
              />
            </ListItemIcon>
            <ListItemText primary="Pages" sx={{ color: "#666" }} />
            {!pagesColapsed ? (
              <ExpandLess sx={{ color: "#666" }} />
            ) : (
              <ExpandMore sx={{ color: "#666" }} />
            )}
          </ListItemButton>
          <Collapse
            in={!pagesColapsed}
            timeout="auto"
            unmountOnExit
            sx={{ color: "#666", paddingLeft: "20px" }}
          >
            <List component="div" disablePadding sx={{ color: "#666" }}>
              <ListItem disablePadding sx={{ color: "#666" }}>
                <ListItemButton sx={{ padding: "0px", color: "#666" }}>
                  <NavLink
                    sx={{ color: "#666" }}
                    className={({ isActive }) =>
                      "nav-link items" + (isActive ? " activated" : "")
                    }
                    to="/competition-guideline"
                  >
                    <AutoStoriesOutlinedIcon sx={{ color: "#666" }} />
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary="Competition Guidelines"
                    />
                  </NavLink>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ color: "#666" }}>
                <ListItemButton sx={{ padding: "0px", color: "#666" }}>
                  <NavLink
                    sx={{ color: "#666" }}
                    className={({ isActive }) =>
                      "nav-link items" + (isActive ? " activated" : "")
                    }
                    to="/about-us"
                  >
                    <AutoStoriesOutlinedIcon sx={{ color: "#666" }} />
                    <ListItemText sx={{ margin: 0 }} primary="About Us" />
                  </NavLink>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ color: "#666" }}>
                <ListItemButton sx={{ padding: "0px", color: "#666" }}>
                  <NavLink
                    sx={{ color: "#666" }}
                    className={({ isActive }) =>
                      "nav-link items" + (isActive ? " activated" : "")
                    }
                    to="/ask-a-rule"
                  >
                    <AutoStoriesOutlinedIcon sx={{ color: "#666" }} />
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary="Ask a Rules Question"
                    />
                  </NavLink>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ color: "#666" }}>
                <ListItemButton sx={{ padding: "0px", color: "#666" }}>
                  <NavLink
                    sx={{ color: "#666" }}
                    className={({ isActive }) =>
                      "nav-link items" + (isActive ? " activated" : "")
                    }
                    to="/privacy-policy"
                  >
                    <AutoStoriesOutlinedIcon sx={{ color: "#666" }} />
                    <ListItemText sx={{ margin: 0 }} primary="Privacy Policy" />
                  </NavLink>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/Join-our-team"
              >
                <GroupAddOutlinedIcon /> Join our Team
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/partners"
              >
                <HandshakeOutlinedIcon /> Partners
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/vendors"
              >
                <AddBusinessOutlinedIcon /> Vendors
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/judges"
              >
                <BalanceOutlinedIcon /> Judges
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/contactus"
              >
                <ContactPhoneOutlinedIcon /> Contact Us
              </NavLink>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/subscribers"
              >
                <UnsubscribeOutlinedIcon /> Newsletter Subscribers
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/homepage-slideshow"
              >
                <ViewCarouselOutlinedIcon /> Home Page Slides
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/contentpage-slideshow"
              >
                <ViewCarouselOutlinedIcon /> Content Page Slides
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/advertisements"
              >
                <PhotoSizeSelectActualOutlinedIcon /> Advertisements
              </NavLink>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton sx={{ padding: "0px" }}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link items" + (isActive ? " activated" : "")
                }
                to="/departments"
              >
                <CorporateFareIcon /> Departments
              </NavLink>
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </div>
  );
}

export default Sidebar;
