import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Alert,
  Backdrop,
  CircularProgress,
  Input,
} from "@mui/material";
import { Box } from "@mui/system";
import Topbar from "../../topbar/topbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../sidebar/sidebar";
import { Main } from "../../main/main";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import {
  editAdvertisementLink,
  editAdvertisementImage,
  setEditAdvertisementButtonDisabled,
} from "./editadvertisementSlice";

import {
  editAdvertisement,
  getAdvertisement,
} from "./editadvertisement.functions";

export function EditAdvertisement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const advertisementLink = useSelector(
    (state) => state.editAdvertisement.editAdvertisement.advertisementLink
  );

  const advertisementImage = useSelector(
    (state) => state.editAdvertisement.editAdvertisement.advertisementImage
  );

  const editAdvertisementButtonDisabled = useSelector(
    (state) => state.editAdvertisement.editAdvertisementButtonDisabled
  );

  const [linkError, setLinkError] = useState(false);
  const [linkHelperText, setLinkHelperText] = useState("");

  ///////////Error and view handles ///////////////
  const loading = useSelector((state) => state.editAdvertisement.loading);
  const message = useSelector((state) => state.editAdvertisement.message);
  const severity = useSelector((state) => state.editAdvertisement.severity);
  const errors = useSelector((state) => state.editAdvertisement.errors);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const validateLink = (link) => {
    if (
      link &&
      validator.isURL(link, {
        protocols: ["http", "https"],
        require_protocol: true,
      })
    ) {
      setLinkError(false);
      setLinkHelperText("");
      dispatch(setEditAdvertisementButtonDisabled({ editAdvertisementButtonDisabled: false }));
    } else {
      setLinkError(true);
      setLinkHelperText("Enter valid Link.");
      dispatch(setEditAdvertisementButtonDisabled({ editAdvertisementButtonDisabled: true }));
    }
    dispatch(editAdvertisementLink({ advertisementLink: link }));
  };

  // Attachments
  const [pictureOfadvertisement, savePicture] = useState(null);

  const pictureOfAdvertisementHandleChange = (event) => {
    savePicture(event.target.files[0]);
    getBase64(event.target.files[0]).then((base64) => {
      const file = base64;

      dispatch(
        editAdvertisementImage({
          advertisementImage: file,
        })
      );
    });
  };

  // Attachments

  const [AdvertisementId, saveAdvertisementId] = useState(0);
  const params = useParams();

  useEffect(() => {
    if (params) {
      const AdvertisementId = params.id;
      saveAdvertisementId(AdvertisementId);
      dispatch(getAdvertisement(AdvertisementId));
    }
  }, [dispatch, params]);

  const handleClickBack = () => {
    navigate("/advertisements");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ediAdvertisementData = {
      link: advertisementLink,
      image: pictureOfadvertisement,
    };
    dispatch(editAdvertisement(ediAdvertisementData, AdvertisementId));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">Edit Advertisement</Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2} item>
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingTop: "30px", marginLeft: "0" }}
                      >
                        <Grid
                          container
                          spacing={2}
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{ marginBottom: "30px", marginLeft: '0', flexWrap: 'wrap' }}
                        >
                          <label
                            htmlFor="pictureOfadvertisement"
                            className="file-input pictureInput"
                            style={{ width: '100%' }}
                          >
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton"
                            >
                              Select Image of Advertisement
                            </Button>
                            <Input
                              id="pictureOfadvertisement"
                              onChange={pictureOfAdvertisementHandleChange}
                              accept="image/*"
                              type="file"
                            />
                            <label className="recomemdedText">Recommended Size : 722px X 196px</label>
                          </label>
                          {!!advertisementImage && (
                            <img
                              src={advertisementImage}
                              alt=""
                              className="advertisementImage"
                              width="250px"
                              style={{ marginTop: "10px" }}
                            />
                          )}
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField
                            id="link"
                            value={advertisementLink}
                            onChange={(e) => validateLink(e.target.value)}
                            label="Link"
                            error={linkError}
                            helperText={linkHelperText}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            disabled={editAdvertisementButtonDisabled}
                            sx={{ margin: "1rem 0" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={handleClickBack}
                            sx={{ margin: "1rem" }}
                            variant="outlined"
                            disableElevation
                            size="large"
                          >
                            Cancel
                          </Button>

                          {!!message && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Alert severity={severity}>{message}</Alert>
                            </Grid>
                          )}
                          {!!errors &&
                            errors.map((error) => (
                              <Grid
                                item
                                key={error}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Alert severity={severity}>{error}</Alert>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box >
  );
}

export default EditAdvertisement;
