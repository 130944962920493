import { Alert, Button, Card, Container, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { saveResetPasswordSuccess, saveResetPasswordError } from './resetpasswordSlice';
import validator from 'validator'
import { useNavigate } from 'react-router-dom';

export function Resetpassword() {

    let navigate = useNavigate();
    const { REACT_APP_API_URL } = process.env;
    const dispatch = useDispatch()
    const resetPasswordMessage = useSelector(state => state.resetPassword.message)
    const [emailError, setEmailError] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [newPasswordHelperText, setnewPasswordHelperText] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('')
    const [buttonDisabled, SetButtonDisabled] = useState(true)
    const severity = useSelector(state => state.resetPassword.severity)

    const useQuery = () => new URLSearchParams(window.location.search);
    const query = useQuery();
    const token = query.get('token');

    useEffect(() => {
        CheckToken(token);
    });

    const CheckToken = (token) => {
        if (token === '' || token === null) {
            navigate('/403', { replace: true });
        }
    }

    const verifyEmail = (e) => {
        const email = e.target.value
        validateEmail(email);
    }

    const validateEmail = (email) => {
        if (!email) {
            setEmailError(true)
            setEmailHelperText('Email is required.')
            SetButtonDisabled(true)
        } else if (email && validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
            SetButtonDisabled(false)
            return true
        } else {
            setEmailError(true)
            setEmailHelperText('Email is incorrect, Enter a valid email.')
            SetButtonDisabled(true)
        }
    }

    const validateNewPassword = (newPassword) => {
        if (newPassword === '') {
            setNewPasswordError(true)
            setnewPasswordHelperText('Password is required.')
            SetButtonDisabled(true)
        } else if (newPassword !== '' && newPassword.length < 6) {
            setNewPasswordError(true)
            setnewPasswordHelperText('Password should be of minimum 6 characters.')
            SetButtonDisabled(true)
        } else {
            setNewPasswordError(false)
            setnewPasswordHelperText('')
            SetButtonDisabled(false)
            return true
        }
    }

    const validateConfirmPassword = (confirmPassword) => {
        if (confirmPassword !== '') {
            setConfirmPasswordError(false)
            setConfirmPasswordHelperText('')
        } else {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('Enter Confirm Password.')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let checkError = false;

        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

        const email = removeExtraSpace(e.nativeEvent.target[0].value);
        const newPassword = removeExtraSpace(e.nativeEvent.target[2].value);
        const confirmPassword = removeExtraSpace(e.nativeEvent.target[5].value);

        if (!email) {
            setEmailError(true)
            setEmailHelperText('Email is required.')
            SetButtonDisabled(true)
        } else if (email && validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
            SetButtonDisabled(false)
            checkError = true
        } else {
            setEmailError(true)
            setEmailHelperText('Email is incorrect, Enter a valid email.')
            SetButtonDisabled(true)
        }

        if (newPassword === '') {
            setNewPasswordError(true)
            setnewPasswordHelperText('Enter New Password.')
            SetButtonDisabled(true)
        } else if (newPassword !== '' && newPassword.length < 6) {
            setNewPasswordError(true)
            setnewPasswordHelperText('The new password must be of 6 characters.')
            SetButtonDisabled(true)
        } else {
            setNewPasswordError(false)
            setnewPasswordHelperText('')
            checkError = true
        }
        if (confirmPassword !== '') {
            setConfirmPasswordError(false)
            setConfirmPasswordHelperText('')
            checkError = true
        } else {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('Enter Confirm Password.')
        }
        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(true)
            setConfirmPasswordHelperText('New password and confirm password do not match.')
        }

        if (checkError) {
            sendResetPasswordRequest(newPassword, confirmPassword, email)
        }
    }

    const [values, setValues] = React.useState({
        showNewPassword: false,
        showConfirmPassword: false
    });

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const sendResetPasswordRequest = (password, password_confirmation, email) => {
        fetch(`${REACT_APP_API_URL}password/reset`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                "token": token,
                "password": password,
                "password_confirmation": password_confirmation,
                "email": email
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(saveResetPasswordSuccess(responseJson.message))
                    SetButtonDisabled(false)
                    setTimeout(function () {
                        navigate('/', { replace: true });
                    }, 3000);
                } else {
                    dispatch(saveResetPasswordError(responseJson.message))
                    const getErrors = Object.values(responseJson.errors);
                    if (getErrors) {
                        getErrors.map((item, key) => {
                            dispatch(saveResetPasswordError(Object.values(item)[0]))
                            return true;
                        });
                    }


                    SetButtonDisabled(false)
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            <Paper elevation={0} sx={{ background: '#e3f2fd', height: '100vh' }}>
                <Container maxWidth="lg" sx={{ height: '100%' }}>
                    <Grid container sx={{ height: '100%' }} justifyContent="center" alignItems="center">
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                        <Grid item lg={6} md={6} sm={10} xs={12}>
                            <Card variant="outlined" sx={{ padding: '1.5rem 4rem 4rem' }} className='formPaper'>
                                <div style={{ textAlign: 'center' }}><img src={logo} alt="logo" style={{ margin: '0 auto 1rem', display: 'inline-block', maxWidth: '200px' }} /></div>
                                <h2>Reset Password</h2>
                                <p>Your new password needs to be at least 6 characters (not more than 64 characters).</p>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <TextField fullWidth id="email" label="Email" variant="outlined" margin="normal"
                                        error={emailError} onChange={(e) => verifyEmail(e)} helperText={emailHelperText} />
                                    <FormControl fullWidth margin="normal" variant="outlined">
                                        <InputLabel htmlFor="password" className="inputLable">New Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={(e) => validateNewPassword(e)}
                                            error={newPasswordError}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        {!!newPasswordError && (
                                            <FormHelperText error id="password-error">
                                                {newPasswordHelperText}
                                            </FormHelperText>)}
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" variant="outlined">
                                        <InputLabel htmlFor="password_confirmation" className="inputLable">Confirm Password</InputLabel>
                                        <OutlinedInput
                                            id="password_confirmation"
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={(e) => validateConfirmPassword(e)}
                                            error={newPasswordError}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        {!!confirmPasswordError && (
                                            <FormHelperText error id="password-error">
                                                {confirmPasswordHelperText}
                                            </FormHelperText>)}
                                    </FormControl>
                                    <Button disabled={buttonDisabled} type="submit" sx={{ margin: '1rem 0' }} variant="contained" fullWidth size="large">Submit</Button>
                                </form>
                                {!!resetPasswordMessage && (
                                    <Alert severity={severity}>{resetPasswordMessage}</Alert>
                                )}
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </div>
    )
}

export default Resetpassword
