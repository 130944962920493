import { createSlice } from '@reduxjs/toolkit'

export const vendorRecordSlice = createSlice({
    name: 'vendor',
    initialState: {
        vendorrequests: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getVendorRequestRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getVendorRequestRecordsSuccess: (state, action) => {
            return {
                ...state,
                vendorrequests: action.payload.vendorrequests,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getVendorRequestRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteVendorRequestSuccess: (state, action) => {
            return {
                ...state,
                vendorrequests: state.vendorrequests.filter(teamrequest => teamrequest.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportVendorRequestSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportVendorRequestFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getVendorRequestRecordsLoading, setRecordDeleted, deleteVendorRequestSuccess, getVendorRequestRecordsSuccess,
    getVendorRequestRecordsFailure, setPage, setRowsPerPage, exportVendorRequestSuccess, exportVendorRequestFailure } = vendorRecordSlice.actions

export default vendorRecordSlice.reducer