import { createSlice } from '@reduxjs/toolkit'

export const advertisementSlice = createSlice({
    name: 'advertisements',
    initialState: {
        advertisements: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
    },
    reducers: {
        getAdvertisementRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getAdvertisementRecordsSuccess: (state, action) => {
            return {
                ...state,
                advertisements: action.payload.advertisements,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getAdvertisementRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteAdvertisementSuccess: (state, action) => {
            return {
                ...state,
                advertisements: state.advertisements.filter(advertisement => advertisement.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        }
    }
})

export const { getAdvertisementRecordsLoading, setRecordDeleted, deleteAdvertisementSuccess, getAdvertisementRecordsSuccess,
    getAdvertisementRecordsFailure, setPage, setRowsPerPage } = advertisementSlice.actions

export default advertisementSlice.reducer