import {
  Alert,
  Snackbar,
  IconButton,
  Button,
  Card,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Main } from "../../main/main";
import Sidebar from "../../sidebar/sidebar";
import Topbar from "../../topbar/topbar";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import validator from "validator";

export function AddDepartment() {
  const { REACT_APP_API_URL } = process.env;
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [severity, severityText] = useState("error");
  const [apimessage, setapiMessage] = useState("");
  let navigate = useNavigate();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  let checkError = [];
  const { vertical, horizontal } = snackbarState;
  const [buttonDisabled, SetButtonDisabled] = useState(false);
  const snackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleClickBack = () => {
    navigate("/departments");
  };


  const validateName = (name) => {
    if (name) {
      setNameError(false);
      setNameHelperText("");
    } else {
      setNameError(true);
      setNameHelperText("Enter valid Name.");
      checkError.push("Enter valid Name.");
    }
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError(true);
      setEmailHelperText("Email is required.");
      checkError.push("Email is required.");
    } else if (email && validator.isEmail(email)) {
      setEmailError(false);
      setEmailHelperText("");
    } else {
      setEmailError(true);
      setEmailHelperText("Email is incorrect, Enter a valid email.");
      checkError.push("Email is incorrect, Enter a valid email.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.nativeEvent.target.elements.departmentName?.value;
    const email = e.nativeEvent.target.elements.email?.value;
    console.log(email);

    validateName(name);
    validateEmail(email);

    if (checkError.length === 0) {
      sendAdRequest(name, email);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendAdRequest = (name, email, severity) => {
    const token = window.localStorage.getItem("token");
    SetButtonDisabled(true);
    var formData = new FormData();

    formData.append("department_name", name);
    formData.append("email", email);

    fetch(`${REACT_APP_API_URL}department/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.response_code === 200 &&
          responseJson.status === true
        ) {
          setapiMessage(responseJson.message);
          severityText('success')
          if (responseJson) {
            SetButtonDisabled(false);
            setSnackbarState({ ...snackbarState, open: true });
            cancelCourse();
          }
        } else {
          SetButtonDisabled(false);
          setapiMessage(responseJson.errors.department_name);
          severityText('error');
          console.log(severity);
          if (responseJson) {
            setSnackbarState({ ...snackbarState, open: true });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelCourse = () => {
    document.getElementById("department-form").reset();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e7f0f6",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2} item md={12}>
              <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                <div
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: "1.5rem",
                  }}
                >
                  <Typography variant="h3">Add Department</Typography>
                  <Button
                    variant="contained"
                    disableElevation
                    startIcon={<ArrowBackIcon />}
                    onClick={handleClickBack}
                  >
                    Back
                  </Button>
                </div>
                <form id="department-form" onSubmit={(e) => handleSubmit(e)}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingTop: "30px", marginLeft: "0" }}
                  >
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingTop: "30px", marginLeft: "0" }}
                      >
                        <Grid
                          container
                          spacing={2}
                          item
                          lg={7}
                          md={7}
                          sm={7}
                          xs={12}
                          sx={{ marginBottom: "30px" }}
                        >
                          <TextField
                            name="departmentName"
                            label="Enter Department Name"
                            variant="outlined"
                            error={nameError}
                            onChange={(e) => validateName(e.target.value)}
                            helperText={nameHelperText === '' ? "" : nameHelperText}
                            fullWidth
                            inputProps={{ maxLength: 70 }}
                          />
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          item
                          lg={7}
                          md={7}
                          sm={7}
                          xs={12}
                          sx={{ marginBottom: "30px" }}
                        >
                          <TextField
                            name="email"
                            label="Enter Email ID"
                            variant="outlined"
                            error={emailError}
                            onChange={(e) => validateEmail(e.target.value)}
                            helperText={emailHelperText === '' ? "" : emailHelperText}
                            fullWidth
                            inputProps={{ maxLength: 70 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Button
                        disabled={buttonDisabled}
                        sx={{ margin: "1rem 0" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={handleClickBack}
                        sx={{ margin: "1rem" }}
                        variant="outlined"
                        disableElevation
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Card>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={snackbarClose}
                action={action}
                key={vertical + horizontal}
              >
                <Alert severity={severity} variant="filled">
                  {apimessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Paper>
      </Main>
    </Box>
  );
}

export default AddDepartment;
