import styled from "@emotion/styled";

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'sidebarState' })(
    ({ theme, sidebarState }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        marginLeft: sidebarState ? 240 : 0,
        ...(sidebarState && {
            marginLeft: 0,
        }),
    }),
);