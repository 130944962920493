import {
  editDepartmentFailure,
  editDepartmentSuccess,
  getDepartmentDataById,
  getDepartmentDataByIdFailure,
  getDepartmentDataByIdSuccess,
  editDepartmentLoading,
} from "./editdepartmentSlice";

import { getDepartmentRecordsFailure } from "../departmentSlice";

const { REACT_APP_API_URL } = process.env;

export function editDepartment(data, adId) {
  let formData = new FormData();

  formData.append("department_name", data.name);
  formData.append("email", data.email);

  formData.append("_method", "PUT");
  return function editDepartmentThunk(dispatch) {
    dispatch(editDepartmentLoading());
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}department/update/${adId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            editDepartmentSuccess({ response: responseJson.message })
          );
        } else {
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            editDepartmentFailure({
              error: responseJson.message,
              errors: newErrors,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(editDepartmentFailure({ error: err }));
      });
  };
}
export function getDepartment(id) {
  return function getEventByIdThunk(dispatch) {
    dispatch(getDepartmentDataById());
    const token = window.localStorage.getItem("token");
    if (token) {
      fetch(`${REACT_APP_API_URL}department/edit/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson) {
            dispatch(
              getDepartmentDataByIdSuccess({
                editDepartment: responseJson,
              })
            );
          } else {
            dispatch(getDepartmentDataByIdFailure({ error: "API Error" }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getDepartmentRecordsFailure({ error: err }));
        });
    }
  };
}
