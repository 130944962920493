import {
    Alert, Button, Card, Checkbox, Container, FormControl,
    FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment,
    InputLabel, OutlinedInput, Paper, TextField
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux';
import { saveLoginToken, saveLoginTokenError } from './loginSlice';

export function Login() {

    let navigate = useNavigate();
    const { REACT_APP_API_URL } = process.env;
    const dispatch = useDispatch()
    const loginError = useSelector(state => state.user.error)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')
    const [passwordHelperText, setPasswordHelperText] = useState('')
    const [buttonDisabled, SetButtonDisabled] = useState(true)
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    useEffect(() => {
        const token = window.localStorage.getItem('token')
        if (token) {
            dispatch(saveLoginToken(token));
            navigate('/dashboard', { replace: true });
        }
    });

    const verifyEmail = (e) => {
        const email = e.target.value
        validateEmail(email);
    }

    const validateEmail = (email) => {
        if (!email) {
            setEmailError(true)
            setEmailHelperText('Email is required.')
            SetButtonDisabled(true)
        } else if (email && validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
            SetButtonDisabled(false)
            return true
        } else {
            setEmailError(true)
            setEmailHelperText('Email is incorrect, Enter a valid email.')
            SetButtonDisabled(true)
        }
    }

    const verifyPassword = (e) => {
        const password = e.target.value
        validatePassword(password);
    }

    const validatePassword = (password) => {
        if (password === '') {
            setPasswordError(true)
            setPasswordHelperText('Password is required.')
            SetButtonDisabled(true)
        } else if (password !== '' && password.length < 6) {
            setPasswordError(true)
            setPasswordHelperText('Password should be of minimum 6 characters.')
            SetButtonDisabled(true)
        } else {
            setPasswordError(false)
            setPasswordHelperText('')
            SetButtonDisabled(false)
            return true
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.nativeEvent.target[0].value;
        const password = e.nativeEvent.target[2].value;
        const rememberMe = e.nativeEvent.target[5].checked;
        if (validateEmail(email) && !validatePassword(password)) {
            setPasswordError(true)
            SetButtonDisabled(true)
        } else if (!validateEmail(email) && validatePassword(password)) {
            setEmailError(true)
            SetButtonDisabled(true)
        } else if (validateEmail(email) && validatePassword(password)) {
            SetButtonDisabled(true)
            sendLoginRequest(email, password, rememberMe);
        } else if (!validateEmail(email) && !validatePassword(password)) {
            setEmailError(true)
            setPasswordError(true)
            SetButtonDisabled(true)
        }
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const sendLoginRequest = (email, password, rememberMe) => {
        fetch(`${REACT_APP_API_URL}login`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                "email": email,
                "password": password,
                "remember_token": rememberMe
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(saveLoginToken(responseJson.token));
                    window.localStorage.setItem('token', responseJson.token)
                    SetButtonDisabled(false)
                    navigate('/dashboard', { replace: true });
                } else {
                    dispatch(saveLoginTokenError(responseJson.message))
                    SetButtonDisabled(false)
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            <Paper elevation={0} sx={{ background: '#e3f2fd', height: '100vh' }}>
                <Container maxWidth="lg" sx={{ height: '100%' }}>

                    <Grid container sx={{ height: '100%' }} justifyContent="center" alignItems="center">
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                        <Grid item lg={6} md={6} sm={10} xs={12}>
                            <Card variant="outlined" sx={{ padding: '1.5rem 4rem 4rem' }} className='formPaper'>
                                <div style={{ textAlign: 'center' }}><img src={logo} alt="logo" style={{ margin: '0 auto 1rem', display: 'inline-block', maxWidth: '200px' }} /></div>
                                <h2>Login</h2>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <TextField fullWidth id="email" label="Email" variant="outlined" margin="normal"
                                        error={emailError} onChange={(e) => verifyEmail(e)} helperText={emailHelperText} />
                                    <FormControl fullWidth margin="normal" variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={(e) => verifyPassword(e)}
                                            error={passwordError}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        {!!passwordError && (
                                            <FormHelperText error id="password-error">
                                                {passwordHelperText}
                                            </FormHelperText>)}
                                    </FormControl>
                                    <FormControlLabel control={<Checkbox color="secondary" />} label="Remember Me" />
                                    <Button type="submit"
                                        disabled={buttonDisabled}
                                        sx={{ margin: '1rem 0' }} variant="contained" disableElevation fullWidth size="large">Login</Button>
                                </form>
                                <div style={{ textAlign: 'right', marginBottom: '1rem' }}><NavLink to="/forgot-password" style={{ color: 'grey', textDecoration: 'none' }}>Forgot Password?</NavLink></div>
                                {!!loginError && (
                                    <Alert severity="error">{loginError}</Alert>
                                )}

                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={1} xs={12}>
                        </Grid>
                    </Grid>

                </Container>
            </Paper>
        </div>
    )
}

export default Login
