import { createSlice } from '@reduxjs/toolkit'

export const subscriberRecordSlice = createSlice({
    name: 'subscriber',
    initialState: {
        subscriberrequests: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getSubscriberRequestRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getSubscriberRequestRecordsSuccess: (state, action) => {
            return {
                ...state,
                subscriberrequests: action.payload.subscriberrequests,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getSubscriberRequestRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteSubscriberRequestSuccess: (state, action) => {
            return {
                ...state,
                subscriberrequests: state.subscriberrequests.filter(teamrequest => teamrequest.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportSubscriberRequestSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportSubscriberRequestFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getSubscriberRequestRecordsLoading, setRecordDeleted, deleteSubscriberRequestSuccess, getSubscriberRequestRecordsSuccess,
    getSubscriberRequestRecordsFailure, setPage, setRowsPerPage, exportSubscriberRequestSuccess, exportSubscriberRequestFailure } = subscriberRecordSlice.actions

export default subscriberRecordSlice.reducer