import {
  CssBaseline,
  Typography,
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Fab,
  Alert,
  Backdrop,
  CircularProgress,
  FormControl,
  Input,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { addSlide } from "./slides.function";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import Topbar from "../topbar/topbar";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../sidebar/sidebar";
import { Main } from "../main/main";

import {
  setSlideHeading,
  setSlideDescription,
  setSlideImage,
  setSlideDisplayOrder,
} from "./addslideSlice";
import { useNavigate } from "react-router-dom";

export function AddSlide() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const slideHeading = useSelector(
    (state) => state.addSlide.slide.slideHeading
  );
  const slideDescription = useSelector(
    (state) => state.addSlide.slide.slideDescription
  );
  const slideImage = useSelector((state) => state.addSlide.slide.slideImage);

  const slideDisplayOrder = useSelector(
    (state) => state.addSlide.slide.slideDisplayOrder
  );

  const addSlideButtonDisabled = useSelector(
    (state) => state.addSlide.addSlideButtonDisabled
  );

  const loading = useSelector((state) => state.addSlide.loading);
  const message = useSelector((state) => state.addSlide.message);
  const severity = useSelector((state) => state.addSlide.severity);
  const errors = useSelector((state) => state.addSlide.errors);

  const [textAreaCount, setTextAreaCount] = useState(0);
  const [headingCount, setHeadingCount] = useState(0);

  const slideHeadinghandleChange = (event) => {
    headingCalculate(event.target.value);
    dispatch(setSlideHeading({ slideHeading: event.target.value }));
  };
  const slideDescriptionHandleChange = (event) => {
    recalculate(event.target.value);
    dispatch(setSlideDescription({ slideDescription: event.target.value }));
  };
  const slideDisplayOrderhandleChange = (event) => {
    dispatch(setSlideDisplayOrder({ slideDisplayOrder: event.target.value }));
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // Attachments
  const [pictureOfSlide, savePicture] = useState(null);

  const pictureOfSlideHandleChange = (event) => {
    savePicture(event.target.files[0]);
    getBase64(event.target.files[0]).then((base64) => {
      const file = base64;

      dispatch(
        setSlideImage({
          slideImage: file,
        })
      );
    });
  };

  const handleClickBack = () => {
    navigate("/homepage-slideshow");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const addSlideData = {
      heading: slideHeading,
      description: slideDescription,
      image: pictureOfSlide,
      display_order: slideDisplayOrder,
    };
    dispatch(addSlide(addSlideData));
    setTextAreaCount(0);
    setHeadingCount(0);
  };

  const recalculate = (message) => {
    console.log("event value:", message);
    setTextAreaCount(message.length);
  };

  const headingCalculate = (headingMessage) => {
    console.log("event value:", headingMessage);
    setHeadingCount(headingMessage.length);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">Add Home Page Slide</Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2} item>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="heading"
                          value={slideHeading}
                          onChange={slideHeadinghandleChange}
                          label="Heading"
                          variant="outlined"
                          inputProps={{ maxLength: 70 }}
                          fullWidth
                        />
                        <p className="charactorLimitTxt">
                          {`${headingCount} to 70 Characters`}
                        </p>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label
                          htmlFor="pictureOfSlide"
                          className="file-input pictureInput"
                        >
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Select Picture of Slide
                          </Button>
                          <Input
                            id="pictureOfSlide"
                            onChange={pictureOfSlideHandleChange}
                            accept="image/*"
                            type="file"
                          />
                          <label className="recomemdedText inline">Recommended Size : 1920px X 572px</label>
                        </label>
                        {!!slideImage && (
                          <div className="fileName">
                            <img src={slideImage} alt="" className="slideImage" />
                          </div>
                        )}
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="description"
                            inputProps={{ maxLength: 150 }}
                            value={slideDescription}
                            onChange={slideDescriptionHandleChange}
                            label="Description*"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                          />
                          <p className="charactorLimitTxt">
                            {`${textAreaCount} to 150 Characters`}
                          </p>
                        </FormControl>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="displayorder"
                          value={slideDisplayOrder}
                          onChange={slideDisplayOrderhandleChange}
                          label="Display Order"
                          variant="outlined"
                        />
                      </Grid>

                      <Fab
                        disabled={addSlideButtonDisabled}
                        type="submit"
                        color="secondary"
                        aria-label="add"
                        className="saveEvent"
                        variant="extended"
                      >
                        <AddIcon sx={{ mr: 1 }} /> Submit
                      </Fab>
                      {!!message && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>{message}</Alert>
                        </Grid>
                      )}
                      {!!errors &&
                        errors.map((error) => (
                          <Grid
                            item
                            key={error}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Alert severity={severity}>{error}</Alert>
                          </Grid>
                        ))}
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default AddSlide;
