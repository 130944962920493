import { createSlice } from '@reduxjs/toolkit'

export const dashboardRecordSlice = createSlice({
    name: 'dashboard',
    initialState: {
        eventconferences: [],
        subscribers: [],
        registrations: [],
        eventconferencesmounted: false,
        subscribersmounted: false,
        registrationsmounted: false,
        error: '',
        loading: false,
    },
    reducers: {
        getEventConferenceRecordLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                eventconferencesmounted: false
            }
        },
        getEventConferenceRecordsSuccess: (state, action) => {
            return {
                ...state,
                eventconferences: action.payload.eventconferences,
                loading: false,
                eventconferencesmounted: true
            }
        },
        getEventConferenceRecordFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                eventconferencesmounted: true
            }
        },
        getSubscribersRecordLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                subscribersmounted: false
            }
        },
        getSubscribersRecordsSuccess: (state, action) => {
            return {
                ...state,
                subscribers: action.payload.subscribers,
                loading: false,
                subscribersmounted: true
            }
        },
        getSubscribersRecordFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                subscribersmounted: true
            }
        },
        getRegistrationsRecordLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                registrationsmounted: false
            }
        },
        getRegistrationsRecordsSuccess: (state, action) => {
            return {
                ...state,
                registrations: action.payload.registrations,
                loading: false,
                registrationsmounted: true
            }
        },
        getRegistrationsRecordFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                registrationsmounted: true
            }
        }

    }
})

export const { getEventConferenceRecordLoading, getEventConferenceRecordsSuccess, getEventConferenceRecordFailure,
    getSubscribersRecordLoading, getSubscribersRecordsSuccess, getSubscribersRecordFailure,
    getRegistrationsRecordLoading, getRegistrationsRecordsSuccess, getRegistrationsRecordFailure } = dashboardRecordSlice.actions

export default dashboardRecordSlice.reducer