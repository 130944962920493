import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Fab,
  Alert,
  Backdrop,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import Topbar from "../topbar/topbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../sidebar/sidebar";
import { Main } from "../main/main";
import { useNavigate, useParams } from "react-router-dom";
import {
  editSlideLink,
  editSlideImage,
  editSlideDisplayOrder,
  editSelectedPages,
} from "./editslidecontentpageSlice";

import AddIcon from "@mui/icons-material/Add";
import {
  editSlideContentpage,
  getSlide,
} from "./editslidecontentpage.functions";
import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const pages = [
  { id: 1, name: "Competition Guidelines" },
  { id: 2, name: "Ask a Rules Question" },
  { id: 4, name: "About Us" },
  { id: 10000, name: "Vendors" },
  { id: 20000, name: "Partners" },
  { id: 30000, name: "Join Our Team" },
  { id: 40000, name: "Judges" },
  { id: 50000, name: "Conferences" },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function Editslide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const slideLink = useSelector(
    (state) => state.editSlideContentPage.slide.slideLink
  );

  const slideImage = useSelector(
    (state) => state.editSlideContentPage.slide.slideImage
  );

  const slideDisplayOrder = useSelector(
    (state) => state.editSlideContentPage.slide.slideDisplayOrder
  );
  const selectedPagesArray = useSelector(
    (state) => state.editSlideContentPage.slide.addSelectedPages
  );
  // 1,2,

  const addSlideButtonDisabled = useSelector(
    (state) => state.editSlideContentPage.addSlideButtonDisabled
  );

  ///////////Error and view handles ///////////////
  const loading = useSelector((state) => state.editSlideContentPage.loading);
  const message = useSelector((state) => state.editSlideContentPage.message);
  const severity = useSelector((state) => state.editSlideContentPage.severity);
  const errors = useSelector((state) => state.editSlideContentPage.errors);

  const slideDisplayOrderhandleChange = (event) => {
    dispatch(editSlideDisplayOrder({ slideDisplayOrder: event.target.value }));
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  //////////////// On change handles/////////////////
  const slideLinkhandleChange = (event) => {
    dispatch(editSlideLink({ slideLink: event.target.value }));
  };

  // Attachments
  const [pictureOfSlide, savePicture] = useState(null);

  const [slideId, saveSlideId] = useState(0);
  const params = useParams();

  const pictureOfSlideHandleChange = (event) => {
    savePicture(event.target.files[0]);
    getBase64(event.target.files[0]).then((base64) => {
      const file = base64;

      dispatch(
        editSlideImage({
          slideImage: file,
        })
      );
    });
  };

  useEffect(() => {
    if (params) {
      const slideId = params.id;
      saveSlideId(slideId);
      dispatch(getSlide(slideId));
    }
  }, [dispatch, params]);

  const handleClickBack = () => {
    navigate("/contentpage-slideshow");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editSlideContentPageData = {
      link: slideLink,
      image: pictureOfSlide,
      display_order: slideDisplayOrder,
      selected_pages: selectedPages,
    };
    dispatch(editSlideContentpage(editSlideContentPageData, slideId));
  };

  const theme = useTheme();
  const [selectedPages, setSelectedPages] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    dispatch(editSelectedPages({ addSelectedPages: value }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">
                      Edit Content Page Slide
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2} item>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <label
                          htmlFor="pictureOfSlide"
                          className="file-input pictureInput"
                        >
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Select Picture of Slide
                          </Button>
                          <Input
                            id="pictureOfSlide"
                            onChange={pictureOfSlideHandleChange}
                            accept="image/*"
                            type="file"
                          />
                          <label className="recomemdedText inline">
                            Recommended Size : 820px X 636px
                          </label>
                        </label>
                        {!!slideImage && (
                          <div className="fileName">
                            <img
                              src={slideImage}
                              alt=""
                              className="slideImage"
                            />
                          </div>
                        )}
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="heading"
                          value={slideLink}
                          onChange={slideLinkhandleChange}
                          label="Link"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <FormControl
                          sx={{ width: "100%" }}
                          className="selectPageDropdown"
                        >
                          <InputLabel id="demo-multiple-name-label">
                            Select Page
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={selectedPagesArray}
                            onChange={handleChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                          >
                            {pages.map((page) => (
                              <MenuItem
                                key={page.id}
                                value={page.id}
                                style={getStyles(
                                  page.name,
                                  selectedPages,
                                  theme
                                )}
                              >
                                {page.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <TextField
                          id="displayorder"
                          value={slideDisplayOrder}
                          onChange={slideDisplayOrderhandleChange}
                          label="Display Order"
                          variant="outlined"
                        />
                      </Grid>
                      <Fab
                        disabled={addSlideButtonDisabled}
                        type="submit"
                        color="secondary"
                        aria-label="add"
                        className="saveEvent"
                        variant="extended"
                      >
                        <AddIcon sx={{ mr: 1 }} /> Submit
                      </Fab>
                      {!!message && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>{message}</Alert>
                        </Grid>
                      )}
                      {!!errors &&
                        errors.map((error) => (
                          <Grid
                            item
                            key={error}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Alert severity={severity}>{error}</Alert>
                          </Grid>
                        ))}
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default Editslide;
