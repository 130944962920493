import {
  getEventConferenceRecordLoading, 
  getEventConferenceRecordsSuccess, 
  getEventConferenceRecordFailure,
  getSubscribersRecordLoading, 
  getSubscribersRecordsSuccess, 
  getSubscribersRecordFailure,
  getRegistrationsRecordLoading, 
  getRegistrationsRecordsSuccess, 
  getRegistrationsRecordFailure
} from "./dashboardSlice";
const { REACT_APP_API_URL } = process.env;

export function getEventConferenceRecords() {
  return function getEventConferenceRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getEventConferenceRecordLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}events-conferences-count`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getEventConferenceRecordsSuccess({
                eventconferences: responseJson.data,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getEventConferenceRecordFailure({ error: responseJson.message })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getEventConferenceRecordFailure({ error: err }));
        });
    }
  };
}

export function getSubscribersRecords() {
  return function getSubscribersRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getSubscribersRecordLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}subscribers-counts`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getSubscribersRecordsSuccess({
                subscribers: responseJson.data,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getSubscribersRecordFailure({ error: responseJson.message })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getSubscribersRecordFailure({ error: err }));
        });
    }
  };
}

export function getRegistrationsRecords() {
  return function getRegistrationsRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getRegistrationsRecordLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}registrations-counts`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getRegistrationsRecordsSuccess({
                registrations: responseJson.data,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getRegistrationsRecordFailure({ error: responseJson.message })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getRegistrationsRecordFailure({ error: err }));
        });
    }
  };
}
