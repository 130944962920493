import {
  deleteDepartmentSuccess,
  getDepartmentRecordsFailure,
  getDepartmentRecordsLoading,
  getDepartmentRecordsSuccess,
} from "./departmentSlice";
const { REACT_APP_API_URL } = process.env;

export function getDepartmentRecords(
  page,
  pageSize,
  sortBy = "created_at",
  order = "DESC",
  search = ""
) {
  const newPage = page + 1;
  return function getDepartmentRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getDepartmentRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}alldepartments?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getDepartmentRecordsSuccess({
                departments: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(getDepartmentRecordsFailure({ error: responseJson.info }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getDepartmentRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteDepartment(id, page, rowsPerPage) {
  return function deleteDepartmentThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}department/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getDepartmentRecords(page, rowsPerPage));
          dispatch(deleteDepartmentSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getDepartmentRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getDepartmentRecordsFailure({ error: err }));
      });
  };
}
