import { createSlice } from "@reduxjs/toolkit";

export const addSlideSlice = createSlice({
  name: "addSlide",
  initialState: {
    slide: {
      slideHeading: "",
      slideDescription: "",
      pictureOfSlide: "",
      slideImage: "",
      slideDisplayOrder: ""
    },
    errors: [],
    severity: "error",
    message: null,
    addSlideButtonDisabled: false,
    loading: false,
  },
  reducers: {
    setSlideHeading: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideHeading: action.payload.slideHeading,
        },
      };
    },
    setSlideDescription: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideDescription: action.payload.slideDescription,
        },
      };
    },
    setSlideImage: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideImage: action.payload.slideImage,
        },
      };
    },
    setSlideDisplayOrder: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideDisplayOrder: action.payload.slideDisplayOrder,
        },
      };
    },

    setAddSlideButtonDisabled: (state, action) => {
      return {
        ...state,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    addSlideLoading: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    addSlideSuccess: (state, action) => {
      return {
        ...state,
        slide: {
          slideHeading: "",
          slideDescription: "",
          pictureOfSlide: "",
          slideImage: "",
          slideDisplayOrder: ""
        },
        errors: [],
        message: action.payload.response,
        severity: "success",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    addSlideFailure: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        severity: "error",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },

    ///////////////
  },
});

export const {
  setSlideHeading,
  setSlideDescription,
  setSlideImage,
  setSlideDisplayOrder,
  setAddSlideButtonDisabled,
  addSlideLoading,
  addSlideSuccess,
  addSlideFailure,
} = addSlideSlice.actions;

export default addSlideSlice.reducer;
