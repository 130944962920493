import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/login/loginSlice";
import changePasswordReducer from "../features/changepassword/changePasswordSlice";
import topbarReducer from "../features/topbar/topbarSlice";
import joinourteamReducer from "../features/joinourteam/joinourteamSlice";
import partnerReducer from "../features/partners/partnerSlice";
import vendorReducer from "../features/vendors/vendorSlice";
import judgeReducer from "../features/judges/judgeSlice";
import contactusReducer from "../features/contactus/contactusSlice";
import subscriberReducer from "../features/subscribers/subscribersSlice";
import resetPasswordReducer from "../features/resetpassword/resetpasswordSlice";
import websiteSettingReducer from "../features/websitesetting/websitesettingSlice";
import addEventReducer from "../features/events/addevents/addeventSlice";
import eventsRecordReducer from "../features/events/listevents/listeventsSlice";
import editEventReducer from "../features/events/editevents/editeventSlice";
import bannersRecordReducer from "../features/homepagebanner/homepagebannerSlice";
import addSlideReducer from "../features/addslide/addslideSlice";
import advertisementsReducer from "../features/advertisement/advertisementSlice";
import editAdvertisementReducer from "../features/advertisement/editadvertisement/editadvertisementSlice";
import departmentReducer from "../features/department/departmentSlice";
import editDepartmentReducer from "../features/department/editdepartment/editdepartmentSlice";
import editSlideReducer from "../features/editSlide/editslideSlice";
import editCompetitionGuidelineReducer from "../features/competitionguideline/competitionguidelineSlice";
import editAboutUsReducer from "../features/pages/aboutus/aboutusSlice";
import editAskARuleReducer from "../features/pages/askarule/askaruleSlice";
import editPrivacyPolicyReducer from "../features/pages/privacypolicy/privacypolicySlice";
import bannersContentPageRecordReducer from "../features/contentpagebanner/contentpagebannerSlice";
import addSlideContentPageReducer from "../features/addslidecontentpage/addslidecontentpageSlice";
import editSlideContentPageReducer from "../features/editSlidecontentpage/editslidecontentpageSlice";
import conferencesRecordReducer from "../features/conferences/listconferences/listConferencesSlice";
import addConferenceReducer from "../features/conferences/addconferences/addconferenceSlice";
import editConferenceReducer from "../features/conferences/editconference/editconferenceSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";

export default configureStore({
  reducer: {
    user: loginReducer,
    changePassword: changePasswordReducer,
    topbar: topbarReducer,
    jointeam: joinourteamReducer,
    partner: partnerReducer,
    vendor: vendorReducer,
    judge: judgeReducer,
    contactus: contactusReducer,
    subscriber: subscriberReducer,
    events: eventsRecordReducer,
    resetPassword: resetPasswordReducer,
    websiteSetting: websiteSettingReducer,
    addEvent: addEventReducer,
    editEvent: editEventReducer,
    banners: bannersRecordReducer,
    addSlide: addSlideReducer,
    advertisements: advertisementsReducer,
    editAdvertisement: editAdvertisementReducer,
    departments: departmentReducer,
    editDepartment: editDepartmentReducer,
    editSlide: editSlideReducer,
    editCompetitionGuideline: editCompetitionGuidelineReducer,
    editAboutUs: editAboutUsReducer,
    editAskARule: editAskARuleReducer,
    editPrivacyPolicy: editPrivacyPolicyReducer,
    bannerscontentpage: bannersContentPageRecordReducer,
    addSlideContentPage: addSlideContentPageReducer,
    editSlideContentPage: editSlideContentPageReducer,
    conferences: conferencesRecordReducer,
    addConference: addConferenceReducer,
    editConference: editConferenceReducer,
    dashboard: dashboardReducer,
  },
});
