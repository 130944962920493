import { createSlice } from "@reduxjs/toolkit";

export const AskARuleSlice = createSlice({
  name: "editaskarule",
  initialState: {
    message: "",
    data: {
      id: 4,
      title: "",
      contentValue: "",
    },
    severity: "error",
    mounted: false,
    loading: false,
    errors: [],
  },
  reducers: {
    saveAskARuleError: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        loading: false,
        severity: "error",
      };
    },
    saveAskARuleSuccess: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        data: {
          title: action.payload.data.title,
          contentValue: !action.payload.data.content ? '' : action.payload.data.content,
        },
        severity: "success",
        loading: false,
        errors: [],
      };
    },
    setAskARule: (state, action) => {
      return {
        ...state,
        data: {
          title: action.payload.title,
          contentValue: !action.payload.content ? '' : action.payload.content,
        },
        mounted: true,
        loading: false,
      };
    },
    setTitle: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          title: action.payload.title,
        },
      };
    },
    setContent: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          contentValue: action.payload.contentValue,
        },
      };
    },
    saveAskARule: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getAskARule: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
});

export const {
  saveAskARuleSuccess,
  saveAskARuleError,
  setAskARule,
  saveAskARule,
  getAskARule,
  setTitle,
  setContent,
} = AskARuleSlice.actions;

export default AskARuleSlice.reducer;
