import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';



console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));

class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.REACT_APP_API_PUBLIC_URL  = process.env.REACT_APP_API_PUBLIC_URL;
    }
    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                <CKEditor
                    config={{
                        ckfinder: {
                            // Upload the images to the server using the CKFinder QuickUpload command.
                            //'http://localhost/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                            uploadUrl: `${this.REACT_APP_API_PUBLIC_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`
                        },
                        toolbar: {
                            items: [
                                'heading', '|',
                                'bold', 'italic', '|',
                                'link', '|',
                                'bulletedList', 'numberedList', '|',
                                'undo', 'redo',
                                'uploadImage'],
                        },
                        heading: {
                            options: [
                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
                            ]
                        },
                        link: {
                            addTargetToExternalLinks: false,
                            decorators: [
                                {
                                    mode: 'manual',
                                    label: 'external',
                                    attributes: {
                                        target: '_blank',
                                        rel: 'noopener noreferrer'
                                    }
                                }
                            ]
                        }
                    }}
                    editor={ClassicEditor}
                    data={this.props.data}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

Editor.propTypes = {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.string,
    onReady: PropTypes.func.isRequired
}

Editor.defaultProps = {
    onChange: () => undefined,
    onReady: () => undefined,
    data: '<p>Hello from CKEditor 5!</p>',
}

export default Editor;