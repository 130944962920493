import {
    AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Icon, IconButton, Menu, MenuItem, Toolbar, Typography
} from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { drawerToggle } from './topbarSlice';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { removeLoginToken } from '../login/loginSlice';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';

const useDialogStyles = makeStyles({
    paper: {
        width: '350px'
    }
});

export function Topbar() {
    const { REACT_APP_API_URL } = process.env;

    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [logoutDailog, setOpen] = React.useState(false);

    const logoutDailogOpen = () => {
        setOpen(true);
    };

    const logoutDailogClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    const logOut = () => {
        fetch(`${REACT_APP_API_URL}logout?token=${token}`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    window.localStorage.removeItem('token');
                    dispatch(removeLoginToken());
                    navigate("/")
                } else {
                    navigate("/")
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const dialogClasses = useDialogStyles();

    return (
        <AppBar position="relative" open={sidebarState} elevation={0} className='topAppBar'>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={(e) => dispatch(drawerToggle(!sidebarState))}
                    edge="start"
                    sx={{ color: '#000' }}
                >
                    <Icon color="primary"><MenuIcon /></Icon>
                </IconButton>
                <Typography variant="h6" noWrap component="div" className='header'>
                    <div className='logo'>HighPointz</div>
                    <div className='headerRight'>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Icon color="primary"><SettingsOutlinedIcon /></Icon>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => navigate("/change-password")}>
                                <Icon color="primary"><PasswordOutlinedIcon /></Icon>

                                <span style={{ marginLeft: '8px' }}>Change Password</span></MenuItem>
                            <MenuItem onClick={logoutDailogOpen}>
                                <Icon color="primary"><LogoutOutlinedIcon /></Icon>
                                <span style={{ marginLeft: '8px' }}>Logout</span></MenuItem>
                        </Menu>
                        <Dialog
                            open={logoutDailog}
                            onClose={logoutDailogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={dialogClasses}
                        >
                            <DialogTitle id="alert-dialog-title" className='dialogHeadWithIcon'>
                                <LogoutOutlinedIcon /> {"Logout"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" className='dialogContent'>
                                    Are you sure you want to logout?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <div className='dailogBtnActWrapper'>
                                    <Button onClick={logoutDailogClose} autoFocus variant="outlined" color='primary'>No</Button>
                                    <Button onClick={logOut} variant="contained" color='primary'>Yes</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Topbar