import { createSlice } from '@reduxjs/toolkit'

export const contactusRecordSlice = createSlice({
    name: 'contactus',
    initialState: {
        contactusrequests: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getContactusRequestRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getContactusRequestRecordsSuccess: (state, action) => {
            return {
                ...state,
                contactusrequests: action.payload.contactusrequests,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getContactusRequestRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deleteContactusRequestSuccess: (state, action) => {
            return {
                ...state,
                contactusrequests: state.contactusrequests.filter(teamrequest => teamrequest.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportContactusRequestSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportContactusRequestFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getContactusRequestRecordsLoading, setRecordDeleted, deleteContactusRequestSuccess, getContactusRequestRecordsSuccess,
    getContactusRequestRecordsFailure, setPage, setRowsPerPage, exportContactusRequestSuccess, exportContactusRequestFailure } = contactusRecordSlice.actions

export default contactusRecordSlice.reducer