import {
  deletePartnerRequestSuccess,
  getPartnerRequestRecordsFailure,
  getPartnerRequestRecordsLoading,
  getPartnerRequestRecordsSuccess,
  exportPartnerRequestSuccess,
  exportPartnerRequestFailure,
} from "./partnerSlice";
const { REACT_APP_API_URL } = process.env;

export function getPartnerRequestRecords(
  page,
  pageSize,
  sortBy = "created_at",
  order = "DESC",
  search = ""
) {
  const newPage = page + 1;
  return function getPartnerRequestRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getPartnerRequestRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}partner-requests?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getPartnerRequestRecordsSuccess({
                partnerrequests: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(
              getPartnerRequestRecordsFailure({ error: responseJson.info })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getPartnerRequestRecordsFailure({ error: err }));
        });
    }
  };
}

export function deletePartnerRequest(id, page, rowsPerPage) {
  return function deletePartnerRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}delete-partner-request/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getPartnerRequestRecords(page, rowsPerPage));
          dispatch(deletePartnerRequestSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(
            getPartnerRequestRecordsFailure({ error: responseJson.info })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPartnerRequestRecordsFailure({ error: err }));
      });
  };
}

export function exportPartnerRequest() {
  return function exportPartnerRequestThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}export-partners`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            exportPartnerRequestSuccess({ response: responseJson.data })
          );
        } else {
          console.log(responseJson);
          dispatch(exportPartnerRequestFailure({ error: responseJson.error }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(exportPartnerRequestFailure({ error: err }));
      });
  };
}
