import {
  getWebsiteSettings,
  saveWebsiteSetting,
  saveWebsiteSettingError,
  saveWebsiteSettingSuccess,
  setButtonDisabled,
  setWebsiteSettings,
} from "./websitesettingSlice";
const { REACT_APP_API_URL } = process.env;

export function getWebsiteSettingsData() {
  return function getWebsiteSettingsThunk(dispatch) {
    dispatch(getWebsiteSettings());
    const token = window.localStorage.getItem("token");
    if (token) {
      fetch(`${REACT_APP_API_URL}website-settings`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true) {
            dispatch(setWebsiteSettings(responseJson.data));
          } else {
            console.log(responseJson);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}

export function sendWebsiteSettingRequest(
  email,
  address,
  heading,
  phone_number,
  copyright,
  facebook_link,
  twitter_link,
  instagram_link,
  header_logo,
  footer_logo,
  contact_us_image,
  fax
) {
  return function sendWebsiteSettingRequestThunk(dispatch) {
    dispatch(setButtonDisabled({ buttondisabled: true }));
    dispatch(saveWebsiteSetting());
    var formData = new FormData();
    if (header_logo) {
      formData.append("header_logo", header_logo);
    }
    if (footer_logo) {
      formData.append("footer_logo", footer_logo);
    }
    if (contact_us_image) {
      formData.append("contact_us_image", contact_us_image);
    }

    formData.append("email", email);
    formData.append("address", address);
    formData.append("fax", fax);
    formData.append("heading", heading);
    formData.append("phone_number", phone_number);
    formData.append("copyright_content", copyright);
    formData.append("facebook_link", facebook_link);
    formData.append("twitter_link", twitter_link);
    formData.append("instagram_link", instagram_link);

    fetch(`${REACT_APP_API_URL}save-website-settings`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            saveWebsiteSettingSuccess({
              data: responseJson.data,
              message: responseJson.message,
            })
          );
          setButtonDisabled({ buttondisabled: false });
        } else {
          if (responseJson.message) {
            dispatch(saveWebsiteSettingError(responseJson.message));
            setButtonDisabled({ buttondisabled: false });
          } else if (responseJson.errors) {
            dispatch(saveWebsiteSettingError(responseJson.errors));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
