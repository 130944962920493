import {
  Alert,
  Snackbar,
  IconButton,
  Button,
  Card,
  CssBaseline,
  Grid,
  Input,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Main } from "../../main/main";
import Sidebar from "../../sidebar/sidebar";
import Topbar from "../../topbar/topbar";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

export function AddAdvertisement() {
  const { REACT_APP_API_URL } = process.env;
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);
  const [imageError, setImageError] = useState(false);
  const [imageHelperText, setImageHelperText] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [linkHelperText, setLinkHelperText] = useState("");
  const [apimessage, setapiMessage] = useState("");
  const [severity, severityText] = useState("error");
  let navigate = useNavigate();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  let checkError = [];
  const { vertical, horizontal } = snackbarState;
  const [buttonDisabled, SetButtonDisabled] = useState(false);
  const [adImageName, setAdImageName] = useState("");
  const [adImageSrc, setAdImageSrc] = useState("");
  const snackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleClickBack = () => {
    navigate("/advertisements");
  };

  const validateImage = (e) => {
    setAdImageName(e.target.files[0].name);
    if (e.target.files[0].name) {
      const imgsrc = URL.createObjectURL(e.target.files[0]);
      setAdImageSrc(imgsrc);
      setImageError(false);
      setImageHelperText("");
    } else {
      setImageError(true);
      setImageHelperText("Please upload advertisement image.");
      checkError.push("Please upload advertisement image.");
    }
  };

  const validateImageName = (advertisementImg) => {
    setAdImageName(advertisementImg);
    if (advertisementImg) {
      setImageError(false);
      setImageHelperText("");
    } else {
      setImageError(true);
      setImageHelperText("Please upload advertisement image.");
      checkError.push("Please upload advertisement image.");
    }
  };

  const validateLink = (link) => {
    if (
      link &&
      validator.isURL(link, {
        protocols: ["http", "https"],
        require_protocol: true,
      })
    ) {
      setLinkError(false);
      setLinkHelperText("");
    } else {
      setLinkError(true);
      setLinkHelperText("Enter valid Link.");
      checkError.push("Enter valid Link.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const advertisementImg =
      e.nativeEvent.target.elements.advertisementImage?.files[0];
    const link = e.nativeEvent.target.elements.advertisementLink?.value;

    validateImageName(advertisementImg);
    validateLink(link);

    if (checkError.length === 0) {
      sendAdRequest(advertisementImg, link);
      setAdImageSrc("");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendAdRequest = (advertisementImg, link) => {
    const token = window.localStorage.getItem("token");
    SetButtonDisabled(true);
    var formData = new FormData();
    if (advertisementImg) {
      formData.append("image", advertisementImg);
    }
    formData.append("link", link);

    fetch(`${REACT_APP_API_URL}advertisement/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.response_code === 200 &&
          responseJson.status === true
        ) {
          setapiMessage(responseJson.message);
          if (responseJson) {
            SetButtonDisabled(false);
            setSnackbarState({ ...snackbarState, open: true });
            cancelCourse();
            severityText('success')
          }
        } else {
          SetButtonDisabled(false);
          setapiMessage(responseJson.errors.image);
          severityText('error')
          if (responseJson) {
            setSnackbarState({ ...snackbarState, open: true });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelCourse = () => {
    document.getElementById("advertisement-form").reset();
  };
  
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e7f0f6",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2} item md={12}>
              <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                <div
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: "1.5rem",
                  }}
                >
                  <Typography variant="h3">Add Advertisement</Typography>
                  <Button
                    variant="contained"
                    disableElevation
                    startIcon={<ArrowBackIcon />}
                    onClick={handleClickBack}
                  >
                    Back
                  </Button>
                </div>
                <form id="advertisement-form" onSubmit={(e) => handleSubmit(e)}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingTop: "30px", marginLeft: "0" }}
                  >
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingTop: "30px", marginLeft: "0" }}
                      >
                        <Grid
                          container
                          spacing={2}
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{ marginBottom: "30px" }}
                        >
                          <label
                            htmlFor="advertisementImg"
                            className="file-input"
                          >
                            <Input
                              name="advertisementImage"
                              id="advertisementImg"
                              accept="image/*"
                              inputProps={{ accept: "image/*" }}
                              type="file"
                              onChange={(e) => validateImage(e)}
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton"
                            >
                              Select Advertisement Image
                            </Button>
                            <label className="recomemdedText">
                              Recommended Size : 722px X 196px
                            </label>
                            {/* <div className='fileName'>{adImageName}</div> */}
                            <br />
                            {adImageSrc && (
                              <img
                                src={adImageSrc}
                                width="250px"
                                alt=""
                                style={{ marginTop: "10px" }}
                              />
                            )}
                            <p className="errorMessage">
                              {imageError} {imageHelperText}
                            </p>
                          </label>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          sx={{ marginBottom: "30px" }}
                        >
                          <TextField
                            name="advertisementLink"
                            label="Enter Link URL"
                            variant="outlined"
                            error={linkError}
                            onChange={(e) => validateLink(e.target.value)}
                            helperText={linkHelperText === '' ? "http://www.example.com" : linkHelperText}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Button
                        disabled={buttonDisabled}
                        sx={{ margin: "1rem 0" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={handleClickBack}
                        sx={{ margin: "1rem" }}
                        variant="outlined"
                        disableElevation
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Card>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={snackbarClose}
                action={action}
                key={vertical + horizontal}
              >
                <Alert severity={severity} variant="filled">
                  {apimessage}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Paper>
      </Main>
    </Box>
  );
}

export default AddAdvertisement;
