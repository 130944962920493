import { Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import PropTypes from 'prop-types';

export function PartnersChart(props) {

    const [showElement, setShowElement] = React.useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 2000);
    },
        [])

    window.dispatchEvent(new Event('resize'))
    const [chartOptions, setChartOptions] = useState({
        options: {
            colors: [
                'rgba(103, 58, 183, 0.85)',
                'rgba(237, 231, 246, 0.85)',
                'rgba(103, 58, 183, 0.85)',
                'rgba(144, 202, 249, 0.85)',
            ],
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            }
        },
        series: [
            {
                name: "Subscribers",
                data: []
            }
        ]
    });

    useEffect(() => {
        setChartOptions({
            options: {
                colors: [
                    'rgba(103, 58, 183, 0.85)',
                    'rgba(237, 231, 246, 0.85)',
                    'rgba(103, 58, 183, 0.85)',
                    'rgba(144, 202, 249, 0.85)',
                ],
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },
                dataLabels: {
                    offsetY: 4,
                    position: "top",
                    style: {
                        fontSize: "13px",
                        // color: ["#ff0000"]
                    },
                },
            },
            series: [
                {
                    name: props.name,
                    data: props.data
                }
            ]
        });
    }, [props]);


    return (
        <Card className="dashboard_chart">
            <Typography variant="h6">{props.name}</Typography>
            <div className="chartWrapper">
                {showElement ? <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type="bar"
                    width="100%"
                /> : <></>}
            </div>
        </Card >
    );
}

PartnersChart.propTypes = {
    data: PropTypes.array,
    name: PropTypes.string,
}

export default PartnersChart
