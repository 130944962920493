import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'user',
    initialState: {
        token: '',
        error: ''
    },
    reducers: {
        saveLoginToken: (state, action) => {
            return {
                ...state,
                token: action.payload,
                error: ''
            }
        },
        saveLoginTokenError: (state, action) => {
            return {
                ...state,
                error: action.payload
            }
        },
        removeLoginToken: (state) => {
            return {
                ...state,
                token: '',
                error: ''
            }
        }
    }
})


export const { saveLoginToken, saveLoginTokenError, removeLoginToken } = loginSlice.actions

export default loginSlice.reducer