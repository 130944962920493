import { createSlice } from '@reduxjs/toolkit'

export const editConferenceSlice = createSlice({
    name: 'editConference',
    initialState: {
        conference: {
            conferenceName: '',
            accommodationId: null,
            conferenceType: 1,
            conferenceDate: convertDateTimeFormat(new Date()).toString(),
            deadlineDate: convertDateTimeFormat(new Date()).toString(),
            conferencePlace: '',
            conferenceAddress: '',
            conferenceImage: null,
            mapCode: '',
            schedulePdfName: '',
            covidGuidelinesPdfName: '',
            feeOneToNine: 0,
            feeTenToNineteen: 0,
            feeTwentyPlus: 0,
            feeNote: '',
            refundFull: convertDateTimeFormat(new Date()).toString(),
            refundHalf: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            accomodation: {
                accomodationId: 0,
                accommodation_title: '',
                accommodation_content: '',
                accommodation_date: convertDateTimeFormat(new Date()).toString(),
            },
            schedulePdfUrl: null,
            covidPdfUrl: null,
        },
        errors: [],
        severity: 'error',
        message: null,
        editConferenceButtonDisabled: false,
        loading: false,
        decideLater: {
            conferenceDate: false,
            deadlineDate: false,
            refundFull: false,
            refundHalf: false,
            noRefund: false,
            accommodation_date: false,
        },
    },
    reducers: {
        getConferenceDataById: (state, action) => {
            return {
                ...state,
                conference: state.conference,
                loading: true
            }
        },
        getConferenceDataByIdSuccess: (state, action) => {
            //console.log('conferenceaccommodations', action.payload.conference.conferenceaccommodations[0]);
            return {
                ...state,
                conference: {
                    conferenceName: action.payload.conference.name,
                    accommodationId: action.payload.conference.accommodation_id,
                    conferenceType: action.payload.conference.type,
                    conferenceDate: action.payload.conference.conference_date
                        ? convertDateTimeFormat(action.payload.conference.conference_date).toString()
                        : convertDateTimeFormat(new Date()).toString(),
                    deadlineDate: action.payload.conference.deadline_date
                        ? convertDateTimeFormat(action.payload.conference.deadline_date).toString()
                        : convertDateTimeFormat(new Date()).toString(),
                    conferencePlace: action.payload.conference.place,
                    conferenceAddress: action.payload.conference.address,
                    conferenceImage: action.payload.conference.image,
                    mapCode: action.payload.conference.map_code,
                    schedulePdfName: action.payload.conference.schedule_pdf,
                    covidGuidelinesPdfName: action.payload.conference.covid_pdf,
                    feeOneToNine: action.payload.conference.fee_one_to_ten,
                    feeTenToNineteen: action.payload.conference.fee_ten_to_nineteen,
                    feeTwentyPlus: action.payload.conference.fee_twenty_plus,
                    feeNote: action.payload.conference.fee_note,
                    refundFull: action.payload.conference.refund_full
                        ? convertDateTimeFormat(action.payload.conference.refund_full).toString()
                        : convertDateTimeFormat(new Date()).toString(),
                    refundHalf: action.payload.conference.refund_half
                        ? convertDateTimeFormat(action.payload.conference.refund_half).toString()
                        : convertDateTimeFormat(new Date()).toString(),
                    noRefund: action.payload.conference.refund_not
                        ? convertDateTimeFormat(action.payload.conference.refund_not).toString()
                        : convertDateTimeFormat(new Date()).toString(),
                    accomodation: {
                        accommodation_title: action.payload.conference.conferenceaccommodations?.title,
                        accommodation_content: action.payload.conference.conferenceaccommodations?.content,
                        accommodation_date: action.payload.conference.conferenceaccommodations?.date ?
                            convertDateTimeFormat(action.payload.conference.conferenceaccommodations.date).toString()
                            : convertDateTimeFormat(new Date()).toString(),
                    },
                    schedulePdfUrl: action.payload.conference.url_schedule_pdf,
                    covidPdfUrl: action.payload.conference.url_covid_pdf,
                },
                decideLater: {
                    conferenceDate: action.payload.conference.conference_date !== null ? false : true,
                    deadlineDate: action.payload.conference.deadline_date !== null ? false : true,
                    refundFull: action.payload.conference.refund_full !== null ? false : true,
                    refundHalf: action.payload.conference.refund_half !== null ? false : true,
                    noRefund: action.payload.conference.refund_not !== null ? false : true,
                    accommodation_date: action.payload.conference.conferenceaccommodations.date !== null ? false : true
                },
                loading: false,
            }
        },
        getConferenceDataByIdFailure: (state, action) => {
            return {
                ...state,
                errors: [],
                loading: false
            }
        },
        editConferenceLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                editConferenceButtonDisabled: true
            }
        },
        editConferenceSuccess: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                },
                errors: [],
                loading: false,
                message: action.payload.response,
                editConferenceButtonDisabled: false,
                severity: 'success'
            }
        },
        editConferenceFailure: (state, action) => {
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.error,
                editConferenceButtonDisabled: false,
                loading: false,
                severity: 'error'
            }
        },
        editConferenceButtonDisabled: (state, action) => {
            return {
                ...state,
                editConferenceButtonDisabled: action.payload.editConferenceButtonDisabled
            }
        },
        editConferenceName: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceName: action.payload.conferenceName
                }
            }
        },
        editConferencePlace: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferencePlace: action.payload.conferencePlace
                }
            }
        },
        editConferenceDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceDate: action.payload.conferenceDate,
                }
            }
        },
        editConferenceDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    conferenceDate: action.payload.decideLater
                }
            }
        },
        editDeadlineDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    deadlineDate: action.payload.deadlineDate,
                }
            }
        },
        editDeadlineDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    deadlineDate: action.payload.decideLater
                }
            }
        },
        editFullRefundDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    refundFull: action.payload.refundFull,
                }
            }
        },
        editFullRefundDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    refundFull: action.payload.decideLater
                }
            }
        },
        editHalfRefundDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    refundHalf: action.payload.refundHalf,
                }
            }
        },
        editHalfRefundDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    refundHalf: action.payload.decideLater
                }
            }
        },
        editNoRefundAfterDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    noRefund: action.payload.noRefund,
                }
            }
        },
        editNoRefundAfterDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    noRefund: action.payload.decideLater
                }
            }
        },
        editFeeOneToNine: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeOneToNine: action.payload.feeOneToNine,
                }
            }
        },
        editFeeTenToNineteen: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeTenToNineteen: action.payload.feeTenToNineteen,
                }
            }

        },
        editFeeTwentyPlus: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeTwentyPlus: action.payload.feeTwentyPlus,
                }
            }
        },
        editMapCode: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    mapCode: action.payload.mapCode
                }
            }
        },
        editConferenceAddress: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceAddress: action.payload.conferenceAddress
                }
            }
        },
        editConferenceImage: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    conferenceImage: action.payload.conferenceImage
                }
            }
        },
        editFeeNote: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeNote: action.payload.feeNote
                }
            }
        },
        clearFeeNote: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    feeNote: ''
                }
            }
        },
        editSchedulePdf: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    schedulePdfName: action.payload.schedulePdfName
                }
            }
        },
        editCovidGuidelinesPdf: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    covidGuidelinesPdfName: action.payload.covidGuidelinesPdfName
                }
            }
        },
        editAccommodationDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    accommodation_date: action.payload.decideLater
                }
            }
        },
        editAccommodationTitle: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_title: action.payload.title
                    }
                }
            }
        },
        editAccommodationContent: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_content: action.payload.content
                    }
                }
            }
        },
        editAccommodationDate: (state, action) => {
            return {
                ...state,
                conference: {
                    ...state.conference,
                    accomodation: {
                        ...state.conference.accomodation,
                        accommodation_date: action.payload.date
                    }
                }
            }
        },
    },
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

export const {
    getConferenceDataById,
    getConferenceDataByIdSuccess,
    getConferenceDataByIdFailure,
    editConferenceButtonDisabled,
    editConferenceName,
    editConferencePlace,
    editConferenceDate,
    editConferenceDateDecideLater,
    editDeadlineDate,
    editDeadlineDateDecideLater,
    editFullRefundDate,
    editFullRefundDateDecideLater,
    editHalfRefundDate,
    editHalfRefundDateDecideLater,
    editNoRefundAfterDate,
    editNoRefundAfterDecideLater,
    editFeeOneToNine,
    editFeeTenToNineteen,
    editFeeTwentyPlus,
    editConferenceAddress,
    editMapCode,
    editConferenceImage,
    editFeeNote,
    editSchedulePdf,
    editCovidGuidelinesPdf,
    editConferenceLoading,
    editConferenceSuccess,
    editConferenceFailure,
    clearFeeNote,
    editAccommodationTitle,
    editAccommodationContent,
    editAccommodationDate,
    editAccommodationDateDecideLater,
} = editConferenceSlice.actions

export default editConferenceSlice.reducer