import {
    Box, CssBaseline, Card, Paper, Typography, Grid, TextField, FormControl, InputLabel, MenuItem,
    Button, Input, FormLabel, RadioGroup, FormControlLabel, Radio,
    Fab, Alert, Backdrop, CircularProgress, Checkbox, InputAdornment
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Main } from '../../main/main'
import Sidebar from '../../sidebar/sidebar'
import Topbar from '../../topbar/topbar'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    setEventDate, setEventOpenToType, setEventType, setQualifierForNationals,
    setRegisterOnTime, setSelectedTabValue, setParking, setFullRefundBy, setHalfRefundBy,
    setNoRefund, setEventName, setEventPlace, setRuleQuestion, setPerformanceSurface,
    setPreliminaryLineup, setShortDescription, setDraftSchedule, setFinalSchedule, setAwardGiveaway, setLate,
    setCrossCompetitor, setSecondPerformance, setNote, setGeneral, setSenior, setChild,
    setChildFiveUnder, setOnTime, setMapUrlValue, setEventAddress,
    setFinalLatePaymentBy,
    setEventImage,
    setDivisionLevelPdf,
    setSchedulePdf,
    setResultsPdf,
    setCovidGuidelinesPdf,
    setQualificationInvitePdf,
    registerOnTimeByDecideLater,
    fullRefundByDecideLater,
    halfRefundByDecideLater,
    noRefundDecideLater,
    eventDateDecideLater,
    latePaymentByDecideLater,
    setCounter,
    setAccommodationTitle,
    setAccommodationContent,
    setAccommodationDate,
    accommodationDateDecideLater,
} from './addeventSlice'
import AddIcon from '@mui/icons-material/Add';
import { addEvent } from '../events.function';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom'
import locationImg from '../../../assets/location-image.gif';
import Editor from '../../../shared/editor/editor'

export function AddEvents() {

    const dispatch = useDispatch()
    let navigate = useNavigate();

    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const selectedTabValue = useSelector(state => state.addEvent.selectedTabValue);
    const eventName = useSelector(state => state.addEvent.event.eventName);
    const eventType = useSelector(state => state.addEvent.event.eventType);
    const eventOpenTo = useSelector(state => state.addEvent.event.eventOpenTo);
    const eventDate = useSelector(state => state.addEvent.event.eventDate);
    const eventPlace = useSelector(state => state.addEvent.event.eventPlace);
    const registerOnTime = useSelector(state => state.addEvent.event.registerOnTime);
    const finalLatePaymentBy = useSelector(state => state.addEvent.event.finalLatePaymentBy);
    const ruleQuestion = useSelector(state => state.addEvent.event.ruleQuestion);
    const qualifierForNationals = useSelector(state => state.addEvent.event.qualifierForNationals);
    const performanceSurface = useSelector(state => state.addEvent.event.performanceSurface);
    const preliminaryLineup = useSelector(state => state.addEvent.event.preliminaryLineup);
    const shortDescription = useSelector(state => state.addEvent.event.shortDescription);
    const draftSchedule = useSelector(state => state.addEvent.event.draftSchedule);
    const finalSchedule = useSelector(state => state.addEvent.event.finalSchedule);
    const awardGiveaway = useSelector(state => state.addEvent.event.awardGiveaway);
    const onTime = useSelector(state => state.addEvent.event.onTime);
    const late = useSelector(state => state.addEvent.event.late);
    const crossCompetitor = useSelector(state => state.addEvent.event.crossCompetitor);
    const secondPerformance = useSelector(state => state.addEvent.event.secondPerformance);
    const note = useSelector(state => state.addEvent.event.note);
    const general = useSelector(state => state.addEvent.event.general);
    const senior = useSelector(state => state.addEvent.event.senior);
    const child = useSelector(state => state.addEvent.event.child);
    const childFiveUnder = useSelector(state => state.addEvent.event.childFiveUnder);
    const parking = useSelector(state => state.addEvent.event.parking);
    const fullRefundBy = useSelector(state => state.addEvent.event.fullRefundBy);
    const halfRefundBy = useSelector(state => state.addEvent.event.halfRefundBy);
    const noRefund = useSelector(state => state.addEvent.event.noRefund);
    const mapUrl = useSelector(state => state.addEvent.event.mapUrl);
    const eventAddress = useSelector(state => state.addEvent.event.eventAddress);
    const eventImage = useSelector(state => state.addEvent.event.eventImage);
    const divisionLevelPdfName = useSelector(state => state.addEvent.event.divisionLevelPdfName);
    const schedulePdfName = useSelector(state => state.addEvent.event.schedulePdfName);
    const resultsPdfName = useSelector(state => state.addEvent.event.resultsPdfName);
    const covidGuidelinesPdfName = useSelector(state => state.addEvent.event.covidGuidelinesPdfName);
    const qualificationInvitePdfName = useSelector(state => state.addEvent.event.qualificationInvitePdfName);

    // Decide Later checkboxes
    const eventDateDecideLaterValue = useSelector(state => state.addEvent.decideLater.eventDate);
    const registerOnTimeDecideLaterValue = useSelector(state => state.addEvent.decideLater.registerOnTime);
    const finalLatePaymentByDecideLaterValue = useSelector(state => state.addEvent.decideLater.finalLatePaymentBy);
    const fullRefundByDecideLaterValue = useSelector(state => state.addEvent.decideLater.fullRefundBy);
    const halfRefundByDecideLaterValue = useSelector(state => state.addEvent.decideLater.halfRefundBy);
    const noRefundDecideLaterValue = useSelector(state => state.addEvent.decideLater.noRefund);
    const accommodationDateDecideLaterValue = useSelector((state) => state.addEvent.decideLater.accommodationDate);

    // Counter Selectors
    const performanceSurfaceCountValue = useSelector(state => state?.addEvent?.counter?.performanceSurfaceLength);
    const preliminaryLineupCountValue = useSelector(state => state.addEvent.counter.preliminaryLineupLength);
    const shortDescriptionCountValue = useSelector(state => state.addEvent.counter.shortDescriptionLength);
    const draftScheduleTextAreaCountValue = useSelector(state => state.addEvent.counter.draftScheduleLength);
    const finalScheduleTextAreaCountValue = useSelector(state => state.addEvent.counter.finalScheduleLength);
    const notesTextAreaCountValue = useSelector(state => state.addEvent.counter.notesLength);
    const awardsGiveawaysTextAreaCountValue = useSelector(state => state.addEvent.counter.awardsGiveawaysLength);

    const accommodationTitle = useSelector(state => state.addEvent.event.accomodation?.accommodationTitle);
    const accommodationContent = useSelector(state => state.addEvent.event.accomodation?.accommodationContent);
    const accommodationDate = useSelector(state => state.addEvent.event.accomodation?.accommodationDate);


    // Attachments
    const [pictureOfFacility, savePicture] = useState(null);
    const [divisionLevelPdf, saveDivisionLevelPdf] = useState(null);
    const [schedulePdf, saveSchedulePdf] = useState(null);
    const [resultsPdf, saveResultsPdf] = useState(null);
    const [covidGuidelinesPdf, saveCovidPdf] = useState(null);
    const [qualificationInvitePdf, saveInvitePdf] = useState(null);

    const addEventButtonDisabled = useSelector(state => state.addEvent.addEventButtonDisabled);
    const loading = useSelector(state => state.addEvent.loading);
    const message = useSelector(state => state.addEvent.message);
    const severity = useSelector(state => state.addEvent.severity);
    const errors = useSelector(state => state.addEvent.errors);

    const handleChange = (event, newValue) => {
        dispatch(setSelectedTabValue({ selectedTabValue: newValue }));
    };

    const eventNamehandleChange = (event) => {
        dispatch(setEventName({ eventName: event.target.value }));
    };

    const eventTypeHandleChange = (event) => {
        dispatch(setEventType({ eventType: event.target.value }));
    };

    const eventOpenToHandleChange = (event) => {
        dispatch(setEventOpenToType({ eventOpenTo: event.target.value }));
    };

    const eventDateHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setEventDate({ eventDate: dateValue }));
    };

    const eventPlacehandleChange = (event) => {
        dispatch(setEventPlace({ eventPlace: event.target.value }));
    };

    const registerOnTimeHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setRegisterOnTime({ registerOnTime: dateValue }));
    };

    const finalLatePaymentByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setFinalLatePaymentBy({ finalLatePaymentBy: dateValue }));
    };

    const ruleQuestionHandleChange = (event) => {
        dispatch(setRuleQuestion({ ruleQuestion: event.target.value }));
    }

    const qualifierForNationalsHandleChange = (event, newValue) => {
        dispatch(setQualifierForNationals({ qualifierForNationals: parseInt(newValue) }));
    };

    const performanceSurfaceHandleChange = (event) => {
        dispatch(setPerformanceSurface({ performanceSurface: event.target.value }));
        dispatch(setCounter({ key: 'performanceSurface', value: event.target.value.length }));
    }

    const preliminaryLineupHandleChange = (event) => {
        dispatch(setPreliminaryLineup({ preliminaryLineup: event.target.value }));
        dispatch(setCounter({ key: 'preliminaryLineup', value: event.target.value.length }));
    }

    const shortDescriptionHandleChange = (event) => {
        dispatch(setShortDescription({ shortDescription: event.target.value }));
        dispatch(setCounter({ key: 'shortDescription', value: event.target.value.length }));
    }

    const draftScheduleHandleChange = (event) => {
        dispatch(setDraftSchedule({ draftSchedule: event.target.value }));
        dispatch(setCounter({ key: 'draftSchedule', value: event.target.value.length }));
    }

    const finalScheduleHandleChange = (event) => {
        dispatch(setFinalSchedule({ finalSchedule: event.target.value }));
        dispatch(setCounter({ key: 'finalSchedule', value: event.target.value.length }));
    }
    const awardGiveawayHandleChange = (data) => {
        dispatch(setAwardGiveaway({ awardGiveaway: data }));
    }

    const parkingHandleChange = (event, newValue) => {
        dispatch(setParking({ parking: newValue }));
    };

    const onTimeHandleChange = (event) => {
        dispatch(setOnTime({ onTime: event.target.value }));
    }

    const lateHandleChange = (event) => {
        dispatch(setLate({ late: event.target.value }));
    }

    const crossCompetitorHandleChange = (event) => {
        dispatch(setCrossCompetitor({ crossCompetitor: event.target.value }));
    }

    const secondPerformancHandleChange = (event) => {
        dispatch(setSecondPerformance({ secondPerformance: event.target.value }));
    }

    const noteHandleChange = (event) => {
        dispatch(setNote({ note: event.target.value }));
        dispatch(setCounter({ key: 'notes', value: event.target.value.length }));
    }

    const genralHandleChange = (event) => {
        dispatch(setGeneral({ general: event.target.value }));
    }

    const seniorHandleChange = (event) => {
        dispatch(setSenior({ senior: event.target.value }));
    }

    const childHandleChange = (event) => {
        dispatch(setChild({ child: event.target.value }));
    }

    const childFiveUnderHandleChange = (event) => {
        dispatch(setChildFiveUnder({ childFiveUnder: event.target.value }));
    }

    const fullRefundByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setFullRefundBy({ fullRefundBy: dateValue }));
    };

    const halfRefundByHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setHalfRefundBy({ halfRefundBy: dateValue }));
    };

    const noRefundHandleChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setNoRefund({ noRefund: dateValue }));
    };

    const mapUrlHandleChange = (event) => {
        dispatch(setMapUrlValue({ mapUrl: event.target.value }));
    }

    const eventAddressHandleChange = (event) => {
        dispatch(setEventAddress({ eventAddress: event.target.value }));
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const pictureOfFacialityHandleChange = (event) => {
        savePicture(event.target.files[0]);
        getBase64(event.target.files[0])
            .then(base64 => {
                const file = base64;
                dispatch(setEventImage({
                    eventImage: file
                }));
            });
    }

    const divisionLevelPdfHandleChange = (event) => {
        saveDivisionLevelPdf(event.target.files[0]);
        dispatch(setDivisionLevelPdf({
            divisionLevelPdfName: event.target.files[0].name
        }));
    }

    const schedulePdfHandleChange = (event) => {
        saveSchedulePdf(event.target.files[0]);
        dispatch(setSchedulePdf({
            schedulePdfName: event.target.files[0].name
        }));
    }

    const resultsPdfHandleChange = (event) => {
        saveResultsPdf(event.target.files[0]);
        dispatch(setResultsPdf({
            resultsPdfName: event.target.files[0].name
        }));
    }

    const covidGuidelinesPdfHandleChange = (event) => {
        saveCovidPdf(event.target.files[0]);
        dispatch(setCovidGuidelinesPdf({
            covidGuidelinesPdfName: event.target.files[0].name
        }));
    }

    const qualificationInvitePdfHandleChange = (event) => {
        saveInvitePdf(event.target.files[0]);
        dispatch(setQualificationInvitePdf({
            qualificationInvitePdfName: event.target.files[0].name
        }));
    }

    const eventDateDecideLaterHandleChange = (event) => {
        dispatch(eventDateDecideLater({ decideLater: event.target.checked }));
    }

    const registerOnTimeDecideLaterHandleChange = (event) => {
        dispatch(registerOnTimeByDecideLater({ decideLater: event.target.checked }));
    }

    const finalLatePaymentByDecideLaterHandleChange = (event) => {
        dispatch(latePaymentByDecideLater({ decideLater: event.target.checked }));
    }

    const fullRefundByDecideLaterHandleChange = (event) => {
        dispatch(fullRefundByDecideLater({ decideLater: event.target.checked }));
    }

    const halfRefundByDecideLaterHandleChange = (event) => {
        dispatch(halfRefundByDecideLater({ decideLater: event.target.checked }));
    }

    const noRefundDecideLaterHandleChange = (event) => {
        dispatch(noRefundDecideLater({ decideLater: event.target.checked }));
    }

    const accommmodationDateDecideLaterHandleChange = (event) => {
        dispatch(accommodationDateDecideLater({ decideLater: event.target.checked }));
    }

    const accommodationTitleChange = (event) => {
        dispatch(setAccommodationTitle({ title: event.target.value }));
    }

    const accommodationContentChange = (data) => {
        dispatch(setAccommodationContent({ content: data }));
    }

    const accommodationDateChange = (newValue) => {
        const dateValue = convertDateTimeFormat(newValue).toString();
        dispatch(setAccommodationDate({ date: dateValue }));
    }

    const handleClickBack = () => {
        navigate("/events");
    };

    function convertDateTimeFormat(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        const addEventData = {
            'name': eventName,
            'type': eventType,
            'open_to': eventOpenTo,
            'event_date': eventDate,
            'place': eventPlace,
            'register_by': registerOnTime,
            'final_payment_by': finalLatePaymentBy,
            'rules_question': ruleQuestion,
            'qualifier': qualifierForNationals,
            'performance_surface': performanceSurface,
            'preliminary_lineup': preliminaryLineup,
            'short_description': shortDescription,
            'draft_schedule': draftSchedule,
            'final_schedule': finalSchedule,
            'award_giveaway': awardGiveaway,
            'youth_ontime': onTime,
            'youth_late': late,
            'youth_crosscompetitor': crossCompetitor,
            'youth_2ndperformance': secondPerformance,
            'youth_note': note,
            'spector_general': general,
            'spector_senior': senior,
            'spector_child': child,
            'spector_childunder5': childFiveUnder,
            'spector_parking': parking,
            'refund_full': fullRefundBy,
            'refund_half': halfRefundBy,
            'refund_not': noRefund,
            'map_url': mapUrl,
            'address': eventAddress,
            'image': pictureOfFacility,
            'dlrs_pdf': divisionLevelPdf,
            'schedule_pdf': schedulePdf,
            'result_pdf': resultsPdf,
            'covid_pdf': covidGuidelinesPdf,
            'invite_pdf': qualificationInvitePdf,
            'accommodation': {
                'accommodation_title': accommodationTitle,
                'accommodation_content': accommodationContent,
                'accommodation_date': accommodationDate,
            },
            'decideLater': {
                'eventDate': eventDateDecideLaterValue,
                'registerOnTime': registerOnTimeDecideLaterValue,
                'finalLatePaymentBy': finalLatePaymentByDecideLaterValue,
                'fullRefundBy': fullRefundByDecideLaterValue,
                'halfRefundBy': halfRefundByDecideLaterValue,
                'noRefund': noRefundDecideLaterValue,
                'accommodation': accommodationDateDecideLaterValue,
            }
        };
        dispatch(addEvent(addEventData));
    }



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main open={sidebarState} className='dashbaordWrapper'>
                <Topbar />
                <Paper elevation={0} sx={{ background: '#e3f2fd', padding: '1.5rem', minHeight: 'calc(100% - 64px)' }}>
                    <Card elevation={1} sx={{ padding: '2rem' }}>
                        <div
                            style={{
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                marginBottom: "1.5rem",
                            }}
                        >
                            <Typography variant="h3">Add Event</Typography>
                            <Button
                                variant="contained"
                                disableElevation
                                startIcon={<ArrowBackIcon />}
                                onClick={handleClickBack}
                            >
                                Back
                            </Button>
                        </div>
                        <Box sx={{ flexGrow: 1 }}>
                            <TabContext value={selectedTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Basic Info" value="1" />
                                        <Tab label="PDF’s" value="2" />
                                        <Tab label="Rates" value="3" />
                                        <Tab label="Accommodations" value="4" className='tabBtn' />
                                        <Tab label="Location" value="5" />
                                    </TabList>
                                </Box>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <TabPanel value="1">
                                        <Grid container spacing={4}>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="eventName" value={eventName} onChange={eventNamehandleChange} label="Event name*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="eventType">Event type</InputLabel>
                                                    <Select
                                                        labelId="eventType"
                                                        id="demo-simple-select"
                                                        value={eventType}
                                                        label="Event type"
                                                        onChange={eventTypeHandleChange}
                                                    >
                                                        <MenuItem value={0}>Championship</MenuItem>
                                                        <MenuItem value={1}>Nationals</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            disablePast={true}
                                                            label="Date*"
                                                            inputFormat="MM/dd/yyyy"
                                                            value={eventDate}
                                                            disabled={eventDateDecideLaterValue}
                                                            onChange={eventDateHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    checked={eventDateDecideLaterValue}
                                                    onChange={eventDateDecideLaterHandleChange} />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            disablePast={true}
                                                            label="Register on time by*"
                                                            inputFormat="MM/dd/yyyy"
                                                            value={registerOnTime}
                                                            disabled={registerOnTimeDecideLaterValue}
                                                            onChange={registerOnTimeHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={registerOnTimeDecideLaterHandleChange}
                                                    checked={registerOnTimeDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            disablePast={true}
                                                            label="Final late payment by*"
                                                            inputFormat="MM/dd/yyyy"
                                                            value={finalLatePaymentBy}
                                                            disabled={finalLatePaymentByDecideLaterValue}
                                                            onChange={finalLatePaymentByHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={finalLatePaymentByDecideLaterHandleChange}
                                                    checked={finalLatePaymentByDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="rulesQuestions" value={ruleQuestion} onChange={ruleQuestionHandleChange} label="Rules questions*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="eventOpenTo">Event Open to</InputLabel>
                                                    <Select
                                                        labelId="eventOpenTo"
                                                        value={eventOpenTo}
                                                        label="Event open to*"
                                                        onChange={eventOpenToHandleChange}
                                                    >
                                                        <MenuItem value={0}>Youth Squads</MenuItem>
                                                        <MenuItem value={1}>School Squads</MenuItem>
                                                        <MenuItem value={2}>Youth &amp; School Squads</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <FormControl>
                                                    <FormLabel id="qualifierForNationals">Qualifier for nationals</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="qualifierForNationals"
                                                        defaultValue={1}
                                                        name="radio-buttons-group"
                                                        onChange={qualifierForNationalsHandleChange}
                                                        value={qualifierForNationals}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={0} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField multiline rows={2} id="place" value={eventPlace} onChange={eventPlacehandleChange} label="Place*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField inputProps={{ maxLength: 500 }} multiline rows={2} id="performanceSurface" value={performanceSurface} onChange={performanceSurfaceHandleChange} label="Performance surface*" variant="outlined" fullWidth />
                                                <p className='charactorLimitTxt'>{`${performanceSurfaceCountValue} to 500 Characters`}</p>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField inputProps={{ maxLength: 500 }} id="preliminaryLineup" value={preliminaryLineup} onChange={preliminaryLineupHandleChange} label="Preliminary lineup*" multiline rows={2} variant="outlined" fullWidth />
                                                <p className='charactorLimitTxt'>{`${preliminaryLineupCountValue} to 500 Characters`}</p>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField inputProps={{ maxLength: 500 }} id="shortDescription" value={shortDescription} onChange={shortDescriptionHandleChange} label="Short description*" multiline rows={2} variant="outlined" fullWidth />
                                                    <p className='charactorLimitTxt'>{`${shortDescriptionCountValue} to 500 Characters`}</p>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField inputProps={{ maxLength: 500 }} id="draftSchedule" value={draftSchedule} onChange={draftScheduleHandleChange} label="Draft schedule*" multiline rows={2} variant="outlined" fullWidth />
                                                <p className='charactorLimitTxt'>{`${draftScheduleTextAreaCountValue} to 500 Characters`}</p>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField inputProps={{ maxLength: 500 }} id="finalSchedule" value={finalSchedule} onChange={finalScheduleHandleChange} label="Final schedule*" multiline rows={2} variant="outlined" fullWidth />
                                                    <p className='charactorLimitTxt'>{`${finalScheduleTextAreaCountValue} to 500 Characters`}</p>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <label htmlFor="pictureOfFaciality" className="file-input pictureInput">
                                                    <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>
                                                        Select Picture of Facility</Button>
                                                    <Input id="pictureOfFaciality" onChange={pictureOfFacialityHandleChange} accept='image/*'
                                                        inputProps={{ accept: 'image/*' }}
                                                        type="file" />
                                                    <label className="recomemdedText">Recommended Size : 472px X 282px</label>
                                                </label>
                                                {!!eventImage && (
                                                    <div className='fileName'><img src={eventImage} alt='' className='eventImage' /></div>
                                                )}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant='h6'>Awards &amp; Giveaways</Typography>
                                                <Editor
                                                    data={awardGiveaway}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        awardGiveawayHandleChange(data);
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Grid container spacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div className='inputFileWrapper'>
                                                    <label htmlFor="divisions" className="file-input pdfInput">
                                                        <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>Select Divisions, Levels, Rules &amp; Scoring</Button>
                                                        <Input id="divisions" accept='pdf/*'
                                                            inputProps={{ accept: 'application/pdf' }}
                                                            onChange={divisionLevelPdfHandleChange} type="file" />
                                                    </label>
                                                    <div className='fileName addEvent'><span><PictureAsPdfIcon /></span> {divisionLevelPdfName ? divisionLevelPdfName : 'File name will show here'}</div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div className='inputFileWrapper'>
                                                    <label htmlFor="schedule" className="file-input pdfInput">
                                                        <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>Select Schedule</Button>
                                                        <Input id="schedule" onChange={schedulePdfHandleChange}
                                                            inputProps={{ accept: 'application/pdf' }}
                                                            accept='pdf/*' type="file" />
                                                    </label>
                                                    <div className='fileName addEvent'><span><PictureAsPdfIcon /></span> {schedulePdfName ? schedulePdfName : 'File name will show here'}</div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div className='inputFileWrapper'>
                                                    <label htmlFor="results" className="file-input pdfInput">
                                                        <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>Select Results</Button>
                                                        <Input id="results" onChange={resultsPdfHandleChange}
                                                            inputProps={{ accept: 'application/pdf' }}
                                                            accept='pdf/*' type="file" />
                                                    </label>
                                                    <div className='fileName addEvent'><span><PictureAsPdfIcon /></span> {resultsPdfName ? resultsPdfName : 'File name will show here'}</div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div className='inputFileWrapper'>
                                                    <label htmlFor="covidGuidelines" className="file-input pdfInput">
                                                        <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>Select Covid Guidelines</Button>
                                                        <Input id="covidGuidelines" onChange={covidGuidelinesPdfHandleChange}
                                                            inputProps={{ accept: 'application/pdf' }}
                                                            accept='pdf/*' type="file" />
                                                    </label>
                                                    <div className='fileName addEvent'><span><PictureAsPdfIcon /></span> {covidGuidelinesPdfName ? covidGuidelinesPdfName : 'File name will show here'}</div>
                                                </div>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div className='inputFileWrapper'>
                                                    <label htmlFor="qualificationInvite" className="file-input pdfInput">
                                                        <Button color='secondary' variant="outlined" component="span" className='fileInputButton'>Select Qualification &amp; Invite</Button>
                                                        <Input id="qualificationInvite" onChange={qualificationInvitePdfHandleChange}
                                                            inputProps={{ accept: 'application/pdf' }}
                                                            accept='pdf/*' type="file" />
                                                    </label>
                                                    <div className='fileName addEvent'><span><PictureAsPdfIcon /></span> {qualificationInvitePdfName ? qualificationInvitePdfName : 'File name will show here'}</div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Grid container spacing={4} className='addEventRateWrapper'>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant='h6' sx={{ paddingTop: '0 !important' }}>Youth Rates (per participant)</Typography>
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="onTime" value={onTime}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={onTimeHandleChange} label="On time*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="late" value={late}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={lateHandleChange} label="Late*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="crossCompetitor"
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    value={crossCompetitor} onChange={crossCompetitorHandleChange} label="Cross competitor*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="secondPerformance"
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    value={secondPerformance} onChange={secondPerformancHandleChange} label="2nd performance*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField inputProps={{ maxLength: 500 }} id="note" value={note} onChange={noteHandleChange} label="Note*" variant="outlined" multiline rows={3} fullWidth />
                                                <p className='charactorLimitTxt'>{`${notesTextAreaCountValue} to 500 Characters`}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} className='addEventRateWrapper'>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant='h6'>Spector Rates (per person)</Typography>
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="genral" value={general}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={genralHandleChange} label="General*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="senior" value={senior}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={seniorHandleChange} label="Senior (65+)*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="child" value={child}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={childHandleChange} label="Child (6-15)*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <TextField id="childFiveUnder" value={childFiveUnder}
                                                    InputProps={{
                                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                                    }}
                                                    onChange={childFiveUnderHandleChange} label="Child 5 &amp; under*" variant="outlined" fullWidth />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <FormControl>
                                                    <FormLabel id="parking">Parking</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="parking"
                                                        defaultValue="Yes"
                                                        name="radio-buttons-group"
                                                        value={parking}
                                                        onChange={parkingHandleChange}
                                                    >
                                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        <FormControlLabel value="tba" control={<Radio />} label="TBA" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} className='addEventRateWrapper'>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant='h6'>Refund rates</Typography>
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            label="100% Refund by"
                                                            disablePast={true}
                                                            disabled={fullRefundByDecideLaterValue}
                                                            inputFormat="MM/dd/yyyy"
                                                            value={fullRefundBy}
                                                            onChange={fullRefundByHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={fullRefundByDecideLaterHandleChange}
                                                    checked={fullRefundByDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            label="50% Refund by"
                                                            disablePast={true}
                                                            inputFormat="MM/dd/yyyy"
                                                            value={halfRefundBy}
                                                            disabled={halfRefundByDecideLaterValue}
                                                            onChange={halfRefundByHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={halfRefundByDecideLaterHandleChange}
                                                    checked={halfRefundByDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            label="No refund"
                                                            disablePast={true}
                                                            inputFormat="MM/dd/yyyy"
                                                            value={noRefund}
                                                            disabled={noRefundDecideLaterValue}
                                                            onChange={noRefundHandleChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={noRefundDecideLaterHandleChange}
                                                    checked={noRefundDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <Grid container spacing={4}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField id="accommodationTitle" value={accommodationTitle}
                                                    onChange={accommodationTitleChange}
                                                    label="Accommodation Title*"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <DesktopDatePicker
                                                            disablePast={true}
                                                            label="Accommodation Date*"
                                                            inputFormat="MM/dd/yyyy"
                                                            value={accommodationDate}
                                                            disabled={accommodationDateDecideLaterValue}
                                                            onChange={accommodationDateChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Stack>
                                                </LocalizationProvider>
                                                <FormControlLabel control={<Checkbox
                                                    onChange={accommmodationDateDecideLaterHandleChange}
                                                    checked={accommodationDateDecideLaterValue}
                                                />} label="Decide later" />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Editor
                                                    data={accommodationContent}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        accommodationContentChange(data);
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="5">
                                        <Grid container spacing={4}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Grid container spacing={4}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <TextField id="mapUrl" value={mapUrl}
                                                            multiline
                                                            rows={8}
                                                            onChange={mapUrlHandleChange}
                                                            label="Map Embed Code*"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <TextField id="eventAddress" value={eventAddress} onChange={eventAddressHandleChange} label="Event address*" variant="outlined" multiline rows={2} fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <div className="locationImage">
                                                    <Typography variant="h6">How to get embed code?</Typography>
                                                    <img src={locationImg} alt="" />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <Fab disabled={addEventButtonDisabled} type="submit" color="secondary" aria-label="add" className='saveEvent' variant="extended">
                                        <AddIcon sx={{ mr: 1 }} /> Submit
                                    </Fab>
                                    {!!message && (
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Alert severity={severity}>{message}</Alert>
                                        </Grid>
                                    )}
                                    {!!errors && errors.map((error) => (
                                        <Grid item key={error} lg={12} md={12} sm={12} xs={12}>
                                            <Alert severity={severity}>{error}</Alert>
                                        </Grid>
                                    ))}
                                </form>
                            </TabContext>
                        </Box>
                    </Card>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            </Main>
        </Box>
    )
}

export default AddEvents
