import { createSlice } from '@reduxjs/toolkit'

export const topbarSlice = createSlice({
    name: 'topbar',
    initialState: {
        drawerToggle: true,
        pagesColapsed: true,
    },
    reducers: {
        drawerToggle: (state, action) => {
            state.drawerToggle = action.payload
        },
        setPagesCollapsed: (state, action) => {
            state.pagesColapsed = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { drawerToggle, setPagesCollapsed } = topbarSlice.actions

export default topbarSlice.reducer
