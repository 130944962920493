import { createSlice } from "@reduxjs/toolkit";

export const editDepartmentSlice = createSlice({
  name: "editDepartment",
  initialState: {
    editDepartment: {
      departmentName: "",
      emailID: ""
    },
    errors: [],
    severity: "error",
    message: null,
    editDepartmentButtonDisabled: false,
    loading: false,
  },
  reducers: {
    getDepartmentDataById: (state, action) => {
      return {
        ...state,
        editDepartment: state.editDepartment,
        loading: true,
      };
    },
    getDepartmentDataByIdSuccess: (state, action) => {
      return {
        ...state,
        editDepartment: {
          id: action.payload.editDepartment.id,

          departmentName: action.payload.editDepartment.data.department_name,
          emailID: action.payload.editDepartment.data.email,
        },
        loading: false,
      };
    },
    getDepartmentDataByIdFailure: (state, action) => {
      return {
        ...state,
        errors: [],
        loading: false,
      };
    },
    editDepartmentName: (state, action) => {
      return {
        ...state,
        editDepartment: {
          ...state.editDepartment,
          departmentName: action.payload.departmentName,
        },
      };
    },
    editDepartmentEmail: (state, action) => {
      return {
        ...state,
        editDepartment: {
          ...state.editDepartment,
          emailID: action.payload.emailID,
        },
      };
    },
    setEditDepartmentButtonDisabled: (state, action) => {
      return {
        ...state,
        editDepartmentButtonDisabled:
          action.payload.editDepartmentButtonDisabled,
      };
    },
    editDepartmentLoading: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    editDepartmentSuccess: (state, action) => {
      return {
        ...state,
        editDepartment: {
          ...state.editDepartment,
        },
        errors: [],
        message: action.payload.response,
        severity: "success",
        loading: false,
        editDepartmentButtonDisabled:
          action.payload.editDepartmentButtonDisabled,
      };
    },
    editDepartmentFailure: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        severity: "error",
        loading: false,
        editDepartmentButtonDisabled:
          action.payload.editDepartmentButtonDisabled,
      };
    },

    ///////////////
  },
});

export const {
  getDepartmentDataById,
  getDepartmentDataByIdFailure,
  getDepartmentDataByIdSuccess,

  editDepartmentName,
  editDepartmentEmail,
  setSubmitButtonDisabled,
  setEditDepartmentButtonDisabled,
  editDepartmentLoading,
  editDepartmentSuccess,
  editDepartmentFailure,
} = editDepartmentSlice.actions;

export default editDepartmentSlice.reducer;
