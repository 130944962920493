import { createSlice } from "@reduxjs/toolkit";

export const AboutUsSlice = createSlice({
  name: "editaboutus",
  initialState: {
    message: "",
    data: {
      id: 4,
      title: "",
      contentValue: "",
    },
    severity: "error",
    mounted: false,
    loading: false,
    errors: [],
  },
  reducers: {
    saveAboutUsError: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        loading: false,
        severity: "error",
      };
    },
    saveAboutUsSuccess: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        data: {
          title: action.payload.data.title,
          contentValue: !action.payload.data.content ? '' : action.payload.data.content,
        },
        severity: "success",
        loading: false,
        errors: [],
      };
    },
    setAboutUs: (state, action) => {
      return {
        ...state,
        data: {
          title: action.payload.title,
          contentValue: !action.payload.content ? '' : action.payload.content,
        },
        mounted: true,
        loading: false,
      };
    },
    setTitle: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          title: action.payload.title,
        },
      };
    },
    setContent: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          contentValue: action.payload.contentValue,
        },
      };
    },
    saveAboutUs: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getAboutUs: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
});

export const {
  saveAboutUsSuccess,
  saveAboutUsError,
  setAboutUs,
  saveAboutUs,
  getAboutUs,
  setTitle,
  setContent,
} = AboutUsSlice.actions;

export default AboutUsSlice.reducer;
