import { createSlice } from '@reduxjs/toolkit'

export const editEventSlice = createSlice({
    name: 'editEvent',
    initialState: {
        event: {
            eventName: '',
            eventType: 1,
            eventDate: convertDateTimeFormat(new Date()).toString(),
            eventPlace: '',
            registerOnTime: convertDateTimeFormat(new Date()).toString(),
            finalLatePaymentBy: convertDateTimeFormat(new Date()).toString(),
            ruleQuestion: '',
            eventOpenTo: 0,
            qualifierForNationals: 1,
            performanceSurface: '',
            preliminaryLineup: '',
            shortDescription: '',
            draftSchedule: '',
            finalSchedule: '',
            awardGiveaway: '',
            onTime: 0,
            late: 0,
            crossCompetitor: 0,
            secondPerformance: 0,
            note: '',
            general: 0,
            senior: 0,
            child: 0,
            childFiveUnder: 0,
            parking: 'yes',
            fullRefundBy: convertDateTimeFormat(new Date()).toString(),
            halfRefundBy: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            mapUrl: '',
            eventAddress: '',
            pictureOfFacility: '',
            divisionLevelPdfName: '',
            schedulePdfName: '',
            resultsPdfName: '',
            covidGuidelinesPdfName: '',
            qualificationInvitePdfName: '',
            divisionLevelPdfUrl: null,
            schedulePdfUrl: null,
            resultsPdfUrl: null,
            covidGuidelinesPdfUrl: null,
            qualificationInvitePdfUrl: null,
            eventImage: null,
            accomodation: {
                accomodationId: 0,
                accommodationTitle: '',
                accommodationContent: '',
                accommodationDate: convertDateTimeFormat(new Date()).toString(),
            }
        },
        selectedTabValue: '1',
        errors: [],
        severity: 'error',
        message: null,
        loading: false,
        decideLater: {
            eventDate: false,
            registerOnTime: false,
            finalLatePaymentBy: false,
            fullRefundBy: false,
            halfRefundBy: false,
            noRefund: false,
            accommodationDate: false
        },
        counter: {
            performanceSurfaceLength: 0,
            preliminaryLineupLength: 0,
            shortDescriptionLength: 0,
            draftScheduleLength: 0,
            finalScheduleLength: 0,
            notesLength: 0,
            awardsGiveawaysLength: 0,
        }
    },
    reducers: {

        getEventDataById: (state, action) => {
            return {
                ...state,
                event: state.event,
                loading: true
            }
        },
        getEventDataByIdSuccess: (state, action) => {
            return {
                ...state,
                event: {
                    id: action.payload.event.id,
                    eventName: action.payload.event.name,
                    eventType: action.payload.event.type,
                    eventDate: action.payload.event.event_date ? convertDateTimeFormat(action.payload.event.event_date).toString() : convertDateTimeFormat(new Date()).toString(),
                    eventPlace: action.payload.event.place,
                    registerOnTime: action.payload.event.register_by ? convertDateTimeFormat(action.payload.event.register_by).toString() : convertDateTimeFormat(new Date()).toString(),
                    finalLatePaymentBy: action.payload.event.final_payment_by ? convertDateTimeFormat(action.payload.event.final_payment_by).toString() : convertDateTimeFormat(new Date()).toString(),
                    ruleQuestion: action.payload.event.rules_question,
                    eventOpenTo: action.payload.event.open_to,
                    qualifierForNationals: action.payload.event.qualifier,
                    performanceSurface: action.payload.event.performance_surface,
                    preliminaryLineup: action.payload.event.preliminary_lineup,
                    shortDescription: action.payload.event.short_description,
                    draftSchedule: action.payload.event.draft_schedule,
                    finalSchedule: action.payload.event.final_schedule,
                    awardGiveaway: action.payload.event.award_giveaway,
                    onTime: action.payload.event.youth_ontime,
                    late: action.payload.event.youth_late,
                    crossCompetitor: action.payload.event.youth_crosscompetitor,
                    secondPerformance: action.payload.event.youth_2ndperformance,
                    note: action.payload.event.youth_note,
                    general: action.payload.event.spector_general,
                    senior: action.payload.event.spector_senior,
                    child: action.payload.event.spector_child,
                    childFiveUnder: action.payload.event.spector_childunder5,
                    parking: action.payload.event.spector_parking,
                    fullRefundBy: action.payload.event.fullRefundBy ? convertDateTimeFormat(action.payload.event.refund_full).toString() : convertDateTimeFormat(new Date()).toString(),
                    halfRefundBy: action.payload.event.halfRefundBy ? convertDateTimeFormat(action.payload.event.refund_half).toString() : convertDateTimeFormat(new Date()).toString(),
                    noRefund: action.payload.event.noRefund ? convertDateTimeFormat(action.payload.event.refund_not).toString() : convertDateTimeFormat(new Date()).toString(),
                    mapUrl: action.payload.event.map_url,
                    eventAddress: action.payload.event.address,
                    divisionLevelPdfName: action.payload.event.dlrs_pdf,
                    schedulePdfName: action.payload.event.schedule_pdf,
                    resultsPdfName: action.payload.event.result_pdf,
                    covidGuidelinesPdfName: action.payload.event.covid_pdf,
                    qualificationInvitePdfName: action.payload.event.invite_pdf,
                    eventImage: action.payload.event.image,
                    divisionLevelPdfUrl: action.payload.event.url_dlrs_pdf,
                    schedulePdfUrl: action.payload.event.url_schedule_pdf,
                    resultsPdfUrl: action.payload.event.url_result_pdf,
                    covidGuidelinesPdfUrl: action.payload.event.url_covid_pdf,
                    qualificationInvitePdfUrl: action.payload.event.url_invite_pdf,
                    accomodation: {
                        accommodationTitle: action.payload.event.accommodations[0]?.title,
                        accommodationContent: action.payload.event.accommodations[0]?.content,
                        accommodationDate: action.payload.event.accommodations[0]?.date ?
                            convertDateTimeFormat(action.payload.event.accommodations[0].date).toString()
                            : convertDateTimeFormat(new Date()).toString(),
                    }
                },
                loading: false,
                decideLater: {
                    eventDate: action.payload.event.event_date !== null ? false : true,
                    registerOnTime: action.payload.event.register_by !== null ? false : true,
                    finalLatePaymentBy: action.payload.event.final_payment_by !== null ? false : true,
                    fullRefundBy: action.payload.event.refund_full !== null ? false : true,
                    halfRefundBy: action.payload.event.refund_half !== null ? false : true,
                    noRefund: action.payload.event.refund_not !== null ? false : true,
                    accommodationDate: action.payload.event.accommodations[0].date !== null ? false : true
                },
                counter: {
                    performanceSurfaceLength: action.payload.event.performance_surface?.length,
                    preliminaryLineupLength: action.payload.event.preliminary_lineup?.length,
                    shortDescriptionLength: action.payload.event.short_description?.length,
                    draftScheduleLength: action.payload.event.draft_schedule?.length,
                    finalScheduleLength: action.payload.event.final_schedule?.length,
                    notesLength: action.payload.event.youth_note?.length,
                    awardsGiveawaysLength: action.payload.event.award_giveaway?.length,
                }
            }
        },
        getEventDataByIdFailure: (state, action) => {
            return {
                ...state,
                errors: [],
                loading: false
            }
        },
        editSelectedTabValue: (state, action) => {
            return {
                ...state,
                selectedTabValue: action.payload.selectedTabValue
            }
        },
        editEventName: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventName: action.payload.eventName
                }
            }
        },
        editEventType: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventType: action.payload.eventType
                }
            }
        },
        editEventOpenToType: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventOpenTo: action.payload.eventOpenTo
                }
            }
        },
        editEventDate: (state, action) => {
            console.log(action.payload.eventDate);
            return {
                ...state,
                event: {
                    ...state.event,
                    eventDate: action.payload.eventDate,
                }
            }
        },
        editEventPlace: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventPlace: action.payload.eventPlace
                }
            }
        },
        editRegisterOnTime: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    registerOnTime: action.payload.registerOnTime
                }
            }
        },
        editFinalLatePaymentBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    finalLatePaymentBy: action.payload.finalLatePaymentBy
                }
            }
        },
        editRuleQuestion: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    ruleQuestion: action.payload.ruleQuestion
                }
            }
        },
        editQualifierForNationals: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    qualifierForNationals: action.payload.qualifierForNationals
                }
            }
        },
        editPerformanceSurface: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    performanceSurface: action.payload.performanceSurface
                }
            }
        },
        editPreliminaryLineup: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    preliminaryLineup: action.payload.preliminaryLineup
                }
            }
        },
        editShortDescription: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    shortDescription: action.payload.shortDescription
                }
            }
        },
        editDraftSchedule: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    draftSchedule: action.payload.draftSchedule
                }
            }
        },
        editFinalSchedule: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    finalSchedule: action.payload.finalSchedule
                }
            }
        },
        editAwardGiveaway: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    awardGiveaway: action.payload.awardGiveaway
                }
            }
        },
        editOnTime: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    onTime: action.payload.onTime
                }
            }
        },
        editLate: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    late: action.payload.late
                }
            }
        },
        editCrossCompetitor: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    crossCompetitor: action.payload.crossCompetitor
                }
            }
        },
        editSecondPerformance: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    secondPerformance: action.payload.secondPerformance
                }
            }
        },
        editNote: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    note: action.payload.note
                }
            }
        },
        editGeneral: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    general: action.payload.general
                }
            }
        },
        editSenior: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    senior: action.payload.senior
                }
            }
        },
        editChild: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    child: action.payload.child
                }
            }
        },
        editChildFiveUnder: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    childFiveUnder: action.payload.childFiveUnder
                }
            }
        },
        editParking: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    parking: action.payload.parking
                }
            }
        },
        editFullRefundBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    fullRefundBy: action.payload.fullRefundBy
                }
            }
        },
        editHalfRefundBy: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    halfRefundBy: action.payload.halfRefundBy
                }
            }
        },
        editNoRefund: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    noRefund: action.payload.noRefund
                }
            }
        },
        editMapUrlValue: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    mapUrl: action.payload.mapUrl
                }
            }
        },
        editEventAddress: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventAddress: action.payload.eventAddress
                }
            }
        },
        editEventImage: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    eventImage: action.payload.eventImage
                }
            }
        },
        editDivisionLevelPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    divisionLevelPdfName: action.payload.divisionLevelPdfName
                }
            }
        },
        editSchedulePdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    schedulePdfName: action.payload.schedulePdfName
                }
            }
        },
        editResultsPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    resultsPdfName: action.payload.resultsPdfName
                }
            }
        },
        editCovidGuidelinesPdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    covidGuidelinesPdfName: action.payload.covidGuidelinesPdfName
                }
            }
        },
        editQualificationInvitePdf: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    qualificationInvitePdfName: action.payload.qualificationInvitePdfName
                }
            }
        },
        editEventDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    eventDate: action.payload.decideLater
                }
            }
        },
        editLatePaymentByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    finalLatePaymentBy: action.payload.decideLater
                }
            }
        },
        editRegisterOnTimeByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    registerOnTime: action.payload.decideLater
                }
            }
        },
        editFullRefundByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    fullRefundBy: action.payload.decideLater
                }
            }
        },
        editHalfRefundByDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    halfRefundBy: action.payload.decideLater
                }
            }
        },
        editNoRefundDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    noRefund: action.payload.decideLater
                }
            }
        },
        editAccommodationDateDecideLater: (state, action) => {
            return {
                ...state,
                decideLater: {
                    ...state.decideLater,
                    accommodationDate: action.payload.decideLater
                }
            }
        },
        editEventLoading: (state, action) => {
            return {
                ...state,
                loading: true,
            }
        },
        editEventSuccess: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                },
                errors: [],
                loading: false,
                message: action.payload.response,
                severity: 'success'
            }
        },
        editEventFailure: (state, action) => {
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.error,
                loading: false,
                severity: 'error'
            }
        },
        editAccommodationTitle: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationTitle: action.payload.title
                    }
                }
            }
        },
        editAccommodationContent: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationContent: action.payload.content
                    }
                }
            }
        },
        editAccommodationDate: (state, action) => {
            return {
                ...state,
                event: {
                    ...state.event,
                    accomodation: {
                        ...state.event.accomodation,
                        accommodationDate: action.payload.date
                    }
                }
            }
        },
        setCounter: (state, action) => {
            switch (action.payload.key) {
                case 'performanceSurface':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            performanceSurfaceLength: action.payload.value,
                        }
                    };
                case 'preliminaryLineup':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            preliminaryLineupLength: action.payload.value,
                        }
                    };
                case 'shortDescription':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            shortDescriptionLength: action.payload.value,
                        }
                    };
                case 'draftSchedule':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            draftScheduleLength: action.payload.value,
                        }
                    };
                case 'finalSchedule':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            finalScheduleLength: action.payload.value,
                        }
                    };
                case 'notes':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            notesLength: action.payload.value,
                        }
                    };
                case 'awardsGiveaways':
                    return {
                        ...state,
                        counter: {
                            ...state.counter,
                            awardsGiveawaysLength: action.payload.value,
                        }
                    };
                default:
                    return {
                        ...state
                    }
            }
        },
    }
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

// Action creators are generated for each case reducer function
export const {
    editSelectedTabValue,
    editEventName,
    editEventType,
    editEventOpenToType,
    editEventDate,
    editEventPlace,
    editRegisterOnTime,
    editFinalLatePaymentBy,
    editRuleQuestion,
    editQualifierForNationals,
    editPerformanceSurface,
    editPreliminaryLineup,
    editShortDescription,
    editDraftSchedule,
    editFinalSchedule,
    editAwardGiveaway,
    editOnTime,
    editLate,
    editCrossCompetitor,
    editSecondPerformance,
    editNote,
    editGeneral,
    editSenior,
    editChild,
    editChildFiveUnder,
    editParking,
    editFullRefundBy,
    editHalfRefundBy,
    editNoRefund,
    editMapUrlValue,
    editEventAddress,
    editEventImage,
    editDivisionLevelPdf,
    editSchedulePdf,
    editResultsPdf,
    editCovidGuidelinesPdf,
    editQualificationInvitePdf,
    editEventSuccess,
    editEventFailure,
    getEventDataById,
    editEventLoading,
    getEventDataByIdSuccess,
    getEventDataByIdFailure,
    editAccommodationTitle,
    editAccommodationContent,
    editAccommodationDate,
    editAccommodationDateDecideLater,
    editEventDateDecideLater,
    editLatePaymentByDecideLater,
    editRegisterOnTimeByDecideLater,
    editFullRefundByDecideLater,
    editNoRefundDecideLater,
    editHalfRefundByDecideLater,
    setCounter
} = editEventSlice.actions

export default editEventSlice.reducer