import { createSlice } from "@reduxjs/toolkit";

export const addSlideContentPageSlice = createSlice({
  name: "addSlideContentPage",
  initialState: {
    slide: {
      slideLink: "",
      slideImage: "",
      slideDisplayOrder: "",
      addSelectedPages: [],
    },
    errors: [],
    severity: "error",
    message: null,
    addSlideButtonDisabled: false,
    loading: false,
  },
  reducers: {
    setSlideLink: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideLink: action.payload.slideLink,
        },
      };
    },
    setSlideImage: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideImage: action.payload.slideImage,
        },
      };
    },
    setSlideDisplayOrder: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          slideDisplayOrder: action.payload.slideDisplayOrder,
        },
      };
    },

    setAddSlideButtonDisabled: (state, action) => {
      return {
        ...state,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    addSlideLoading: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    addSlideSuccess: (state, action) => {
      return {
        ...state,
        slide: {
          slideLink: "",
          slideImage: "",
          slideDisplayOrder: "",
        },
        errors: [],
        message: action.payload.response,
        severity: "success",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    addSlideFailure: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        severity: "error",
        loading: false,
        addSlideButtonDisabled: action.payload.addSlideButtonDisabled,
      };
    },
    addSelectedPages: (state, action) => {
      return {
        ...state,
        slide: {
          ...state.slide,
          addSelectedPages: action.payload.addSelectedPages,
        },
      };
    },
    ///////////////
  },
});

export const {
  setSlideLink,
  setSlideImage,
  setSlideDisplayOrder,
  setAddSlideButtonDisabled,
  addSlideLoading,
  addSlideSuccess,
  addSlideFailure,
  addSelectedPages,
} = addSlideContentPageSlice.actions;

export default addSlideContentPageSlice.reducer;
