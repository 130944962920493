import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  Card,
  Grid,
  TextField,
  Button,
  Fab,
  Alert,
  Backdrop,
  CircularProgress,
  FormControl,
  Input,
} from "@mui/material";
import { Box } from "@mui/system";
import Topbar from "../topbar/topbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../sidebar/sidebar";
import { Main } from "../main/main";
import { useNavigate, useParams } from "react-router-dom";
import {
  editSlideHeading,
  editSlideDescription,
  editSlideImage,
  editSlideDisplayOrder,
} from "./editslideSlice";

import AddIcon from "@mui/icons-material/Add";
import { editSlide, getSlide } from "./editslides.functions";

export function Editslide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const slideHeading = useSelector(
    (state) => state.editSlide.slide.slideHeading
  );
  const slideDescription = useSelector(
    (state) => state.editSlide.slide.slideDescription
  );
  const slideImage = useSelector((state) => state.editSlide.slide.slideImage);

  const slideDisplayOrder = useSelector(
    (state) => state.editSlide.slide.slideDisplayOrder
  );

  const addSlideButtonDisabled = useSelector(
    (state) => state.editSlide.addSlideButtonDisabled
  );


  ///////////Error and view handles ///////////////
  const loading = useSelector((state) => state.editSlide.loading);
  const message = useSelector((state) => state.editSlide.message);
  const severity = useSelector((state) => state.editSlide.severity);
  const errors = useSelector((state) => state.editSlide.errors);

  const slideDisplayOrderhandleChange = (event) => {
    dispatch(editSlideDisplayOrder({ slideDisplayOrder: event.target.value }));
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  //////////////// On change handles/////////////////
  const slideHeadinghandleChange = (event) => {
    dispatch(editSlideHeading({ slideHeading: event.target.value }));
  };
  const slideDescriptionHandleChange = (event) => {
    dispatch(editSlideDescription({ slideDescription: event.target.value }));
  };

  // Attachments
  const [pictureOfSlide, savePicture] = useState(null);

  const [slideId, saveSlideId] = useState(0);
  const params = useParams();

  const pictureOfSlideHandleChange = (event) => {
    savePicture(event.target.files[0]);
    getBase64(event.target.files[0]).then((base64) => {
      const file = base64;

      dispatch(
        editSlideImage({
          slideImage: file,
        })
      );
    });
  };

  useEffect(() => {
    if (params) {
      const slideId = params.id;
      saveSlideId(slideId);
      dispatch(getSlide(slideId));
    }
  }, [dispatch, params]);

  const handleClickBack = () => {
    navigate("/homepage-slideshow");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editSlideData = {
      heading: slideHeading,
      description: slideDescription,
      image: pictureOfSlide,
      display_order: slideDisplayOrder,
    };
    dispatch(editSlide(editSlideData, slideId));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">Edit Home Page Slide</Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      startIcon={<ArrowBackIcon />}
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2} item>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="heading"
                          value={slideHeading}
                          onChange={slideHeadinghandleChange}
                          label="Heading"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label
                          htmlFor="pictureOfSlide"
                          className="file-input pictureInput"
                        >
                          <Button
                            color="secondary"
                            variant="outlined"
                            component="span"
                            className="fileInputButton"
                          >
                            Select Picture of Slide
                          </Button>
                          <Input
                            id="pictureOfSlide"
                            onChange={pictureOfSlideHandleChange}
                            accept="image/*"
                            type="file"
                          />
                          <label className="recomemdedText inline">Recommended Size : 1920px X 572px</label>
                        </label>
                        {!!slideImage && (
                          <div className="fileName">
                            <img src={slideImage} alt="" className="slideImage" />
                          </div>
                        )}
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="description"
                            value={slideDescription}
                            onChange={slideDescriptionHandleChange}
                            label="Description*"
                            multiline
                            rows={2}
                            variant="outlined"
                            fullWidth
                          />
                          <p className="charactorLimitTxt">
                            0 to 150 Characters
                          </p>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="displayorder"
                          value={slideDisplayOrder}
                          onChange={slideDisplayOrderhandleChange}
                          label="Display Order"
                          variant="outlined"
                        />
                      </Grid>
                      <Fab
                        disabled={addSlideButtonDisabled}
                        type="submit"
                        color="secondary"
                        aria-label="add"
                        className="saveEvent"
                        variant="extended"
                      >
                        <AddIcon sx={{ mr: 1 }} /> Submit
                      </Fab>
                      {!!message && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>{message}</Alert>
                        </Grid>
                      )}
                      {!!errors &&
                        errors.map((error) => (
                          <Grid
                            item
                            key={error}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Alert severity={severity}>{error}</Alert>
                          </Grid>
                        ))}
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default Editslide;
