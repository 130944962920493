import { createSlice } from '@reduxjs/toolkit'

export const partnerRecordSlice = createSlice({
    name: 'partner',
    initialState: {
        partnerrequests: [],
        mounted: false,
        rowsPerPage: 10,
        page: 0,
        error: '',
        loading: false,
        recordDeleted: false,
        totalCount: 10,
        csvData: [],
    },
    reducers: {
        getPartnerRequestRecordsLoading: (state, action) => {
            return {
                ...state,
                loading: true,
                mounted: true
            }
        },
        getPartnerRequestRecordsSuccess: (state, action) => {
            return {
                ...state,
                partnerrequests: action.payload.partnerrequests,
                totalCount: action.payload.totalCount,
                loading: false,
                mounted: true
            }
        },
        getPartnerRequestRecordsFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.error,
                mounted: true
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setRowsPerPage: (state, action) => {
            return {
                ...state,
                rowsPerPage: action.payload
            }
        },
        deletePartnerRequestSuccess: (state, action) => {
            return {
                ...state,
                partnerrequests: state.partnerrequests.filter(teamrequest => teamrequest.id === !action.payload.id),
                recordDeleted: true
            }
        },
        setRecordDeleted: (state, action) => {
            return {
                ...state,
                recordDeleted: false
            }
        },
        exportPartnerRequestSuccess: (state, action) => {
            return {
                ...state,
                exportSuccess: true,
                csvData: action.payload.response
            }
        },
        exportPartnerRequestFailure: (state, action) => {
            return {
                ...state,
                exportError: action.payload.error
            }
        }
    }
})

export const { getPartnerRequestRecordsLoading, setRecordDeleted, deletePartnerRequestSuccess, getPartnerRequestRecordsSuccess,
    getPartnerRequestRecordsFailure, setPage, setRowsPerPage, exportPartnerRequestSuccess, exportPartnerRequestFailure } = partnerRecordSlice.actions

export default partnerRecordSlice.reducer