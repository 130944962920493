import { createSlice } from '@reduxjs/toolkit'

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
        message: '',
        severity: 'error'
    },
    reducers: {
        saveResetPasswordError: (state, action) => {
            return {
                ...state,
                message: action.payload,
                severity: 'error'
            }
        },
        saveResetPasswordSuccess: (state, action) => {
            return {
                ...state,
                message: action.payload,
                severity: 'success'
            }
        }
    }
})

export const { saveResetPasswordSuccess, saveResetPasswordError } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer