import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Delete, Edit } from '@mui/icons-material'
import {
    Box, Button, CssBaseline, Paper,
    TableContainer, Table, TableRow, TableCell, TableBody, IconButton, TablePagination,
    tableCellClasses, TableHead, useMediaQuery, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Alert, Snackbar, Backdrop, CircularProgress, TextField, Typography, InputAdornment
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Main } from '../../main/main'
import Sidebar from '../../sidebar/sidebar'
import Topbar from '../../topbar/topbar'
import DeleteIcon from '@mui/icons-material/Delete';
import { setPage, setRecordDeleted, setRowsPerPage } from './listeventsSlice';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from 'react-redux';
import { getEventRecords, deleteEvent, exportEvent } from '../events.function'
import CsvDownloader from 'react-csv-downloader';
import up from "../../../assets/up.svg";
import down from "../../../assets/down.svg";


export function ListEvents() {
    let navigate = useNavigate();
    const events = useSelector(state => state?.events?.events);
    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const page = useSelector(state => state.events.page);
    const rowsPerPage = useSelector(state => state.events.rowsPerPage);
    const error = useSelector(state => state.events.error);
    const recordDeleted = useSelector(state => state.events.recordDeleted);
    const totalCount = useSelector(state => state.events.totalCount);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const loading = useSelector(state => state.events.loading);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 'bold',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '0.5rem 1rem',
            fontSize: 14,
        },
    }));
    const csvData = useSelector(state => state?.events?.csvData?.events);
    const csvColumns = useSelector(state => state?.events?.csvData?.columns);

    const [sortoptions, setSortOptions] = useState({
        sortby: "",
        orderby: "",
    });
    const { sortby, orderby } = sortoptions;
    const [searchinput, setSearchInput] = useState("");
    const [doSearch, setDoSearch] = useState("");

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getEventRecords(page, rowsPerPage));
        dispatch(exportEvent());
    }, [dispatch, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(page, event.target.value);
        dispatch(setRowsPerPage(event.target.value));
        dispatch(setPage(0));

    };

    const [open, setOpen] = useState(false);
    const [dialogDeleteId, setDialogDeleteId] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const confirmDialogOpen = (id) => {
        setOpen(true);
        setDialogDeleteId(id);
    };

    const confirmDialogClose = (dialogAction) => {
        if (dialogAction === 'Yes') {
            dispatch(deleteEvent(dialogDeleteId, page, rowsPerPage));
        }
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setRecordDeleted({
            recordDeleted: false
        }));
    };


    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                CLOSE
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const sortRecords = (sortfield) => {
        let newsort = "";
        let neworder = "";
        if (sortoptions.sortby === sortfield) {
            newsort = sortfield;
            if (sortoptions.orderby === "asc") {
                neworder = "desc";
            }
            if (sortoptions.orderby === "desc") {
                neworder = "asc";
            }
        } else {
            newsort = sortfield;
            neworder = "asc";
        }
        setSortOptions((sortoptions) => ({
            ...sortoptions,
            ["sortby"]: newsort,
            ["orderby"]: neworder,
        }));
    };

    useEffect(() => {
        if (sortoptions.sortby != "" && sortoptions.orderby != "") {
            console.log("sortoptions", sortoptions);
            dispatch(
                getEventRecords(
                    page,
                    rowsPerPage,
                    sortoptions.sortby,
                    sortoptions.orderby
                )
            );
        }
    }, [sortoptions]);

    useEffect(() => {
        dispatch(
            getEventRecords(
                page,
                rowsPerPage,
                sortoptions.sortby,
                sortoptions.orderby,
                searchinput
            )
        );
    }, [doSearch]);

    const handleClickClearSearch = () => {
        setSearchInput('');
        setDoSearch(new Date().getTime());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDoSearch(new Date().getTime());
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main open={sidebarState} className='dashbaordWrapper'>
                <Topbar />
                <Paper sx={{ background: '#e3f2fd', height: 'calc(100% - 64px)', padding: '1.5rem' }}>
                    <div className='topBarButtonWrapper'>
                        <Button
                            onClick={() => {
                                navigate('/events/add-event', { replace: true });
                            }}
                            variant="contained">Add Event</Button>

                        <CsvDownloader className="customButton MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-1eikyat-MuiButtonBase-root-MuiButton-root"
                            filename="events"
                            extension=".csv"
                            wrapColumnChar=""
                            columns={csvColumns}
                            datas={csvData}
                            text="Export CSV" />

                        {!!error && (
                            <Alert severity="error">{error}</Alert>
                        )}
                    </div>
                    <div style={{ float: 'right' }}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <TextField id="searchinput" label="Search" variant="outlined" fullWidth={false} value={searchinput}
                                /*onChange={(e) => doSearch(e.nativeEvent.target.value)}*/
                                onChange={(e) => setSearchInput(e.nativeEvent.target.value)}
                                size="small"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {!!searchinput && (
                                            <IconButton
                                                aria-label="Clear Search"
                                                onClick={handleClickClearSearch}
                                                edge="end"
                                            >
                                                <CloseIcon></CloseIcon>
                                            </IconButton>
                                        )}
                                    </InputAdornment>,
                                }} />
                            <IconButton
                                aria-label="Search"
                                onClick={(e) => setDoSearch(new Date().getTime())}
                                edge="end"
                            >
                                <SearchIcon></SearchIcon>
                            </IconButton>
                        </form>
                    </div>
                    <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>
                        Events
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ padding: '12px 16px' }}
                                        onClick={() => sortRecords("name")}>Event
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "name" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "name" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        style={{ padding: '12px 16px' }}
                                        onClick={() => sortRecords("event_date")}>Date
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "event_date" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "event_date" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    {/* <StyledTableCell style={{ padding: '12px 16px' }}>Open To</StyledTableCell> */}
                                    <StyledTableCell
                                        style={{ padding: '12px 16px' }}
                                        onClick={() => sortRecords("place")}>Place
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "place" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "place" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell style={{ padding: '12px 16px' }}
                                        align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!events && events
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                            >
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell>{
                                                    row.event_date ? new Date(row.event_date).toLocaleDateString('en-US', dateOptions) : 'TBA'}
                                                </StyledTableCell>
                                                {/* <StyledTableCell>
                                                    {row.open_to === 0 ? 'Youth Squads' : ''}
                                                    {row.open_to === 1 ? 'School Squads' : ''}
                                                    {row.open_to === 2 ? 'Youth & School Squads' : ''}
                                                </StyledTableCell> */}
                                                <StyledTableCell>{row.place}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <IconButton color="primary" aria-label="edit"
                                                        sx={{ marginRight: '0.25rem' }}
                                                        onClick={() => {
                                                            console.log('edit', row.id);
                                                            navigate('/events/edit-event/' + row.id, { replace: true });
                                                        }}
                                                    >
                                                        <Edit color="primary" />
                                                    </IconButton>
                                                    <IconButton aria-label="delete"
                                                        onClick={() => {
                                                            confirmDialogOpen(row.id);
                                                        }}
                                                    >
                                                        <Delete sx={{ color: '#d32f2f' }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!loading && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={() => { confirmDialogClose('No') }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" className='dialogHeadWithIcon'>
                            <DeleteIcon /> {"Delete Event"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are You sure you want to delete this event?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div className='dailogBtnActWrapper'>
                                <Button autoFocus onClick={() => { confirmDialogClose('No') }} variant="outlined" color='primary'>No</Button>
                                <Button onClick={() => { confirmDialogClose('Yes') }} autoFocus variant="contained" color='primary'>Yes</Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={recordDeleted}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        action={action}
                        anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                        }}
                    >
                        <Alert severity="success" variant="filled">Event deleted successfully</Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            </Main>
        </Box >
    )
}

export default ListEvents