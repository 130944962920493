import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../sidebar/sidebar';
import { Main } from '../../main/main';
import { CssBaseline, Paper, Grid, Button, Typography, TextField, InputAdornment, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, TablePagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Topbar from '../../topbar/topbar';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import { Delete, Edit } from '@mui/icons-material'
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import CsvDownloader from 'react-csv-downloader';
import { useNavigate } from 'react-router-dom';
import { deleteConference, exportConference, getConferenceRecords } from '../conferences.function';
import { setPage, setRecordDeleted, setRowsPerPage } from './listConferencesSlice';
import up from "../../../assets/up.svg";
import down from "../../../assets/down.svg";

export function ListConferences() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const conferences = useSelector(state => state?.conferences?.conferences);
    const totalCount = useSelector((state) => state.conferences.totalCount);
    const page = useSelector(state => state.conferences.page);
    const rowsPerPage = useSelector(state => state.conferences.rowsPerPage);
    const error = useSelector(state => state.conferences.error);
    const [dialogDeleteId, setDialogDeleteId] = useState(0);
    const recordDeleted = useSelector(state => state.conferences.recordDeleted);
    const csvData = useSelector(state => state?.conferences?.csvData?.conferences);
    const csvColumns = useSelector(state => state?.conferences?.csvData?.columns);
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const [sortoptions, setSortOptions] = useState({
        sortby: "",
        orderby: "",
    });
    const { sortby, orderby } = sortoptions;
    const [searchinput, setSearchInput] = useState("");
    const [doSearch, setDoSearch] = useState("");


    useEffect(() => {
        dispatch(getConferenceRecords(page, rowsPerPage));
        dispatch(exportConference());
    }, [dispatch, page, rowsPerPage]);


    const [open, setOpen] = useState(false);


    const handleChangePage = (event, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        console.log(page, event.target.value);
        dispatch(setRowsPerPage(event.target.value));
        dispatch(setPage(0));
    };

    const confirmDialogOpen = (id) => {
        setOpen(true);
        setDialogDeleteId(id);
    };

    const confirmDialogClose = (dialogAction) => {
        if (dialogAction === 'Yes') {
            dispatch(deleteConference(dialogDeleteId, page, rowsPerPage));
        }
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setRecordDeleted({
            recordDeleted: false
        }));
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 'bold',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '0.5rem 1rem',
            fontSize: 14,
        },
    }));

    const sortRecords = (sortfield) => {
        let newsort = "";
        let neworder = "";
        if (sortoptions.sortby === sortfield) {
            newsort = sortfield;
            if (sortoptions.orderby === "asc") {
                neworder = "desc";
            }
            if (sortoptions.orderby === "desc") {
                neworder = "asc";
            }
        } else {
            newsort = sortfield;
            neworder = "asc";
        }
        setSortOptions((sortoptions) => ({
            ...sortoptions,
            ["sortby"]: newsort,
            ["orderby"]: neworder,
        }));
    };

    useEffect(() => {
        if (sortoptions.sortby !== "" && sortoptions.orderby !== "") {
            dispatch(
                getConferenceRecords(
                    page,
                    rowsPerPage,
                    sortoptions.sortby,
                    sortoptions.orderby
                )
            );
        }
    }, [sortoptions]);

    useEffect(() => {
        dispatch(
            getConferenceRecords(
                page,
                rowsPerPage,
                sortoptions.sortby,
                sortoptions.orderby,
                searchinput
            )
        );
    }, [doSearch]);

    const handleClickClearSearch = () => {
        setSearchInput('');
        setDoSearch(new Date().getTime());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDoSearch(new Date().getTime());
    };


    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                CLOSE
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Sidebar />
                <Main open={sidebarState} className='dashbaordWrapper'>
                    <Topbar />
                    <Paper sx={{ background: '#e3f2fd', height: 'calc(100% - 64px)', padding: '1.5rem' }}>
                        <Grid container spacing={4}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className='topBarButtonWrapper'>
                                    <Button variant="contained" onClick={() => {
                                        navigate('/conferences/add-conference', { replace: true });
                                    }}>Add Conference</Button>
                                    <CsvDownloader className="customButton MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-1eikyat-MuiButtonBase-root-MuiButton-root"
                                        filename="conferences"
                                        extension=".csv"
                                        wrapColumnChar=""
                                        columns={csvColumns}
                                        datas={csvData}
                                        text="Export CSV" />
                                    {!!error && (
                                        <Alert severity="error">{error}</Alert>
                                    )}
                                </div>
                                <div style={{ float: 'right' }}>
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <TextField id="searchinput" label="Search" variant="outlined" fullWidth={false} value={searchinput}
                                            /*onChange={(e) => doSearch(e.nativeEvent.target.value)}*/
                                            onChange={(e) => setSearchInput(e.nativeEvent.target.value)}
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    {!!searchinput && (
                                                        <IconButton
                                                            aria-label="Clear Search"
                                                            onClick={handleClickClearSearch}
                                                            edge="end"
                                                        >
                                                            <CloseIcon></CloseIcon>
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>,
                                            }} />
                                        <IconButton
                                            aria-label="Search"
                                            onClick={(e) => setDoSearch(new Date().getTime())}
                                            edge="end"
                                        >
                                            <SearchIcon></SearchIcon>
                                        </IconButton>
                                    </form>
                                </div>
                                <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>Conferences</Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell style={{ padding: '12px 16px' }}
                                                    onClick={() => sortRecords("name")}>Name
                                                    <span className='sorting'>
                                                        <img
                                                            alt="up"
                                                            src={up}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "name" &&
                                                                    sortoptions.orderby === "asc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />{" "}
                                                        <img
                                                            alt="down"
                                                            src={down}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "name" &&
                                                                    sortoptions.orderby === "desc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{ padding: '12px 16px' }}
                                                    onClick={() => sortRecords("conference_date")}>Conference Date
                                                    <span className='sorting'>
                                                        <img
                                                            alt="up"
                                                            src={up}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "conference_date" &&
                                                                    sortoptions.orderby === "asc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />{" "}
                                                        <img
                                                            alt="down"
                                                            src={down}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "conference_date" &&
                                                                    sortoptions.orderby === "desc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell style={{ padding: '12px 16px' }} onClick={() => sortRecords("place")}>Place
                                                    <span className='sorting'>
                                                        <img
                                                            alt="up"
                                                            src={up}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "place" &&
                                                                    sortoptions.orderby === "asc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />{" "}
                                                        <img
                                                            alt="down"
                                                            src={down}
                                                            width="12"
                                                            className={
                                                                sortoptions.sortby === "place" &&
                                                                    sortoptions.orderby === "desc"
                                                                    ? " "
                                                                    : "hide"
                                                            }
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ padding: '12px 16px' }}>Actions</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!!conferences && conferences.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ padding: '12px 16px' }}>
                                                        {row.conference_date ? new Date(row.conference_date.toString()).toLocaleDateString('en-US', dateOptions) : 'TBA'}</StyledTableCell>
                                                    <StyledTableCell style={{ padding: '12px 16px' }}>{row.place}</StyledTableCell>
                                                    <StyledTableCell align="right" style={{ padding: '12px 16px' }}>
                                                        <IconButton color="primary" aria-label="edit" sx={{ marginRight: '0.25rem' }}
                                                            onClick={() => {
                                                                console.log('edit', row.id);
                                                                navigate('/conferences/edit-conference/' + row.id, { replace: true });
                                                            }}
                                                        >
                                                            <Edit color="primary" />
                                                        </IconButton>
                                                        <IconButton aria-label="delete"
                                                            onClick={() => {
                                                                confirmDialogOpen(row.id);
                                                            }}
                                                        >
                                                            <Delete sx={{ color: '#d32f2f' }} />
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalCount}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                <Dialog
                                    open={open}
                                    onClose={() => { confirmDialogClose('No') }}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title" className='dialogHeadWithIcon'>
                                        <DeleteIcon /> {"Delete Conference"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Are You sure you want to delete this conference?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className='dailogBtnActWrapper'>
                                            <Button autoFocus onClick={() => { confirmDialogClose('No') }} variant="outlined" color='primary'>No</Button>
                                            <Button onClick={() => { confirmDialogClose('Yes') }} autoFocus variant="contained" color='primary'>Yes</Button>
                                        </div>
                                    </DialogActions>
                                </Dialog>
                                <Snackbar
                                    open={recordDeleted}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                    action={action}
                                    anchorOrigin={{
                                        horizontal: "center",
                                        vertical: "bottom",
                                    }}
                                >
                                    <Alert severity="success" variant="filled">Conference deleted successfully</Alert>
                                </Snackbar>
                            </Grid>
                        </Grid>
                    </Paper>
                </Main>
            </Box>
        </>
    )
}

export default ListConferences;
