import { createSlice } from "@reduxjs/toolkit";

export const editAdvertisementSlice = createSlice({
  name: "editAdvertisement",
  initialState: {
    editAdvertisement: {
      advertisementLink: "",
      advertisementImage: "",
    },
    errors: [],
    severity: "error",
    message: null,
    editAdvertisementButtonDisabled: false,
    loading: false,
  },
  reducers: {
    getAdvertisementDataById: (state, action) => {
      return {
        ...state,
        editAdvertisement: state.editAdvertisement,
        loading: true,
      };
    },
    getAdvertisementDataByIdSuccess: (state, action) => {
      console.log(action.payload);

      return {
        ...state,
        editAdvertisement: {
          id: action.payload.editAdvertisement.id,

          advertisementLink: action.payload.editAdvertisement.link,
          advertisementImage: action.payload.editAdvertisement.image,
        },
        loading: false,
      };
    },
    getAdvertisementDataByIdFailure: (state, action) => {
      return {
        ...state,
        errors: [],
        loading: false,
      };
    },
    editAdvertisementLink: (state, action) => {
      return {
        ...state,
        editAdvertisement: {
          ...state.editAdvertisement,
          advertisementLink: action.payload.advertisementLink,
        },
      };
    },
    editAdvertisementImage: (state, action) => {
      return {
        ...state,
        editAdvertisement: {
          ...state.editAdvertisement,
          advertisementImage: action.payload.advertisementImage,
        },
      };
    },
    setEditAdvertisementButtonDisabled: (state, action) => {
      return {
        ...state,
        editAdvertisementButtonDisabled:
          action.payload.editAdvertisementButtonDisabled,
      };
    },
    editAdvertisementLoading: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    editAdvertisementSuccess: (state, action) => {
      return {
        ...state,
        editAdvertisement: {
          ...state.editAdvertisement,
        },
        errors: [],
        message: action.payload.response,
        severity: "success",
        loading: false,
        editAdvertisementButtonDisabled:
          action.payload.editAdvertisementButtonDisabled,
      };
    },
    editAdvertisementFailure: (state, action) => {
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.error,
        severity: "error",
        loading: false,
        editAdvertisementButtonDisabled:
          action.payload.editAdvertisementButtonDisabled,
      };
    },

    ///////////////
  },
});

export const {
  getAdvertisementDataById,
  getAdvertisementDataByIdFailure,
  getAdvertisementDataByIdSuccess,

  editAdvertisementLink,
  editAdvertisementImage,
  setSubmitButtonDisabled,
  setEditAdvertisementButtonDisabled,
  editAdvertisementLoading,
  editAdvertisementSuccess,
  editAdvertisementFailure,
} = editAdvertisementSlice.actions;

export default editAdvertisementSlice.reducer;
