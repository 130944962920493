import {
  Alert,
  Box,
  Button,
  Card,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../sidebar/sidebar";
import Topbar from "../../topbar/topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAskARuleSuccess,
  saveAskARuleError,
  setAskARule,
  getAskARule,
  saveAskARule,
  setTitle,
  setContent,
} from "./askaruleSlice";
import { Main } from "../../main/main";
import Editor from "../../../shared/editor/editor";

export function AskARule() {
  const { REACT_APP_API_URL } = process.env;
  const dispatch = useDispatch();

  const editAskARulemessage = useSelector((state) => state.editAskARule.message);
  const [buttonDisabled, SetButtonDisabled] = useState(false);
  const title = useSelector((state) => state.editAskARule.data.title);
  const contentValue = useSelector((state) => state?.editAskARule?.data?.contentValue);
  const [titleError, setTitleError] = useState(false);
  const [titleHelperText, setTitleHelperText] = useState("");
  const mounted = useSelector((state) => state?.editAskARule?.mounted);
  const loading = useSelector((state) => state?.editAskARule.loading);
  const severity = useSelector((state) => state.editAskARule.severity);
  const errors = useSelector((state) => state.editAskARule.errors);

  useEffect(() => {
    if (!mounted) {
      dispatch(getAskARule());
      fetch(`${REACT_APP_API_URL}page/detail/ASK_A_RULE_ID/ASK_A_RULE`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true) {
            dispatch(setAskARule(responseJson.data));
          } else {
            console.log(responseJson);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [REACT_APP_API_URL, dispatch, mounted]);

  const validateTitle = (e) => {
    const title = e.target.value;
    dispatch(setTitle({ title: title }));
    if (title !== "") {
      setTitleError(false);
      setTitleHelperText("");
      SetButtonDisabled(false);
    } else {
      setTitleError(true);
      setTitleHelperText("Enter page title.");
      SetButtonDisabled(true);
    }
  };

  const contentHandleChange = (data) => {
    dispatch(
      setContent({
        contentValue: data,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const addPageData = {
      title: title,
      content: contentValue,
    };

    let checkError = false;

    if (title !== "") {
      setTitleError(false);
      setTitleHelperText("");
      SetButtonDisabled(false);
      checkError = true;
    } else {
      setTitleError(true);
      setTitleHelperText("Enter page title.");
      SetButtonDisabled(true);
    }

    if (checkError) {
      sendAskARuleRequest(addPageData);
    }
  };

  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const sendAskARuleRequest = (addPageData) => {
    let formData = new FormData();

    formData.append("title", addPageData.title);

    if (addPageData.content) {
      formData.append("content", addPageData.content);
    }

    formData.append("_method", "PUT");
    dispatch(saveAskARule());
    fetch(`${REACT_APP_API_URL}page/update/2`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            saveAskARuleSuccess({
              data: responseJson.data,
              message: responseJson.message,
            })
          );
          SetButtonDisabled(false);
        } else {
          console.log(responseJson);
          const errors = responseJson.errors;
          const newErrors = [];
          for (const [key, value] of Object.entries(errors)) {
            console.log(`${key}`, `${value}`);
            newErrors.push(`${value}`);
          }
          dispatch(
            saveAskARuleError({
              error: responseJson.message,
              errors: newErrors,
              addEventButtonDisabled: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">
                      Add / Update Ask a Rules Question
                    </Typography>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)} id="askaRuleForm">
                    <Grid container spacing={2} item>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          onChange={(e) => validateTitle(e)}
                          id="title"
                          label="Title"
                          variant="outlined"
                          fullWidth
                          error={titleError}
                          helperText={titleHelperText}
                          value={title}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Editor
                          data={contentValue}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            contentHandleChange(data);
                          }} />
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          type="submit"
                          sx={{ margin: "1rem 0" }}
                          disabled={buttonDisabled}
                          variant="contained"
                          disableElevation
                          size="large"
                        >
                          Save Ask A Rules
                        </Button>
                      </Grid>

                      {editAskARulemessage !== "" && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>
                            {editAskARulemessage}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>

                    {!!errors &&
                      errors.map((error) => (
                        <Grid item key={error} lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>{error}</Alert>
                        </Grid>
                      ))}
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default AskARule;
