import { Login } from "../login/login";
import { Dashboard } from "../dashboard/dashboard";
import { WebsiteSetting } from "../websitesetting/websitesetting";
import { Changepassword } from "../changepassword/changepassword";
import { Forgotpassword } from "../forgotpassword/forgotpassword";
import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Joinourteam from "../joinourteam/joinourteam";
import { removeLoginToken, saveLoginToken } from "../login/loginSlice";
import Resetpassword from "../resetpassword/resetpassword";
import { useDispatch } from "react-redux";
import { ListEvents } from "../events/listevents/listevents";
import AddEvents from "../events/addevents/addevent";
import Partners from "../partners/partner";
import Vendors from "../vendors/vendor";
import Judges from "../judges/judge";
import Contactus from "../contactus/contactus";
import Subscribers from "../subscribers/subscribers";
import Advertisement from "../advertisement/advertisement";
import AddAdvertisement from "../advertisement/addadvertisement/addadvertisement";
import EditAdvertisement from "../advertisement/editadvertisement/editadvertisement";

import Department from "../department/department";
import AddDepartment from "../department/adddepartment/adddepartment";
import EditDepartment from "../department/editdepartment/editdepartment";

import EditEvent from "../events/editevents/editevent";
import Homepagebanner from "../homepagebanner/homepagebanner";
import Addslide from "../addslide/addslide";
import Editslide from "../editSlide/editslide";
import Contentpagebanner from "../contentpagebanner/contentpagebanner";
import Addslidecontentpage from "../addslidecontentpage/addslidecontentpage";
import Editslidecontentpage from "../editSlidecontentpage/editslidecontentpage";
import CompetitionGuideline from "../competitionguideline/competitionguideline";
import AboutUs from "../pages/aboutus/aboutus";
import AskARule from "../pages/askarule/askarule";
import PrivacyPolicy from "../pages/privacypolicy/privacypolicy";
import NotFound from "../notfound/notfound";
import Editor from "../../shared/editor/editor";
import ListConferences from "../conferences/listconferences/listconferences";
import AddConference from '../conferences/addconferences/addconferences';
import EditConference from "../conferences/editconference/editconference";

export function Routing() {
  const { REACT_APP_API_URL } = process.env;
  const dispatch = useDispatch();
  const localToken = window.localStorage.getItem("token");
  let navigate = useNavigate();

  const validateToken = (localToken) => {
    fetch(`${REACT_APP_API_URL}checklogin`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${localToken}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(saveLoginToken(localToken));
        } else {
          window.localStorage.removeItem("token");
          dispatch(removeLoginToken());
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (localToken) {
    validateToken(localToken);
  }

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/" element={<LoginComponent />}></Route>
        <Route
          path="/forgot-password"
          element={<ForgotPasswordComponent />}
        ></Route>
        <Route
          path="/reset-password"
          element={<ResetPasswordComponent />}
        ></Route>
        {!!localToken && (
          <React.Fragment>
            <Route path="/dashboard" element={<DashboardComponent />}></Route>
            <Route
              path="/website-setting"
              element={<WebsiteSettingsComponent />}
            ></Route>
            <Route
              path="/Join-our-team"
              element={<JoinOurTeamComponent />}
            ></Route>
            <Route path="/events" element={<EventsComponent />}></Route>
            <Route
              path="/events/add-event"
              element={<AddEventsComponent />}
            ></Route>
            <Route
              path="/events/edit-event/:id"
              element={<EditEventsComponent />}
            ></Route>
            <Route path="/partners" element={<PartnersComponent />}></Route>
            <Route path="/vendors" element={<VendorsComponent />}></Route>
            <Route path="/judges" element={<JudgesComponent />}></Route>
            <Route path="/contactus" element={<ContactusComponent />}></Route>
            <Route
              path="/subscribers"
              element={<SubscribersComponent />}
            ></Route>
            <Route
              path="/change-password"
              element={<ChangePasswordComponent />}
            ></Route>
            <Route
              path="/homepage-slideshow"
              element={<HomepageBannerComponent />}
            ></Route>
            <Route
              path="/homepage-slideshow/add-slide"
              element={<Addhomepageslide />}
            ></Route>
            <Route
              path="/homepage-slideshow/edit-slide/:id"
              element={<Edithomepageslide />}
            ></Route>
            <Route
              path="/contentpage-slideshow"
              element={<ContentpageBannerComponent />}
            ></Route>
            <Route
              path="/contentpage-slideshow/add-slide"
              element={<Addcontentpageslide />}
            ></Route>
            <Route
              path="/contentpage-slideshow/edit-slide/:id"
              element={<Editcontentpageslide />}
            ></Route>
            <Route
              path="/advertisements"
              element={<AdvertisementComponent />}
            ></Route>
            <Route
              path="/advertisement/add"
              element={<AddFooterAdvertisement />}
            ></Route>
            <Route
              path="/advertisement/edit/:id"
              element={<EditFooterAdvertisement />}
            ></Route>

            <Route
              path="/departments"
              element={<Department />}
            ></Route>
            <Route
              path="/department/add"
              element={<AddDepartment />}
            ></Route>
            <Route
              path="/department/edit/:id"
              element={<EditDepartment />}
            ></Route>

            <Route
              path="/competition-guideline"
              element={<CompetitionGuidelinesComponent />}
            ></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/ask-a-rule" element={<AskARule />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/conferences"
              element={<ConferencesComponent />}
            ></Route>
            <Route
              path="/conferences/add-conference"
              element={<AddConferenceComponent />}
            ></Route>
            <Route
              path="/conferences/edit-conference/:id"
              element={<EditConferenceComponent />}
            ></Route>
          </React.Fragment>
        )}
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </React.Fragment>
  );
}

function LoginComponent() {
  return <Login />;
}

function DashboardComponent() {
  return <Dashboard />;
}

function WebsiteSettingsComponent() {
  return <WebsiteSetting />;
}

function JoinOurTeamComponent() {
  return <Joinourteam />;
}

function ChangePasswordComponent() {
  return <Changepassword />;
}

function ForgotPasswordComponent() {
  return <Forgotpassword />;
}

function ResetPasswordComponent() {
  return <Resetpassword />;
}

function EventsComponent() {
  return <ListEvents />;
}

function AddEventsComponent() {
  return <AddEvents />;
}

function EditEventsComponent() {
  return <EditEvent />;
}

function PartnersComponent() {
  return <Partners />;
}

function VendorsComponent() {
  return <Vendors />;
}

function JudgesComponent() {
  return <Judges />;
}

function ContactusComponent() {
  return <Contactus />;
}

function SubscribersComponent() {
  return <Subscribers />;
}

function HomepageBannerComponent() {
  return <Homepagebanner />;
}

function Addhomepageslide() {
  return <Addslide />;
}

function Edithomepageslide() {
  return <Editslide />;
}

function ContentpageBannerComponent() {
  return <Contentpagebanner />;
}

function Addcontentpageslide() {
  return <Addslidecontentpage />;
}

function Editcontentpageslide() {
  return <Editslidecontentpage />;
}

function AdvertisementComponent() {
  return <Advertisement />;
}

function AddFooterAdvertisement() {
  return <AddAdvertisement />;
}

function EditFooterAdvertisement() {
  return <EditAdvertisement />;
}

function CompetitionGuidelinesComponent() {
  return <CompetitionGuideline />;
}

function ConferencesComponent() {
  return <ListConferences />;
}

function AddConferenceComponent() {
  return <AddConference />;
}

function EditConferenceComponent() {
  return <EditConference />;
}

function NotFoundComponent() {
  return <NotFound />;
}
