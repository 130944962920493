import {
  deleteBannerSuccess,
  getBannerRecordsFailure,
  getBannerRecordsLoading,
  getBannerRecordsSuccess,
} from "./homepagebannerSlice";
const { REACT_APP_API_URL } = process.env;

export function getBannerRecords(
  page,
  pageSize,
  sortBy = "created_at",
  order = "DESC",
  search = ""
) {
  const newPage = page + 1;
  return function getBannerRecordsThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    dispatch(getBannerRecordsLoading());
    if (token) {
      fetch(
        `${REACT_APP_API_URL}sliders?page=${newPage}&sortBy=${sortBy}&order=${order}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true && responseJson.data) {
            dispatch(
              getBannerRecordsSuccess({
                banners: responseJson.data,
                totalCount: responseJson.total_count,
              })
            );
          } else {
            console.log(responseJson);
            dispatch(getBannerRecordsFailure({ error: responseJson.info }));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getBannerRecordsFailure({ error: err }));
        });
    }
  };
}

export function deleteBanner(id, page, rowsPerPage) {
  return function deleteBannerThunk(dispatch) {
    const token = window.localStorage.getItem("token");
    fetch(`${REACT_APP_API_URL}slider/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson);
          dispatch(getBannerRecords(page, rowsPerPage));
          dispatch(deleteBannerSuccess({ id: id }));
        } else {
          console.log(responseJson);
          dispatch(getBannerRecordsFailure({ error: responseJson.info }));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getBannerRecordsFailure({ error: err }));
      });
  };
}
