import { useTheme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import {
    useMediaQuery, Box, CssBaseline, IconButton, Paper, tableCellClasses, Button, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Snackbar, Backdrop, CircularProgress, Alert, TextField, Typography, InputAdornment
} from '@mui/material';
import { } from '@mui/system';
import { Main } from '../main/main';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import Sidebar from '../sidebar/sidebar';
import Topbar from '../topbar/topbar';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import { setPage, setRecordDeleted, setRowsPerPage } from './joinourteamSlice';
import { getTeamRequestRecords, deleteTeamRequest, exportTeamRequest } from './joinourteam.function'
import { Delete } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import CsvDownloader from 'react-csv-downloader';
import up from "../../assets/up.svg";
import down from "../../assets/down.svg";


export function Joinourteam() {
    const sidebarState = useSelector(state => state.topbar.drawerToggle);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontWeight: 'bold',
            cursor: 'pointer',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            padding: '0.5rem 1rem',
            fontSize: 14,
        },
    }));

    const csvData = useSelector(state => state?.jointeam?.csvData?.joinTeamRequest);
    const csvColumns = useSelector(state => state?.jointeam?.csvData?.columns);


    const teamrequests = useSelector(state => state?.jointeam?.teamrequests);
    const loading = useSelector(state => state.jointeam.loading);
    const totalCount = useSelector(state => state.jointeam.totalCount);
    const rowsPerPage = useSelector(state => state.jointeam.rowsPerPage);
    const page = useSelector(state => state.jointeam.page);
    const recordDeleted = useSelector(state => state.jointeam.recordDeleted);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getTeamRequestRecords(page, rowsPerPage));
        dispatch(exportTeamRequest());
    }, [dispatch, page, rowsPerPage]);


    const handleChangePage = (teamrequest, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (teamrequest) => {
        dispatch(setRowsPerPage(teamrequest.target.value));
        dispatch(setPage(0));

    };

    const [open, setOpen] = useState(false);
    const [dialogDeleteId, setDialogDeleteId] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [sortoptions, setSortOptions] = useState({
        sortby: "",
        orderby: "",
    });
    const { sortby, orderby } = sortoptions;
    const [searchinput, setSearchInput] = useState('');
    const [doSearch, setDoSearch] = useState("");

    const confirmDialogOpen = (id) => {
        setOpen(true);
        setDialogDeleteId(id);
    };

    const confirmDialogClose = (dialogAction) => {
        if (dialogAction === 'Yes') {
            dispatch(deleteTeamRequest(dialogDeleteId, page, rowsPerPage));
        }
        setOpen(false);
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setRecordDeleted({
            recordDeleted: false
        }));
    };

    const sortRecords = (sortfield) => {
        let newsort = "";
        let neworder = "";
        if (sortoptions.sortby === sortfield) {
            newsort = sortfield;
            if (sortoptions.orderby === "asc") {
                neworder = "desc";
            }
            if (sortoptions.orderby === "desc") {
                neworder = "asc";
            }
        } else {
            newsort = sortfield;
            neworder = "asc";
        }
        setSortOptions((sortoptions) => ({
            ...sortoptions,
            ["sortby"]: newsort,
            ["orderby"]: neworder,
        }));
    };

    useEffect(() => {
        if (sortoptions.sortby !== "" && sortoptions.orderby !== "") {
            console.log('sortoptions', sortoptions);
            dispatch(getTeamRequestRecords(page, rowsPerPage, sortoptions.sortby, sortoptions.orderby));
        }
    }, [sortoptions]);

    useEffect(() => {
        dispatch(getTeamRequestRecords(page, rowsPerPage, sortoptions.sortby, sortoptions.orderby, searchinput));
    }, [doSearch]);


    const handleClickClearSearch = () => {
        setSearchInput('');
        setDoSearch(new Date().getTime());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDoSearch(new Date().getTime());
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                CLOSE
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main open={sidebarState} className='dashbaordWrapper'>
                <Topbar />
                <Paper sx={{ background: '#e3f2fd', padding: '1.5rem', minHeight: 'calc(100% - 64px)' }}>
                    <div className='topBarButtonWrapper'>
                        <CsvDownloader className="customButton MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-1eikyat-MuiButtonBase-root-MuiButton-root"
                            filename="join-team-requests"
                            extension=".csv"
                            wrapColumnChar=""
                            columns={csvColumns}
                            datas={csvData}
                            text="Export CSV" />

                        <form onSubmit={(e) => handleSubmit(e)}>
                            <TextField id="searchinput" label="Search" size="small"
                                variant="outlined" fullWidth={false} value={searchinput}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {!!searchinput && (
                                            <IconButton
                                                aria-label="Clear Search"
                                                onClick={handleClickClearSearch}
                                                edge="end"
                                            >
                                                <CloseIcon></CloseIcon>
                                            </IconButton>
                                        )}

                                    </InputAdornment>,
                                }}

                                /*onChange={(e) => doSearch(e.nativeEvent.target.value)} */
                                onChange={(e) => setSearchInput(e.nativeEvent.target.value)}
                            />
                            <IconButton
                                aria-label="Search"
                                onClick={(e) => setDoSearch(new Date().getTime())}
                                edge="end"
                            >
                                <SearchIcon></SearchIcon>
                            </IconButton>
                        </form>
                    </div>
                    <Typography variant="h3" sx={{ margin: "0 0 1.5rem" }}>
                        Join Our Team Requests
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell onClick={() => sortRecords('first_name')} width={200}>Name
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "first_name" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "first_name" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => sortRecords('contact')}>Contact Number
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "contact" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "contact" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => sortRecords('email')}>Email
                                        <span className='sorting'>
                                            <img
                                                alt="up"
                                                src={up}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "email" &&
                                                        sortoptions.orderby === "asc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />{" "}
                                            <img
                                                alt="down"
                                                src={down}
                                                width="12"
                                                className={
                                                    sortoptions.sortby === "email" &&
                                                        sortoptions.orderby === "desc"
                                                        ? " "
                                                        : "hide"
                                                }
                                            />
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell width={400}>Message</StyledTableCell>
                                    <StyledTableCell align="left">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamrequests.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.first_name} {row.last_name}</TableCell>
                                        <TableCell>{row.contact}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.message}</TableCell>
                                        <TableCell align="left">
                                            <Stack direction="row" spacing={1}>
                                                <IconButton aria-label="delete"
                                                    onClick={() => {
                                                        confirmDialogOpen(row.id);
                                                    }}
                                                >
                                                    <Delete sx={{ color: '#d32f2f' }} />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!loading && (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}

                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={() => { confirmDialogClose('No') }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" className='dialogHeadWithIcon'>
                            <DeleteIcon /> {"Delete Team Request"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this request?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div className='dailogBtnActWrapper'>
                                <Button autoFocus onClick={() => { confirmDialogClose('No') }} variant="outlined" color='primary'>No</Button>
                                <Button onClick={() => { confirmDialogClose('Yes') }} autoFocus variant="contained" color='primary'>Yes</Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={recordDeleted}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        action={action}
                        anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                        }}
                    >
                        <Alert severity="success" variant="filled">Team Requests deleted successfully</Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            </Main>
        </Box>
    );
}

export default Joinourteam